import { Button, Form } from "react-bootstrap";
import { useAccount } from "../../../../Context/AccountProvider";
import { requireInteger } from "../../../../utilities/FormUtility";
import { SectionHeader } from "../../../SectionHeader/SectionHeader";

export function PatchSettings() {
    const {toast} = useAccount();
    return (
        <div>
            <SectionHeader underlined>Create a new patch</SectionHeader>
            <form>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>New Patch File</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Patch Version</Form.Label>
                    <Form.Control type="number" min={0} onKeyPress={requireInteger}/>
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" onClick={() => {toast('error', 'title', 'body')}}>Create Patch</Button>
                </div>
            </form>
        </div>
    )
}