import { useState } from "react"
import { Toast } from "react-bootstrap";
import './ParagonToast.css'

export function ParagonToast({title, children, variant}) {
    const [show, setShow] = useState(true);

    return (
        <Toast className={`paragon-toast ${variant}`} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <strong className="me-auto">{title}</strong>
          </Toast.Header>
          <Toast.Body>{children}</Toast.Body>
        </Toast>
    )
}