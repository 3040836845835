import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/TempestWings/pic1.jpg'
import Image2 from '../../images/Guide/TempestWings/pic2.jpg'
import Image3 from '../../images/Guide/TempestWings/pic3.jpg'
import Image4 from '../../images/Guide/TempestWings/pic4.jpg'
import Image5 from '../../images/Guide/TempestWings/pic5.jpg'
import Image6 from '../../images/Guide/TempestWings/pic6.jpg'
import Image7 from '../../images/Guide/TempestWings/pic7.jpg'

export function TempestWings() {
    return (
        <div className="guide white">
            <h3>Wings Quest</h3>
            
            <p className="head">Recommendation</p>
            <ul>
                <li>Level 140 2nd Reborn as the boss to defeat is quite strong.</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>Mariah <i>(Twin City)</i></li>
                <li>Celestial <i>(Market)</i></li>
                <li>Mr.Wonder <i>(Phoenix Castle)</i></li>
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>(5) MeteorScrolls</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to Mariah and click option "Wings".</p>
                    <Image src={Image1} fluid />
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent to Celestial to learn about the TempestWings.</p>
                    <Image src={Image3} fluid />
                </li>
                <li>
                    <p className="instruction">Celestial will take you the Mr.Wonder where you'll have to trade (5) MeteorScrolls as a fee to enter the RainbowSky. You then will be sent to the RainbowSky where you have to
                    defeat HeavenRoc to get Roc'sfeather.</p>
                    <Image src={Image4} fluid />
                    <p className="tip">*Note that RainbowSky is a Non-PK zone and that HeavenRoc will spawn every 3 minutes, in case you get there and can't find HeavenRock, please wait until it respawns.</p>
                    <Image src={Image5} fluid />
                </li>
                <li>
                    <p className="instruction">Once you defeat HeavenRoc, You'll be sent outside to trade Roc'Feather for the TempestWings.</p>
                    <Image src={Image6} fluid />
                    <Image src={Image7} fluid />
                </li>
            </ol>
        </div>
    );
}