import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/EpicTaoistQuest/pic1.jpg'
import Image2 from '../../images/Guide/EpicTaoistQuest/pic2.jpg'
import Image3 from '../../images/Guide/EpicTaoistQuest/pic3.jpg'
import Image4 from '../../images/Guide/EpicTaoistQuest/pic4.jpg'
import Image5 from '../../images/Guide/EpicTaoistQuest/pic5.jpg'
import Image6 from '../../images/Guide/EpicTaoistQuest/pic6.jpg'
import Image7 from '../../images/Guide/EpicTaoistQuest/pic7.jpg'
import Image8 from '../../images/Guide/EpicTaoistQuest/pic8.jpg'
import Image9 from '../../images/Guide/EpicTaoistQuest/pic9.jpg'
import Image10 from '../../images/Guide/EpicTaoistQuest/pic10.jpg'
import Image11 from '../../images/Guide/EpicTaoistQuest/pic11.jpg' 
import Image12 from '../../images/Guide/EpicTaoistQuest/pic12.jpg'
import Image13 from '../../images/Guide/EpicTaoistQuest/pic13.jpg'
import Image14 from '../../images/Guide/EpicTaoistQuest/pic14.jpg'
import Image15 from '../../images/Guide/EpicTaoistQuest/pic15.jpg'
import Image16 from '../../images/Guide/EpicTaoistQuest/pic16.jpg'
import Image17 from '../../images/Guide/EpicTaoistQuest/pic17.jpg'
import Image18 from '../../images/Guide/EpicTaoistQuest/pic18.jpg'
import Image19 from '../../images/Guide/EpicTaoistQuest/pic19.jpg'
import Image20 from '../../images/Guide/EpicTaoistQuest/pic20.jpg'
import Image21 from '../../images/Guide/EpicTaoistQuest/pic21.jpg'
import Image22 from '../../images/Guide/EpicTaoistQuest/pic22.jpg'
import Image23 from '../../images/Guide/EpicTaoistQuest/pic23.jpg'
import Image24 from '../../images/Guide/EpicTaoistQuest/pic24.jpg' 
import Image25 from '../../images/Guide/EpicTaoistQuest/pic25.jpg'
import Image26 from '../../images/Guide/EpicTaoistQuest/pic26.jpg'
import Image27 from '../../images/Guide/EpicTaoistQuest/pic27.jpg'
import Image28 from '../../images/Guide/EpicTaoistQuest/pic28.jpg'
import Image29 from '../../images/Guide/EpicTaoistQuest/pic29.jpg'

export function EpicTaoistQuest() {
    return (
        <div className="guide white">
            <h3>Epic Taoist Quest</h3>
            
            <p className="head">Requirements</p>
            <ul>
                <li>Level 140</li>
                <li>2nd Reborn Character</li>
                <li>VIP Level 6</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>TaoistMoon <i>(Job Center)</i></li>
                <li>Athur <i>(Evil Abyss)</i></li>
                <li>EarthSeal <i>(Evil Abyss)</i></li>
                <li>WaterSeal <i>(Evil Abyss)</i></li>
                <li>FireSeal <i>(Evil Abyss)</i></li>
                <li>CleansingStove <i>(Evil Abyss)</i></li>
                <li>BryansOven <i>(Evil Abyss)</i></li>
                <li>Angela <i>(Evil Abyss)</i></li>
                <li>Angela2 <i>(Evil Cave)</i></li>
                <li>DivineTao <i>(Evil Abyss)</i></li>
                
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>MagicJade</li>
                <li>TaoQuestTeleport</li>
                <li>Moss<i>(119)</i></li>
                <li>SoulAroma<i>(119)</i></li>
                <li>DreamGrass<i>(119)</i></li>
                <li>EvilTooth</li>
                <li>FeatherStone</li>
                <li>ImmortalStone</li>
                <li>VigorFragment</li>
                <li>ImpureVigor</li>
                <li>PureVigor</li>
                <li>SpiritualCrystal</li>
                <li>YinYangFish</li>
                <li>SquamaBead</li>
                <li>StarCrystal</li>
                <li>DivinePanacea</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to TaoistMoon(Job Center) to enter the EvilAbyss.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">You'll get MagicJade and TaoQuestTeleport to your inventory, the second item is a quick teleporter
that will help you get quicker to the Seals.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*If you're familiar with the second reborn quest this will be very familiar.</p>
                </li>
                <li>
                    <p className="instruction">Hunt for Mosses, Dream Grasses and Soul Aromas. You need to collect 119 of each.</p>
                </li>
                <li>
                    <p className="instruction">Head to the Earth Seal and click it to summon the Hill Monster with (1) Moss, (1) Dream Grass, and (1) Soul Aroma. Kill the monster to get (1) Ghost Horn. Collect (3) Ghost Horns
to then exchange for (1) Evil Tooth at Arthur.</p>
                    <Image src={Image3} fluid />
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">Go to the Water Seal, summon and take care of the Swift Devil with (1) Evil Tooth, (1) Moss, (1) Dream Grass, and (1) Soul Aroma, you will get (1) Feather Stone. Collect (3) Feather Stones
to exchange them for (1) ImmortalStone at Arthur.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                </li>
                <li>
                    <p className="instruction">Take (1) Immortal Stone, (1)Moss, (1)Dream Grass and (1) Soul Aroma to the Fire Seal to summon and kill Banshee for (1) VigorFragment. Collect (3) VigorFragment and take them to Arthur to exchange
for (1) VigorFragment. </p>
                    <Image src={Image7} fluid />
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                </li>
                <li>
                    <p className="instruction">Take (1) ImpureVigor to the Cleansing Stove to summon the Cleansing Devil. Take it down for (1) PureVigor.</p>
                    <Image src={Image10} fluid />
                    <Image src={Image11} fluid />
                </li>
                <li>
                    <p className="instruction">Repeat the steps until you get (3) PureVigors, then take them to Arthur to finish the first stage.</p>
                    <Image src={Image12} fluid />
                    <Image src={Image13} fluid />
                </li>
                <li>
                    <p className="instruction">Once you've completed the first stage, talk to BryansOven to learn about the second stage. You're gonna be asked to sink the Mountain of Grievance which is 40.000 meters high.
Killing the monsters inside the EvilAbyss give meters based on their HP, some may give 5 per kill and some 20.</p>
                    <Image src={Image14} fluid />
                </li>
                <li>
                    <p className="instruction">When you have the 40.000 meters completed, head back to BryansOven to finish the second stage.</p>
                    <Image src={Image15} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Angela to learn about the third and last stage. You're gonna be sent to the EvilCave to save Angela's little sister, kill all 8 bosses one after another, Taoist is going to drop (1) SquamaBead.</p>
                    <Image src={Image16} fluid />
                    <Image src={Image17} fluid />
                    <Image src={Image18} fluid />
                    <Image src={Image19} fluid />
                    <Image src={Image20} fluid />
                    <Image src={Image21} fluid />
                    <Image src={Image22} fluid />
                    <Image src={Image23} fluid />

                </li>
                <li>
                    <p className="instruction">Take the SquamaBead back to Angela to be sent to the Satan's Cave, defeat the guarding beast and Satan to get (1) StarCrystal.</p>
                    <Image src={Image24} fluid />
                    <Image src={Image25} fluid />
                    <Image src={Image26} fluid />
                    <Image src={Image27} fluid />
                    
                </li>
                <li>
                    <p className="instruction">Take (1) StarCrystal to DivineTao to complete the quest and get (1) DivinePanacea, right click it to convert your backsword into the Epic Backsword.</p>
                    <Image src={Image28} fluid />
                    <Image src={Image29} fluid />
                </li>
            </ol>
        </div>
    );
}