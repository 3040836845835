import { Col, Row } from "react-bootstrap";
import { getDateTimeString } from "../../css/Utility";

export function TicketMessage(props) {
    return(
        <div className="body">
            <Row className={"message " + ((props.message.type === 'staff') ? "reply" : "")}>
                <Col md={{span: 3, order: ((props.message.type === 'staff') ? 2 : 1)}} className="center info">
                    <img width={64} src={`/images/avatar/${props.message.avatar}.webp`} alt="a"></img>
                    <p>{props.message.name}</p>
                    <p>{props.message.title}</p>
                </Col>
                <Col md={{span: 9, order: ((props.message.type === 'staff') ? 1 : 2)}} className="content">
                    <p className="ticket-msg-content">{props.message.content}</p>
                </Col>
                <Col xs={{span: 9, offset: (props.message.type === 'staff') ? 0 : 3, order: 3}} className="timestamp">
                    <p>{getDateTimeString(props.message.timestamp)}</p>
                </Col>
            </Row>
        </div>
    );
}