import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { useAccount } from "../Context/AccountProvider";
import { getDateString } from "../css/Utility";
import { getAllTickets } from "../services/TicketService";
import { getToken } from "../services/TokenService";
import AuthenticationError from "../components/AuthenticationError";

export function StaffTicketPage() {
  const [tickets, setTickets] = useState();
  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [showClosedTickets, setShowClosedTickets] = useState(false);

  async function loadTickets() {
    let loadedTickets = await getAllTickets();
    setTicketsLoaded(true);
    setTickets(loadedTickets)
  }

  const { accountInfo } = useAccount();
  let history = useHistory(); 
  const redirectTickets = () =>{ history.push("/tickets") }

  if (accountInfo.loggedIn) {
    if (!accountInfo.user.ServerStaff) {
      redirectTickets();
    }

    if (!ticketsLoaded) {
      loadTickets()
    }

    if (tickets) {
      return (
        <div className="ticket-list-container">
          <Row>
            <Col className="white center ticket-list-header" xs={{offset: 4}}>
              <span>Support Tickets</span>
              </Col>
            <Col className="white ticket-list-toggle">
              <span>Show Closed</span>
              <input type="checkbox" className="form-check-input" onClick={()=>{setShowClosedTickets(!showClosedTickets)}}/>
              </Col>
          </Row>
          {processTickets(tickets, accountInfo, showClosedTickets)}
        </div>
      );
    } else {
      return (
        <div className="white center">
          Loading tickets...
        </div>
      );
    }

  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}

function processTickets(_tickets, accountInfo, showClosedTickets) {

  if (_tickets.length === 0) {
    return (
      <div className="no-tickets center white">
        <h3>You have no tickets.</h3>
      </div>
    )
  }

  let ticketObjects = []
  
  for (const _ticket of _tickets) {
    if (_ticket.status === "Closed" && !showClosedTickets) {
      continue;
    }

    ticketObjects.push(
      <LinkContainer exact to={"/ticket/"+_ticket.id}>
        <Row className="item">
          <Col xs={12} md={3}><b>Character:</b> {_ticket.characterName}</Col>
          <Col xs={12} md={3}><b>Ticket ID:</b> {_ticket.id}</Col>
          <Col xs={12} md={3}><b>Date Opened:</b> {getDateString(_ticket.timestamp)}</Col>
          <Col xs={12} md={3}><b>Status:</b> {_ticket.status}</Col>
          <Col xs={12}><b>Subject:</b> {_ticket.subject}</Col>
        </Row>
      </LinkContainer>
    )
  }

  if (ticketObjects.length === 0) {
    return (
      <div className="no-tickets center white">
        <h3>You have no open tickets.</h3>
      </div>
    )
  }

  return ticketObjects
}