import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass3/pic1.webp'
import Image2 from '../../images/Guide/BattlePass3/pic2.webp'
import Image3 from '../../images/Guide/BattlePass3/pic3.webp'
import Image4 from '../../images/Guide/BattlePass3/pic4.webp'
import Image5 from '../../images/Guide/BattlePass3/pic5.webp'
import Image6 from '../../images/Guide/BattlePass3/pic6.webp'
import Image7 from '../../images/Guide/BattlePass3/pic7.webp'
import Image8 from '../../images/Guide/BattlePass3/pic8.webp'
import Image9 from '../../images/Guide/BattlePass3/pic9.webp'
import Image10 from '../../images/Guide/BattlePass3/pic10.webp'
import Image11 from '../../images/Guide/BattlePass3/pic11.webp'
import Image12 from '../../images/Guide/BattlePass3/pic12.webp'
import Image13 from '../../images/Guide/BattlePass3/pic13.webp'
import Image14 from '../../images/Guide/BattlePass3/pic14.webp'
import Image15 from '../../images/Guide/BattlePass3/pic15.webp'
import Image16 from '../../images/Guide/BattlePass3/pic16.webp'
import Image17 from '../../images/Guide/BattlePass3/pic17.webp'
import Image18 from '../../images/Guide/BattlePass3/pic18.webp'
import Image19 from '../../images/Guide/BattlePass3/pic19.webp'
import Image20 from '../../images/Guide/BattlePass3/pic20.webp'
import Image21 from '../../images/Guide/BattlePass3/pic21.webp'
import Image22 from '../../images/Guide/BattlePass3/pic22.webp'
import Image23 from '../../images/Guide/BattlePass3/pic23.webp'
import Image24 from '../../images/Guide/BattlePass3/pic24.webp'

export function BattlePass3() {
    return (
        <div className="guide white">
            <h3>Battle Pass 3</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #3.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>MagicBox <i>(Twin City)</i></li>
                <li>Hula <i>(Twin City)</i></li>
                <li>KingAzazal<i>(Boss)</i></li>
                <li>Lovers<i>(Boss)</i></li>
                <li>ArabicPride <i>(Boss)</i></li>
                <li>Killua <i>(Boss)</i></li>
                <li>Nvm <i>(Boss)</i></li>
                <li>NinjaMaster <i>(Boss)</i></li>
                <li>Neymar <i>(Boss)</i></li>
                <li>LA_VENGANZA <i>(Boss)</i></li>
                <li>SoundofBlue<i>(Garment)</i></li>
                <li>WinterlandRobe<i>(Garment)</i></li>
                <li>RDFLag <i>(Item)</i></li>
                <li>EgyptianFlag <i>(Item)</i></li>
                <li>AmericanFlag <i>(Item)</i></li>
                <li>PinoyFlag <i>(Item)</i></li>
                <li>PassPack1 <i>(Item)</i></li>
                <li>PassPack2 <i>(Item)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 3 through the Coin Store for 10 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 3 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There are 12 different quests, each one gives 10 Battle Pass Points and 2M CPs:
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to PrayerTree.</li>
                    <Image src={Image4} fluid />
                    <li>KingAzazal: Your damage will be 1, this boss has 300 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image5} fluid />
                    <li>Lovers: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image6} fluid />
                    <li>ArabicPride: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image7} fluid />
                    <li>Killua: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image8} fluid />
                    <li>Nvm: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image9} fluid />
                    <li>NinjaMaster: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image10} fluid />
                    <li>LA_VENGANZA: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image11} fluid />
                    <li>Neymar: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image12} fluid />
                    <li>Defeat Hourly Bosses/Boss Invasion: Kill any of the Hourly Bosses or Boss Invasion to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image13} fluid />
                    <li>Win Hourly Events: Upon winning any of the HOURLY events, you have a chance to get BattlePassPoints(10) item + 2M CPs.</li>
                    <Image src={Image14} fluid />
                    <li>Win 10 Arena Qualifier Matches: You must win 10 Arena Qualifier matches to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image15} fluid />
                </ol>  
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>              
            <ol>          
                <ol>
                    <li>Level 1: 100M CPs, 5,000 Champion Points and (1) NameToken(Tradable)</li>
                    <li>Level 2: 100M CPs, 6,500 Champion Points and x2 USA Flag Accessories(255 HP Bound)</li>
                    <Image src={Image16} fluid />

                    <li>Level 3: 100M CPs, 8,000 Champion Points and x2 Pinoy Flag Accessories(255 HP Bound)</li>
                    <Image src={Image17} fluid />

                    <li>Level 4: 100M CPs, 10,000 Champion Points and (1) SoundofBlue(255 HP Bound Garment)</li>
                    <Image src={Image18} fluid />

                    <li>Level 5: 100M CPs, 12,000 Champion Points, x2 EgyptianFlag Accessories and x2 RD Flag Accessories(255 HP Bound)</li>
                    <Image src={Image19} fluid />
                    <Image src={Image20} fluid />

                    <li>Level 6: 100M CPs, 15,000 Champion Points and (1) PassPack1(Untradable)</li>
                    <p className="tip">*PassPack1 is a clickable item where you get to choose whether you want x2 AccessoryEnchanter(Bound) or 5 SuperBook(Bound)</p>
                    <Image src={Image21} fluid />

                    <li>Level 7: 100M CPs, (1) PassPack2(Untradable) and WinterlandRobe(255 HP Bound Garment)</li>
                    <Image src={Image22} fluid />
                    <Image src={Image23} fluid />
                    <Image src={Image24} fluid />
                    <p className="tip">*PassPack2 is a clickable item where you get to choose whether you want ForgeOrb(Bound) or 3 TalismanEnchanter(Tradable)</p>
                    <p className="tip">What is ForgeOrb? It's a clickable item to forge one of the donation cups(ShifuCup, ImperialCup or GobletofFire)</p>
                </ol>  
            </ol>
        </div>
    );
}