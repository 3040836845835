import { useState } from "react";
import { Button, Accordion } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { getDownloadLinks } from "../services/SettingsService";

function Download() {
    const [settings, setSettings] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    async function loadSettings() {
        let settingsResult = await getDownloadLinks();
        setSettingsLoaded(true);
        
        setSettings(settingsResult);
    }

    if (!settingsLoaded) {
        loadSettings();
    }

    return (
        <div className="center white">
            <h3>Download Paragon Conquer</h3>
            <div className="download-content">
                <p>Paragon Conquer is available for Windows</p>
                <a href={(settings) ? settings.filter(x => x.key === "mediaFireDownloadLink")[0].value : "#"} target="_blank" rel="noreferrer">
                    <Button className="download-button" disabled={(settings) ? false : true}>MediaFire</Button>
                </a>
                <a href={(settings) ? settings.filter(x => x.key === "googleDriveDownloadLink")[0].value : "#"} target="_blank" rel="noreferrer">
                    <Button className="download-button" disabled={(settings) ? false : true}>Google Drive</Button>
                </a>
                <hr></hr>
            </div>

            <div className="download-notification">
                <div className="dln-header">
                    <h3>Adobe has discontinued support for Flash Player</h3>
                </div>
                <div className="dln-content">
                    <p>You may experience this error while launching the game.</p>
                    <p>We've listed potential fixes to this error.</p>
                    <p>Try each method at a time and one of them will fix it.</p>
                    <ul>
                        <li><a href="https://www.techspot.com/downloads/524-flash-player-pro.html" target="_blank" rel="noreferrer">Method 1</a>&nbsp;(Download and install)</li>
                        <li><a href="https://www.mediafire.com/file/txxoy3cw87euvyy/NewFlash.7z/file" target="_blank" rel="noreferrer">Method 2</a>&nbsp;(Extract and replace in client)</li>
                        <li><a href="https://www.mediafire.com/file/k7rmmd22m71h6tm/OldFlash.7z/file" target="_blank" rel="noreferrer">Method 3</a>&nbsp;(Extract and replace in client)</li>
                    </ul>
                </div>
            </div>

            <div className="download-problems">
                <h4>Common Client Problems:</h4>
                <Accordion flush className="download-accordion">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How do I launch the game?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                                Run Autopatch.exe
                            </span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>I need assistance installing the game</Accordion.Header>
                        <Accordion.Body>
                            <h3>To get assistance:</h3>
                            <ol>
                                <li>Check out our&nbsp;
                                    <LinkContainer to="/faq">
                                        <span className="fake-link">FAQ</span>
                                    </LinkContainer>
                                    &nbsp;for some common problems</li>
                                <li>Ask for assistance on our <a href="https://discord.gg/rCQ5Bask8S" target="_blank" rel="noreferrer">Discord</a> if you need further help.</li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>                    
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>How to add exceptions to antiviruses?</Accordion.Header>
                        <Accordion.Body>
                            <h3>Adding Exceptions to your Anti-Virus:</h3>
                            <p>All antiviruses have different ways to adding exclusions. We've listed below the most popular ones.</p>
                            <ul>
                                <li><a href="https://youtu.be/BonLkFNnO9w" target="_blank" rel="noreferrer">Windows Defender (Win 10)</a></li>
                                <li><a href="https://youtu.be/eoRSxbMPWRc" target="_blank" rel="noreferrer">Avast</a></li>
                                <li><a href="https://youtu.be/9NbfmhJAiDs" target="_blank" rel="noreferrer">Eset</a></li>
                                <li><a href="https://youtu.be/E2VA62rYTag" target="_blank" rel="noreferrer">Kaspersky</a></li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
  }
  
export { Download };