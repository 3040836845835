import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import EventScheduleImage from "../images/webp/event_schedule.webp";

const localizer = momentLocalizer(moment)

export function EventSchedule() {
    return (
        <div>
            <div className="center white">
                <img src={EventScheduleImage} style={{width: '100%'}} alt="Paragon Conquer Event Schedule"></img>
            </div>
        </div>
    );
}