import { useAccount } from '../Context/AccountProvider';
import { getToken } from '../services/TokenService'
import AuthenticationError from '../components/AuthenticationError';
import { ChangePasswordForm } from "../components/ChangePasswordForm";

function ChangePassword() {
  const { accountInfo } = useAccount();

  if (accountInfo.loggedIn) {
    return (
      <ChangePasswordForm/>
    );
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}

export { ChangePassword };