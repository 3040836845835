import Axios from 'axios';
import { getToken } from './TokenService'

export function getUserTickets() {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/getTickets', {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function getAllTickets() {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/getAllTickets', {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function fetchTicket(ticketId) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/ticketData', {
        ticket: ticketId
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function submitTicket(subject, message, captcha) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/ticketSubmit', {
        subject: subject,
        message: message,
        captcha: captcha
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function replyTicket(ticketId, message) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/ticketReply', {
        id: ticketId,
        message: message
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(false);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function closeTicket(ticketId) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/ticketClose', {
        id: ticketId,
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(false);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}