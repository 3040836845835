import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Clock } from './Clock';

import { useAccount } from '../Context/AccountProvider'
import { getUserData } from '../services/UserService'
import { serverStatus } from '../services/StatusService'

function Header() {
    var {updateAccountInfo} = useAccount();
    var [status, setStatus] = useState(true);

    var statusCheckerInitiated = false;

    async function update(event) {
        let userData = await getUserData()
        updateAccountInfo({user: userData});
    }

    async function statusCheck() {
        let s = await serverStatus();
        setStatus(s);
    }

    if (!statusCheckerInitiated) {
        setInterval(()=>{
            statusCheck();
        }, 30000)
    }

    return (
        <div className="header">
            <Row className="justify-content-md-center">
                <Col xs="12" lg="3" className="header-left">
                    
                </Col>
                <Col xs lg="4" className="header-right mobile-hidden">
                    <div>
                        <p className="header-box">
                            <span>Server Time:</span>&nbsp; <Clock/>
                        </p>
                        <br></br>
                        <p className="header-box" onClick={update}>
                            <span>Server Status:</span>&nbsp;{(status) ? <span style={{color: 'lightgreen'}}>Online</span> : <span style={{color: 'pink'}}>Offline</span>}
                        </p>
                    </div>
                </Col>
            </Row>
        </div>
    );
  }
  
  export { Header };