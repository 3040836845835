import { useHistory } from "react-router";
import { useAccount } from "../Context/AccountProvider";
import { getToken } from "../services/TokenService";
import AuthenticationError from "../components/AuthenticationError";
import { SiteSettings } from "../components/Admin/Settings/SiteSettings/SiteSettings";
import { ContentBlock } from "../components/ContentBlock/ContentBlock";
import { LauncherSettings } from "../components/Admin/Settings/LauncherSettings/LauncherSettings";
import { PatchSettings } from "../components/Admin/Settings/PatchSettings/PatchSettings";
import { ExistingPatches } from "../components/Admin/Settings/ExistingPatches/ExistingPatches";

export function StaffSettingsPage() {
  const { accountInfo } = useAccount();
  let history = useHistory(); 
  const redirectHome = () =>{ history.push("/") }

  if (accountInfo.loggedIn) {
    if (!accountInfo.user.ServerStaff) {
      redirectHome();
    }

    return (
      <div>
        <ContentBlock header={"Website Settings"}>
          <SiteSettings/>  
        </ContentBlock>
        <ContentBlock header={"Launcher Settings"}>
          <LauncherSettings/>
        </ContentBlock>
        <ContentBlock header={"Patch Settings"}>
          <PatchSettings/>
          <ExistingPatches/>
        </ContentBlock>
      </div>
    )

  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}