import { useAccount } from '../Context/AccountProvider';
import { getToken } from '../services/TokenService'
import AuthenticationError from "../components/AuthenticationError";
import CoinImage from "../images/pcoin.webp";
import ScrollIcon from "../images/scroll_icon.png";
import { RefundPolicyText } from '../components/RefundPolicyText';
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { checkout } from '../services/DonationStoreService';

function BuyCoins() {
  const { accountInfo } = useAccount();
  const [policyAccepted, setPolicyAccepted] = useState(false);

  async function purchaseCoins(e) {
    e.preventDefault();

    const coins = e.target['coins'].value;

    let checkoutLink = await checkout(coins);
    
    window.location.replace(checkoutLink);
  }

  if (accountInfo.loggedIn) {
    if (policyAccepted) {
      return (
        <div>
          <div class="header-skewed mb-3">
            <div class="header-skewed-text">
              <img class="header-icon" src={CoinImage} alt="Coins" />
              <span>Purchase Paragon Coins</span>
            </div>
          </div>

          <div className="white dark-box mb-3">
            <p>You can buy Paragon Coins to spend in-game at the PrizeNPC in the Market.</p>
            <p>Purchasing Paragon Coins helps support the servers upkeep and development, allowing us to provide a smooth and rich game environment for you and your fellow players.</p>
            <p className="mb-3">Paragon Coins can be purchased below for the price of $1.00 USD per 1 Paragon Coin.</p>

            <div className="justify-left">
            <Form onSubmit={purchaseCoins}>
                <InputGroup>
                  <InputGroup.Text><img src={CoinImage} width={26} height={26} alt="Paragon Coin" /></InputGroup.Text>
                  <Form.Control 
                    className="input-right-align input-number-normal" 
                    name="coins" 
                    type="number" 
                    min="5" 
                    defaultValue='5' 
                  />
                  <Button className="paragon-button" type="submit">Purchase</Button>
                </InputGroup>
                <p className="dps-notice mb-0">*All Paragon Coin sales must be atleast $5</p>
              </Form>
          </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="header-skewed mb-3">
            <div className="header-skewed-text">
              <img className="header-icon" src={ScrollIcon} alt="Refund Policy" />
              <span>Refund Policy</span>
            </div>
          </div>
          <div className="white dark-box mb-3">
            <RefundPolicyText />
          </div>
          <div className="justify-right">
            <Button className="paragon-button" onClick={() => setPolicyAccepted(true)}>Accept Refund Policy</Button>
          </div>
        </div>
      );
    }
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}

export { BuyCoins };