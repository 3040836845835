export function getDateString(date) {
    return new Date(date).toLocaleDateString(getLocale(), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
  }
  
  export function getDateTimeString(date) {
    return new Date(date).toLocaleDateString('en-us', {
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour12: true
    })
  }
  
  export function getLocale() {
    if (navigator.languages !== undefined) 
      return navigator.languages[0]; 
    return navigator.language;
  }