import React, { useContext, useState } from 'react';
import merge from 'deepmerge';
import { ParagonToastContainer } from '../components/ParagonToastContainer/ParagonToastContainer';
import { ParagonToast } from '../components/ParagonToast/ParagonToast';

const AccountContext = React.createContext();

export function useAccount() {
    return useContext(AccountContext);
}

export function AccountProvider({ children }) {
    const [accountInfo, setAccountInfo] = useState({
        loggedIn: false,
        showLogin: false
    });

    const [toasts, setToasts] = useState([]);

    function updateAccountInfo(info) {
        setAccountInfo(merge(accountInfo, info));
    }

    function setAccInfo(info) {
        setAccountInfo(info);
    }

    function toast(variant, title, body) {
        setToasts([...toasts, <ParagonToast variant={variant} title={title}>{body}</ParagonToast>]);
    }

    return (
        <AccountContext.Provider value={{accountInfo, updateAccountInfo, setAccInfo, toast}}>
            {children}
            <ParagonToastContainer>{toasts}</ParagonToastContainer>
        </AccountContext.Provider>
    )
}