import { Accordion } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export function FrequentlyAskedQuestions() {
    return (
        <div className="white center">
            <h3>Frequently Asked Questions</h3>
            <Accordion flush className="download-accordion">
                <Accordion.Item eventKey="-2">
                    <Accordion.Header>How do I download the game?</Accordion.Header>
                    <Accordion.Body>
                        <h3>To download the game:</h3>
                        <p>You should simply run the installer that you have downloaded from our&nbsp;
                            <LinkContainer to="/download">
                                <span className="fake-link">Downloads</span>
                            </LinkContainer> 
                            &nbsp;page.</p>

                        <p>As you may already know, Conquer Online files aren't antivirus-friendly, and this leads to having issues after installing the game.</p>

                        <p>We highly recommend turning your antivirus off after your download is complete and then adding an exclusion to the folder where the game is installed.</p>

                        <p>After you've created the exception through your antivirus, you should turn it back on again.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>How do I report a player?</Accordion.Header>
                    <Accordion.Body>
                        <h3>To report a player:</h3>
                        <ul>
                            <li>
                                <h5>Submit a Ticket</h5>
                                <ol>
                                    <li>Login to the Website</li>
                                    <li>Go to the Ticket Tracker</li>
                                    <li>Submit a new ticket with any evidence and information necessary to prove that a player has broken the rules.</li>
                                </ol>
                            </li>
                            <li>
                                <h5>Contact a GM</h5>
                                <p>Contact a GM in-game or through Discord</p>
                                </li>
                            <li>
                                <h5>Visit our Discord</h5>
                                <p>Send a message in the #support channel on our Discord</p>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Is Account Sharing allowed?</Accordion.Header>
                    <Accordion.Body>
                        <h5>Account Sharing is not supported by Paragon Conquer</h5>
                        <p>Account Sharing is completely at your own risk.</p>
                        <p>Paragon Conquer and its staff take no responsibility for the actions taken by someone who you have allowed access to your account.</p>
                        <p>While we understand that it's very frustrating to have your account stolen or destroyed by someone you trust, we can not assist you in recovering anything lost through Account Sharing.</p>
                        <p><b>Please do not share your login credentials with anyone who asks for them.</b></p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>How do I change my account information?</Accordion.Header>
                    <Accordion.Body>
                        <p>You may change your account's Email and Password by logging in to your account through the website and visiting the <b>Account Dropdown Menu</b> located in the top-right corner of the page.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>I forgot my username!</Accordion.Header>
                    <Accordion.Body>
                        <p>To retrieve your accounts username you must know the email associated with your account.</p>
                        <p>You may request a list of all usernames registered to your email to be sent to your email address.</p>
                        <p>If you do not know the email associated with your account, you can not recover your account.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>I forgot my password!</Accordion.Header>
                    <Accordion.Body>
                        <p>You may request a Password Reset to be sent to the email attached to your account.</p>
                        <ol>
                            <li>Choose Forgot Password</li>
                            <li>Enter your Username</li>
                            <li>Check your email for an email labelled <b>Password Reset</b> from <b><br></br>no-reply@paragonconquer.com</b></li>
                            <li>Click the Password Reset link in your email within 30 minutes of requesting it</li>
                            <li>Choose a new password</li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>How do I find out when events are held?</Accordion.Header>
                    <Accordion.Body>
                        <p>There is a posted schedule of all server-held events on our <b>Events Schedule</b> page.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>I'm stuck on this quest, I don't know what to do next!</Accordion.Header>
                    <Accordion.Body>
                        <p>Please view the <b>Guide</b> for assistance with quests.</p>
                        <p>All quests have comprehensive image and video walkthroughs. If you are still confused after visiting the Guide, please visit our Discord to request further assistance.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}