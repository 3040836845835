export function GameCommands() {
    return (
        <div className="guide white">
            <h3>Commands</h3>
            
            <p className="head">General Commands:</p>
            <ul className="margin-command-list">
                <li><span className="command">/fps [number here]</span> (up to 900)</li>
                <li><span className="command">/rolescale</span></li>
                <li><span className="command">@skillmaxlevel</span> (Upgrades your available skills to max level)</li>
                <li><span className="command">@profmaxlevel</span> (Upgrades your available weapon proficiency to max level)</li>
                <li><span className="command">@agi @str @vit @spi [number here]</span> (Capped at 1k per stat)</li>
                <li><span className="command">@day @dia</span> (Default screen)</li>
                <li><span className="command">@night @noche</span> (Night mode screen)</li>
                <li><span className="command">@english @spanish</span> (Changes NPC dialog language)</li>
                <li><span className="command">@taballies @tabally</span> (Hides allies and guild members in your screen)</li>
                <li><span className="command">@tabenemies @tabenemy</span> (Hides enemied guild member in your screen)</li>
                <li><span className="command">@autopot 1</span> (Enables server side autopot, pot must be in F10)</li>
                <li><span className="command">@autopot 0</span> (Disables autopot)</li>
                <li><span className="command">@tabmonster</span> (Hides monsters except bosses)</li>
                <li><span className="command">@view</span> [accessory name here] @ver</li>
                <li><span className="command">@potions</span> (Spawns potions to your inventory)</li>
                <li><span className="command">@pvp</span> (Join a tournament, only works if you're in TwinCity, Market, Pka and No Cost)</li>
                <li><span className="command">@breathfocus</span> (Hides Breathfocus effect, can only be used by Trojans)</li>
                <li><span className="command">@clearinventory</span> (Deletes unlocked items in your inventory, secondary password is required)</li>
            </ul>

            <p className="head">Custom rooms/duel rooms Commands:</p>
            <ul>
                <li><span className="command">@leave</span> (Lets you out of custom/duel rooms)</li>
                <li><span className="command">@resetscores</span></li>
            </ul>

            <p className="head">VIP 6/7 Command:</p>
            <ul>
                <li><span className="command">@summonguild @llamarclan</span> (Sends a message to your guildies to your location)</li>
            </ul>

            <p className="head">VIP 7 Command:</p>
            <ul>
                <li><span className="command">@dnd</span> (Blocks trades, team invites and system messages)</li>
            </ul>
        </div>
    );
}