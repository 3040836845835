import { useState } from "react";
import { Accordion, Button, Col, Row, Table } from "react-bootstrap";
import { useAccount } from "../Context/AccountProvider";
import { getToken } from "../services/TokenService";
import { getPaymentHistory } from "../services/UserService";
import AuthenticationError from "../components/AuthenticationError";
import Items from "../services/PrizeNPCItemsService"
import { LinkContainer } from "react-router-bootstrap";

function PaymentLogs() {
  const { accountInfo } = useAccount();

  const [activeTab, setActiveTab] = useState("tebex");

  const [paymentHistory, setPaymentHistory] = useState();
  const [phLoaded, setPhLoaded] = useState(false);

  if (!accountInfo.loggedIn && getToken() != null) {
    return (
      <></>
    )
  } else if (!accountInfo.loggedIn) {
    return (
      <AuthenticationError />
    )
  }

  if (!phLoaded) {
    (async() => {
      setPaymentHistory((await getPaymentHistory(accountInfo.user.ID)).result);
      setPhLoaded(true);
    })();
  }

  if (!phLoaded) {
    return (
      <div className="loading-container">
        <p>Loading Purchase History for {accountInfo.user.Name}</p>
        <div class="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="plogTabHeader">
        <h3>Purchase History</h3>
        <p>For your convenience, Paragon Conquer provides a detailed history of your purchases from our store.</p>
        <Button onClick={() => { setActiveTab("tebex") }} variant={(activeTab === "tebex" ? "secondary" : "outline-secondary")}>
          Tebex History
        </Button>
        <Button onClick={() => { setActiveTab("payment") }} variant={(activeTab === "payment" ? "secondary" : "outline-secondary")}>
          PayPal History
        </Button>
        <Button onClick={() => { setActiveTab("prizenpc") }} variant={(activeTab === "prizenpc" ? "secondary" : "outline-secondary")}>
          PrizeNPC History
        </Button>
      </div>
      <div className="payment-logs">
        {getPaymentStructure(activeTab, paymentHistory)}
      </div>
    </div>
  );
}

function getPaymentStructure(activeTab, paymentHistory) {
  let components = [];
  
  switch(activeTab) {
    case "payment":
      if (paymentHistory[0].length === 0) {
        components.push(
          <div className="no-history">
            <h4>You have no PayPal Transcation History!</h4>
            <LinkContainer exact to="/buycoins">
              <Button>Purchase Paragon Coins</Button>
            </LinkContainer>
          </div>
        )
        break;
      }

      components.push(<p className="plog-tooltip">Click on a transaction to view more information.</p>)

      components.push(
        <Row>
          <Col>Purchase Date</Col>
          <Col>PayPal Email</Col>
          <Col>Amount (USD)</Col>
          <Col>Coins Credited</Col>
        </Row>
      )
      
      components.push(
        <Accordion>
          {(() => {
            let comps = [];
            
            let i = 0;

            for (let record of paymentHistory[0]) {
              let date = new Date(record.txn_date);

              comps.push(
                <Accordion.Item eventKey={i++}>
                  <Accordion.Header>
                    <Row className="payment-row">
                      <Col>{date.toLocaleDateString()} {date.toLocaleTimeString()}</Col>
                      <Col>{record.payer_email}</Col>
                      <Col>${record.payment_gross}</Col>
                      <Col>{record.donation_points}</Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                        <h3>Transaction Information:</h3>
                        <Row>
                          <Col>Transaction ID</Col>
                          <Col>{record.txn_id}</Col>
                        </Row>
                        <Row>
                          <Col>Transaction Date</Col>
                          <Col>{date.toLocaleDateString()}</Col>
                        </Row>
                        <Row>
                          <Col>Transaction Time</Col>
                          <Col>{date.toLocaleTimeString()}</Col>
                        </Row>
                        <Row>
                          <Col>Purchase Price (USD):</Col>
                          <Col>${record.payment_gross}</Col>
                        </Row>
                        <Row>
                          <Col>Paragon Coins:</Col>
                          <Col>{record.donation_points} Coins</Col>
                        </Row>
                        <Row>
                          <Col>Status:</Col>
                          <Col>{(record.distributed == "1") ? <span className="credited">Credited</span> : <span className="pending">Pending</span>}</Col>
                        </Row>
                      </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }

            return comps;
          })()}
        </Accordion>
      )

      let sumDollars = 0;
      let sumDPS = 0;

      paymentHistory[0].map(x => sumDollars += x.payment_gross)
      paymentHistory[0].map(x => sumDPS += x.donation_points)

      components.push(
        <Row className="payment-table-total">
          <Col></Col>
          <Col>Total</Col>
          <Col>${sumDollars}</Col>
          <Col>{sumDPS} Coins</Col>
        </Row>
      )

      if (sumDollars > 0) {
        components.push(<p>
          Thank you for supporting Paragon Conquer!
        </p>)
      }

      break;
    case "tebex":
      if (paymentHistory[2].length === 0) {
        components.push(
          <div className="no-history">
            <h4>You have no Tebex Transaction History!</h4>
            <LinkContainer exact to="/buycoins">
              <Button>Purchase Paragon Coins</Button>
            </LinkContainer>
          </div>
        )
        break;
      }

      components.push(<p className="plog-tooltip">Click on a transaction to view more information.</p>)

      components.push(
        <Row>
          <Col>Purchase Date</Col>
          <Col>Tebex Email</Col>
          <Col>Amount (USD)</Col>
          <Col>Coins Credited</Col>
        </Row>
      )
      
      components.push(
        <Accordion>
          {(() => {
            let comps = [];
            
            let i = 0;

            for (let record of paymentHistory[2]) {
              let date = new Date(record.transaction_date);

              comps.push(
                <Accordion.Item eventKey={i++}>
                  <Accordion.Header>
                    <Row className="payment-row">
                      <Col>{date.toLocaleDateString()} {date.toLocaleTimeString()}</Col>
                      <Col>{record.payer_email}</Col>
                      <Col>${record.price_paid}</Col>
                      <Col>{record.coins_bought}</Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                        <h3>Transaction Information:</h3>
                        <Row>
                          <Col>Transaction ID</Col>
                          <Col>{record.transaction_id}</Col>
                        </Row>
                        <Row>
                          <Col>Transaction Date</Col>
                          <Col>{date.toLocaleDateString()}</Col>
                        </Row>
                        <Row>
                          <Col>Transaction Time</Col>
                          <Col>{date.toLocaleTimeString()}</Col>
                        </Row>
                        <Row>
                          <Col>Purchase Price (USD):</Col>
                          <Col>${record.price_paid}</Col>
                        </Row>
                        <Row>
                          <Col>Paragon Coins:</Col>
                          <Col>{record.coins_bought} Coins</Col>
                        </Row>
                        <Row>
                          <Col>Status:</Col>
                          <Col>{(record.distributed.data[0] === 1) ? <span className="credited">Credited</span> : <span className="pending">Pending</span>}</Col>
                        </Row>
                      </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }

            return comps;
          })()}
        </Accordion>
      )

      let sumDollarsTebex = 0;
      let sumCoinsTebex = 0;

      paymentHistory[2].map(x => sumDollarsTebex += x.price_paid)
      paymentHistory[2].map(x => sumCoinsTebex += x.coins_bought)

      components.push(
        <Row className="payment-table-total">
          <Col></Col>
          <Col>Total</Col>
          <Col>${sumDollarsTebex}</Col>
          <Col>{sumCoinsTebex} Coins</Col>
        </Row>
      )

      if (sumDollarsTebex > 0) {
        components.push(<p>
          Thank you for supporting Paragon Conquer!
        </p>)
      }

      break;
    case "prizenpc":
      if (paymentHistory[1].length === 0) {
        components.push(
          <div className="no-history">
            <h4>You have no PrizeNPC History!</h4>
            <p>This means you have not yet purchased Donation Points from our store.</p>
            <LinkContainer exact to="/buycoins">
              <Button>Purchase Donation Points</Button>
            </LinkContainer>
          </div>
        )
        break;
      }

      let sumDPSSpent = 0;

      components.push(
        <Table className="prizenpc-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Coins Value</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              let components = [];

              for (let record of paymentHistory[1]) {
                let date = new Date(record.Date);

                components.push(
                  <tr>
                    <td>{date.toLocaleDateString()} {date.toLocaleTimeString()}</td>
                    <td>{Items[record.ItemType].Name}</td>
                    <td>{record.ItemQuantity}</td>
                    <td>{Items[record.ItemType].DPSCost * record.ItemQuantity}</td>
                  </tr>
                )

                sumDPSSpent += (Items[record.ItemType].DPSCost * record.ItemQuantity);
              }

              return components;
            })()}
            <tr className="totals">
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{sumDPSSpent} Coins</td>
            </tr>
          </tbody>
        </Table>
      )

      if (sumDPSSpent > 0) {
        components.push(<p>
          Thank you for supporting Paragon Conquer!
        </p>)
      }
      break;
    default:
      components.push(<div>Invalid structure</div>)
      break;
  }

  return components;

}

export { PaymentLogs };