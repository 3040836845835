import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/PerfectionSystem/pic1.jpg'
import Image2 from '../../images/Guide/PerfectionSystem/pic2.jpg'
import Image3 from '../../images/Guide/PerfectionSystem/pic3.jpg'
import Image4 from '../../images/Guide/PerfectionSystem/pic4.jpg'
import Image5 from '../../images/Guide/PerfectionSystem/pic5.jpg'

export function PerfectionSystem() {
    return (
        <div className="guide white">
            <h3>Perfection System</h3>
            <div>
                <span>Perfection system is aimed to be an end-game type of feature. All of the effects have been disabled for good. The only thing that perfection gives
                    is P-Attack, P-Defence, M-Attack and M-Defence buff of 1.500 to each value when maxed. These buffs will scale as your perfection points get higher.
                </span>
                <p className="instruction">About:</p>
                <span style={{paddingLeft: '2em'}}>You may only use one +7 stone a day on any of your gears. For better/faster result it is recommended to use RadiantStarStone and SplendidStarStone
                        that are found in the Champion Points Store for Champion Points and have no limit of daily use.
                        <Image src={Image1} fluid />
                </span>
                <p className="instruction">Perfection Mechanics:</p>
                <ol>
                    <li>Perfection:</li>
                    <Image src={Image2} fluid />
                    <li>Transfer of Ownership:</li>
                    <Image src={Image3} fluid />
                    <li>Perection Exchange:</li>
                    <Image src={Image4} fluid />
                    <li>Signature:</li>
                    <Image src={Image5} fluid />
                </ol>
                <p className="instruction">Where do I get Champion Points?</p>
                <ol>
                    <li>Hourly Bosses.</li>
                    <li>Treasure Forest gems swap.</li>
                    <li>Power Arena.</li>
                    <li>Elite Pk rewards.</li>
                    <li>Daily Quests(Worship).</li>
                    <li>Exchanging Online Points.</li>
                </ol>
                <p className="instruction">Where do I get Perfection Stones?</p>
                <ol>
                    <li>Raid Boss.</li>
                    <li>Treasure Forest gems swap.</li>
                    <li>Elite Pk rewards.</li>
                    <li>Team PK rewards.</li>
                </ol>
            </div>
        </div>
    );
}