import Axios from 'axios';
import { getToken } from './TokenService'

export function getLauncherVersion() {
    return new Promise(resolve => {
        Axios.get('http://patches.paragonconquer.com/getLauncherVersion')
          .then(function (response) {
            console.log(response);
            if (response.data["error"] === undefined) {
                resolve(response.data);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function uploadLauncherFile(file) {
    return new Promise(resolve => {
      Axios.post('https://www.paragonconquer.com/api/uploadNewLauncher', {
          file
        }, {
          headers: {
              'Authorization': 'Bearer ' + getToken()
          }
        })
        .then(function (response) {
          if (response.data["error"] === undefined) {
              resolve(response.data.result);
          } else {
              resolve(response.data.error);
          }
        })
        .catch(function (error) {
          resolve(false);
        });
  })
}

export function updateLauncherVersion(version) {
    return new Promise(resolve => {
      Axios.post('https://www.paragonconquer.com/api/setLauncherVersion', {
          version
        }, {
          headers: {
              'Authorization': 'Bearer ' + getToken()
          }
        })
        .then(function (response) {
          if (response.data["error"] === undefined) {
              resolve(response.data.result);
          } else {
              resolve(response.data.error);
          }
        })
        .catch(function (error) {
          resolve(false);
        });
  })
}