import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/fonts.css';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from './components/Header'
import SidebarAccount from './components/SidebarAccount'
import { Home } from './Pages/Home'
import { Register } from './Pages/Register'
import { ChangePassword } from './Pages/ChangePassword'
import { ResetPassword } from './Pages/ResetPassword'
import { MyAccount } from './Pages/MyAccount'
import { Download } from './Pages/Download'
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';
import { NavigationBar } from './components/NavigationBar';
import { AccountProvider } from './Context/AccountProvider';
import { Guide } from './Pages/Guide';
import { Rules } from './Pages/Rules';
import { FrequentlyAskedQuestions } from './Pages/FrequentlyAskedQuestions';
import { EventSchedule } from './Pages/EventSchedule';
import { TicketPage } from './Pages/TicketPage';
import { TicketView } from './Pages/TicketView';
import { CreateTicket } from './Pages/CreateTicket'
import { StaffTicketPage } from './Pages/StaffTicketPage';
import { UsefulLinks } from './components/UsefulLinks';
import { FacebookWidget } from './components/FacebookWidget';
import { UpdateNotes } from './components/UpdateNotes';
import { NobilityReset } from './components/NobilityReset';
import { TermsOfService } from './Pages/TermsOfService';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import { RefundPolicy } from './Pages/RefundPolicy';
import { Updates } from './Pages/Updates';
import { StaffSettingsPage } from './Pages/StaffSettingsPage';
import { PaymentLogs } from './Pages/PaymentLogs';
import { DonationStorePreview } from './Pages/DonationStorePreview';
import { StoreItemDetails } from './Pages/StoreItemDetails'
import { StaffAnnouncementManagement } from './Pages/StaffAnnouncementManagement';
import { BuyCoins } from './Pages/BuyCoins';
import { GuideDev } from './Pages/GuideDev';

function App() {
  return (
    <AccountProvider>
      <Router>
        <NavigationBar/>

        <Header/>
        
        <Container className="main-content">
          <Row className="upper-container justify-content-md-center">
            <Col xs="0" lg="3" className="main-left mobile-hidden" id="sidebarColumn">
              <SidebarAccount />
              <UsefulLinks />
              <UpdateNotes />
              <NobilityReset />
              <FacebookWidget />
            </Col>
            <Col xs="12" lg="8" className="main-right" id="contentColumn">
              <Banner />
              <div id="main-content" className="main-container">
                <Switch>
                  <Route path="/register" component={Register} />
                  <Route path="/download" component={Download} />
                  <Route path="/rules" component={Rules} />
                  <Route path="/guide" component={Guide} />
                  <Route path="/guidedev" component={GuideDev} />
                  <Route path="/eventschedule" component={EventSchedule} />
                  <Route path="/myaccount" component={MyAccount} />
                  <Route path="/changepassword" component={ChangePassword} />
                  <Route path="/buycoins" component={BuyCoins} />
                  <Route path="/buycoinstebex" component={BuyCoins} />
                  <Route path="/store/:id" component={StoreItemDetails} />
                  <Route path="/store" component={DonationStorePreview} />
                  <Route path="/resetpassword" component={ResetPassword} />
                  <Route path="/purchasehistory" component={PaymentLogs} />
                  <Route path="/faq" component={FrequentlyAskedQuestions} />
                  <Route path="/tos" component={TermsOfService} />
                  <Route path="/privacy" component={PrivacyPolicy} />
                  <Route path="/refundpolicy" component={RefundPolicy} />
                  <Route path="/createticket" component={CreateTicket} />
                  <Route path="/tickets" component={TicketPage} />
                  <Route path="/staff/announcements" component={StaffAnnouncementManagement} />
                  <Route path="/staff/tickets" component={StaffTicketPage} />
                  <Route path="/staff/settings" component={StaffSettingsPage} />
                  <Route path="/updates/:id" component={Updates} />
                  <Route path="/updates/" component={Updates} />
                  <Route path="/ticket/:id" component={TicketView} />
                  <Route path="/" component={Home} />
                </Switch>
              </div>
            </Col>
          </Row>
          <Row className="footer-container">
            <Col>
              <Footer/>
            </Col>
          </Row>
        </Container>
      </Router>
    </AccountProvider>
  );
}

export default App;
