import { Button } from "react-bootstrap";

function Rules() {
  return (
      <div className="white">
        <div className="rules-head center">
          <h3>Server Rules</h3>
          <h6>You must follow all of the rules on this page in order to play Paragon Conquer</h6>
        </div>
        <div className="rules-nav center">
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-1').scrollIntoView(true);}}>1</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-2').scrollIntoView(true);}}>2</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-3').scrollIntoView(true);}}>3</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-4').scrollIntoView(true);}}>4</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-5').scrollIntoView(true);}}>5</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-6').scrollIntoView(true);}}>6</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-7').scrollIntoView(true);}}>7</Button>
          <Button variant="info" as="span" onClick={()=>{document.getElementById('rule-8').scrollIntoView(true);}}>8</Button>
          
        </div>
        <div className="rules-body">
          <div className="rule" id="rule-1">
            <h5>1. Staff</h5>
            <p>Staff are volunteers who are here to help the community. Please be patient when asking for help and you will be assisted as soon as a staff member can help you.</p>
            <p>Do not impersonate staff, whether it is in-game or chat.</p>
          </div>
          <div className="rule" id="rule-2">
            <h5>2. Your account is your responsibility</h5>
            <p>If someone breaks the rules using your account, it is your account that will be punished.</p>
            <p>Scamming players is against rules, however your account and gears are your responsibility. We do not recommend sharing your account at all, support will not be given for scam cases regarding your account.</p>
          </div>
          <div className="rule" id="rule-3">
            <h5>3. Accounts/Trading of any form</h5>
            <p>Selling/Trading accounts/items/CPs outside the game for real life currencies or any other exchange, will lead to your account being permanently banned without prior notice/warning.</p>
          </div>
          <div className="rule" id="rule-4">
            <h5>4. Bots/Exploits - 3 Strike Rule</h5>
            <ul>
              <li>1st strike: 7 days</li>
              <li>2nd strike: 14 days</li>
              <li>3rd strike: Permanent Botjail/Ban</li>
            </ul>
            <p>If you are caught autohunting / using a bot/macro/program to hunt, the account hunting will be permanently banned & a strike will be added to your main account.  The only authorized way to hunt is manually.  Using any other method is against rules and will be punished accordingly.</p>
            <p>Using bots is against the rules and you will be punished based on your previous strikes.</p>
            <p>It is against the rules to exploit any bug/glitch or oversights found in the game to get an advantage. This includes duplication glitches and this will be punished according to the severity of the offence.</p>
            <p>Sharing or discussing bots/exploits will be considered an offence and will not be tolerated.</p>
          </div>
          <div className="rule" id="rule-5">
            <h5>5. Social rules (bullying and harassment)</h5>
            <p>Racial/ethnic slurs/slang or comments, personal insults, improper sexual or religious comments, offensive political discussions, discrimination, promoting self-harm and foul language WILL NOT be tolerated.</p>
            <p>Harassing via multiple characters and dodging account mutes is not accepted and will result in a harsher punishment.</p>
            <p>Sharing or publishing personal details of another player without their consent (doxing) is forbidden and will be taken extremely seriously.</p>
            <p>Malicious attempts to obtain private information (e.g. IP grabbing) is not accepted.</p>
          </div>
          <div className="rule" id="rule-6">
            <h5>6. Advertising</h5>
            <p>Any attempt to advertise another server is illegal, whether that is through the name or web links.</p>
            <p>Do not send any IP address or server domain names which can be used to connect to a server. This includes advertising through private messaging.</p>
          </div>
          <div className="rule" id="rule-7">
            <h5>7. Donation chargebacks</h5>
            <p>Attempting donation chargebacks is forbidden as per the Donation T&C’s and will result in a permanent HWID ban.</p>
          </div>
          <div className="rule" id="rule-8">
            <h5>8. General rule</h5>
            <p>By playing the server, you agree to the above rules. The staff reserve the right to apply the rules to their discretion and any player found to be harming the experience for other players, may be punished or banned.</p>
          </div>

          <div class="rule">
            <h5>Disclaimer</h5>
            <p style={{textAlign:'justify'}}>
              Paragon Conquer reserves the right to punish you for any abuse of the game, systems, or any acts that we deem have malicious intent behind them.
              </p>
          </div>
        </div>
      </div>
  );
}

export { Rules };