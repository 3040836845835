import { useState } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Facebook, Discord } from 'react-bootstrap-icons';

import { LoginModal } from './LoginModal'

import { useAccount } from '../Context/AccountProvider';
import { handleToken, handleLogout } from '../services/AuthService';
import { getToken } from '../services/TokenService'
import { getUserData } from '../services/UserService'
import { useHistory } from 'react-router-dom';

var loadCheck = false;

function NavigationBar() {
    var {accountInfo, setAccInfo, updateAccountInfo} = useAccount();
    const [ loginShow, setLoginShow ] = useState(false);
    var history = useHistory();

    async function runLoadCheck() {
      if (getToken() === null) {
          return;
      }

      let tokenValid = await handleToken(getToken());

      if (!tokenValid) {
          return;
      }

      let userData = await getUserData();
      updateAccountInfo({loggedIn: true, user: userData});
    }
  
    if (!loadCheck) {
        loadCheck = true;
        runLoadCheck()
    }

    if (loginShow && accountInfo.loggedIn) {
        setLoginShow(false);
    }

    function scrollPage() {
      document.getElementById('main-content').scrollIntoView(true);
    }

    function scrollPageDropdown() {
      window.scrollTo(0, window.pageYOffset + document.getElementById('main-content').getBoundingClientRect().top)
    }

    const redirectHome = () => {
      history.push('/');
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
        <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer exact to="/" onClick={scrollPage}>
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/register" onClick={scrollPage}>
              <Nav.Link>Register</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/download" onClick={scrollPage}>
              <Nav.Link>Download</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Information" id="collasible-nav-dropdown" className="nav-bar-dropdown">
                <LinkContainer exact to="/rules">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Game Rules</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer exact to="/tos">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Terms of Service</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer exact to="/privacy">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Privacy Policy</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer exact to="/refundpolicy">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Refund Policy</NavDropdown.Item>
                </LinkContainer>
                {/*<LinkContainer exact to="/store">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Coin Store</NavDropdown.Item>
                  </LinkContainer>*/}
            </NavDropdown>
            <NavDropdown title="Guide" id="collasible-nav-dropdown" className="nav-bar-dropdown">
                <LinkContainer exact to="/guide">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Player Guide</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer exact to="/eventschedule">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Event Schedule</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer exact to="/faq">
                    <NavDropdown.Item onClick={scrollPageDropdown}>Frequently Asked Questions</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link className="mobile-hidden" rel="noreferrer" href="https://www.facebook.com/Paragon-Conquer-103703422181032" target="_blank"><Facebook className="facebook" size="40"/></Nav.Link>
            <Nav.Link className="mobile-hidden" rel="noreferrer" href="https://discord.gg/F7tUnrBMDY" target="_blank"><Discord className="discord" size="40"/></Nav.Link>
            {            
                (!accountInfo.loggedIn) 
                ? 
                  <Nav.Link onClick={() => setLoginShow(true)}>Login</Nav.Link>
                :
                (accountInfo.loggedIn && accountInfo.user === undefined) 
                ?
                  <NavDropdown title="Account" id="collasible-nav-dropdown" className="nav-bar-dropdown">
                      <LinkContainer exact to="/changepassword">
                          <NavDropdown.Item onClick={scrollPageDropdown}>Change Password</NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={() => {setAccInfo({loggedIn:false}); handleLogout()}}>Logout</NavDropdown.Item>
                  </NavDropdown>
                :
                  <NavDropdown title={accountInfo.user.Name} id="collasible-nav-dropdown" className="nav-bar-dropdown">
                      <LinkContainer exact to="/myaccount">
                          <NavDropdown.Item onClick={scrollPageDropdown}>My Account</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer exact to="/changepassword">
                          <NavDropdown.Item onClick={scrollPageDropdown}>Change Password</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer exact to="/buycoins">
                          <NavDropdown.Item onClick={scrollPageDropdown}>Buy Coins</NavDropdown.Item>
                      </LinkContainer>
                      {/*<LinkContainer exact to="/store">
                          <NavDropdown.Item onClick={scrollPageDropdown}>Coin Store</NavDropdown.Item>
                        </LinkContainer>*/}
                      <LinkContainer exact to="/tickets">
                          <NavDropdown.Item onClick={scrollPageDropdown}>Support Tickets</NavDropdown.Item>
                      </LinkContainer>
                      {
                        (accountInfo.user.ServerStaff)
                        ?
                        <>
                          <NavDropdown.Divider />
                          <span className="nav-dropdown-heading">Staff</span>
                          <LinkContainer exact to="/staff/announcements">
                              <NavDropdown.Item onClick={scrollPageDropdown}>Announcements</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer exact to="/staff/tickets">
                              <NavDropdown.Item onClick={scrollPageDropdown}>Tickets</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer exact to="/staff/settings">
                              <NavDropdown.Item onClick={scrollPageDropdown}>Settings</NavDropdown.Item>
                          </LinkContainer>
                        </>
                        :
                        <></>
                      }
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={() => {setAccInfo({loggedIn:false}); handleLogout(); redirectHome()}}>Logout</NavDropdown.Item>
                  </NavDropdown>
            }
          </Nav>
          <LoginModal closeModal={setLoginShow} show={loginShow || accountInfo.showLogin} onHide={() => {setLoginShow(false); updateAccountInfo({showLogin: false})}}/>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}
  
export { NavigationBar };