import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass5/pic1.webp'
import Image2 from '../../images/Guide/BattlePass5/pic2.webp'
import Image3 from '../../images/Guide/BattlePass5/pic3.webp'
import Image4 from '../../images/Guide/BattlePass5/pic4.webp'
import Image5 from '../../images/Guide/BattlePass5/pic5.webp'
import Image6 from '../../images/Guide/BattlePass5/pic6.webp'
import Image7 from '../../images/Guide/BattlePass5/pic7.webp'
import Image8 from '../../images/Guide/BattlePass5/pic8.webp'
import Image9 from '../../images/Guide/BattlePass5/pic9.webp'
import Image10 from '../../images/Guide/BattlePass5/pic10.webp'
import Image11 from '../../images/Guide/BattlePass5/pic11.webp'
import Image12 from '../../images/Guide/BattlePass5/pic12.webp'
import Image13 from '../../images/Guide/BattlePass5/pic13.webp'
import Image14 from '../../images/Guide/BattlePass5/pic14.webp'
import Image15 from '../../images/Guide/BattlePass5/pic15.webp'
import Image16 from '../../images/Guide/BattlePass5/pic16.webp'
import Image17 from '../../images/Guide/BattlePass5/pic17.webp'
import { LinkContainer } from 'react-router-bootstrap';

export function BattlePass5() {
    return (
        <div className="guide white">
            <h3>Battle Pass</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #5.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>EssenceChest <i>(Twin City)</i></li>
                <li>DizzyBird <i>(Twin City)</i></li>
                <li>SpecialSpook<i>(Boss)</i></li>
                <li>SpecialTerato<i>(Boss)</i></li>
                <li>SungJinwoo <i>(Boss)</i></li>
                <li>SpecialOctopus <i>(Boss)</i></li>
                <li>SpecialHelhest <i>(Boss)</i></li>
                <li>SpecialApe <i>(Boss)</i></li>
                <li>Orochimaru<i>(Garment)</i></li>
                <li>Athleisure Assassin<i>(Garment)</i></li>
                <li>Akaza<i>(Garment)</i></li>
                <li>PassPack1 <i>(Item)</i></li>
                <li>Eminem<i>(Garment)</i></li>
                <li>PassPack2 <i>(Item)</i></li>
                <li>Killua(Suit)<i>(Garment)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 4 through the Coin Store for 10 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 5 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There are 15 different quests, each one gives 10 Battle Pass Points and 2M CPs(Except for winning EPk):
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to Shuang.</li>
                    <Image src={Image4} fluid />
                    <li>SpecialSpook: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image5} fluid />
                    <li>SpecialTerato: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image6} fluid />
                    <li>SungJinwoo: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image7} fluid />
                    <li>SpecialOctopus: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image8} fluid />
                    <li>SpecialHelhest: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image9} fluid />
                    <li>SpecialApe: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image10} fluid />

                    <li>Win Hourly Events: Upon winning any of the HOURLY events, you will get BattlePassPoints(10) item + 2M CPs.</li>
                    <li>Win 10 Arena Qualifier Matches: You must win 10 Arena Qualifier matches to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Defeat Hourly Bosses/Boss Invasion: Kill any of the Hourly Bosses or Boss Invasion to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Complete Tower of Mystery to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Infernal GW(Sign up through Balrog) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Elite GW(Sign up through GeneralRoo) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Elite Pk Tournament(Sign up through ElitePkEnvoy) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Win the Elite Pk/Skill Elite Pk Tournament to obtain 40 Battle Pass Points + 50M CPs.</li>
                </ol>
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>
            <ol>
                <ol>
                    <li>Level 1: 100M CPs, 5,000 Champion Points and (1) NameToken(Tradable)</li>
                    <li>Level 2: 100M CPs and 6,500 Champion Points.</li>
                    <li>Level 3: 100M CPs and 10,000 Champion Points.</li>
                    <li>Level 4: 100M CPs, 10,000 Champion Points and (1) Orochimaru(255 HP Bound Garment)</li>
                    <Image src={Image11} fluid />

                    <li>Level 5: 100M CPs, 12,000 Champion Points and (1) Athleisure Assassin(255 HP Bound Garment)</li>
                    <Image src={Image12} fluid />

                    <li>Level 6: 100M CPs, 15,000 Champion Points and (1) PassPack1(Untradable)</li>
                    <p className="tip">*PassPack1 is a clickable item where you get to choose whether you want Eminem(255 HP Bound Garment) or RuneStone+3(Tradable)</p>
                    <Image src={Image13} fluid />
                    <Image src={Image16} fluid />

                    <li>Level 7: 100M CPs, (1) PassPack2(Untradable) and Akaza(255 HP Bound Garment)</li>
                    <Image src={Image14} fluid />
                    <Image src={Image15} fluid />
                    <p className="tip">*PassPack2 is a clickable item where you get to choose whether you want Killua(Suit)(255 HP Bound Garment) or RuneStone+4(Tradable)</p>
                    <Image src={Image17} fluid />
                </ol>
            </ol>
        </div>
    );
}