import { useState } from 'react'
import { Form, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { checkUsernameTaken, registerAccount } from '../services/RegistrationService'
import ReCAPTCHA from 'react-google-recaptcha';

function Register() {
    const validationRegex = /^[a-zA-Z0-9!@#$%^&*()]{4,16}$/;
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [confirmEmailError, setConfirmEmailError] = useState("");
    const [outputText, setOutputText] = useState("");
    const [outputValid, setOutputValid] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState(false);
    var captEl;

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const usernameElement = document.getElementById("username");
        const passwordElement = document.getElementById("password");
        const confirmPasswordElement = document.getElementById("confirmPassword");
        const emailElement = document.getElementById("email");

        let valid = captchaResponse !== false && usernameElement.classList.contains("is-valid") && passwordElement.classList.contains("is-valid") && confirmPasswordElement.classList.contains("is-valid") && emailElement.classList.contains("is-valid");

        if (captchaResponse === false) {
            setOutputValid(false);
            setOutputText("Please complete the captcha.");
            return;
        }

        if (valid) {
            let registered = await registerAccount(usernameElement.value, passwordElement.value, emailElement.value, captchaResponse);

            if (registered) {
                setOutputValid(true);
                setOutputText("Your account has been successfully registered!");
                document.getElementById("registrationForm").reset();
                usernameElement.classList.remove("is-valid");
                passwordElement.classList.remove("is-valid");
                confirmPasswordElement.classList.remove("is-valid");
                emailElement.classList.remove("is-valid");
            } else {
                setOutputValid(false);
                setOutputText("There was an error registering your account, please try again.");
            }

            captEl.reset()
        } else {
            setOutputValid(false);
            setOutputText("Please enter valid information.");
        }
    };

    async function handleUsername(event) {
        const element = event.currentTarget;

        var username = element.value;

        if (username === "") {
            element.classList.remove("is-valid");
            element.classList.remove("is-invalid");
            return;
        }

        if (username.length >= 4 && username.length <= 14) {
            if (username.match(validationRegex) != null) {
                let usernameTaken = await checkUsernameTaken(username);
            
                if (!usernameTaken) {
                    element.classList.add("is-valid");
                    element.classList.remove("is-invalid");
                } else {
                    element.classList.remove("is-valid");
                    element.classList.add("is-invalid");
                    setUsernameError("Username taken!");
                }
            } else {
                element.classList.remove("is-valid");
                element.classList.add("is-invalid");
                setUsernameError("Invalid characters in username.");
            }
        } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
            setUsernameError("Your username must be between 4 and 14 characters!");
        }
    }

    const handlePassword = (event) => {
        const element = event.currentTarget;

        var password = element.value;

        handleConfirmPassword();

        if (password === "") {
            element.classList.remove("is-valid");
            element.classList.remove("is-invalid");
            return;
        }

        if (password.length >= 6 && password.length <= 14) {
            if (password.match(validationRegex) != null) {
                element.classList.add("is-valid");
                element.classList.remove("is-invalid");
            } else {
                element.classList.remove("is-valid");
                element.classList.add("is-invalid");
                setPasswordError("Invalid characters in password.");
            }
        } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
            setPasswordError("Your password must be between 6 and 14 characters!");
        }
    }

    const handleConfirmPassword = (event) => {
        const confirmPasswordElement = document.getElementById("confirmPassword");
        const passwordElement = document.getElementById("password");
       
        if (confirmPasswordElement.value === "") {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
            return;
        }

        if (confirmPasswordElement.value === passwordElement.value) {
            confirmPasswordElement.classList.add("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
        } else {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.add("is-invalid");
            setConfirmPasswordError("Your passwords do not match.");
        }
    }

    const handleEmail = (event) => {
        const element = event.currentTarget;
        
        if (element.value === "") {
            element.classList.remove("is-valid");
            element.classList.remove("is-invalid");
            return;
        }

        if (element.checkValidity()) {
            element.classList.add("is-valid");
            element.classList.remove("is-invalid");
        } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
            setConfirmEmailError("Enter a valid email.");
        }
    }

    return (
        <div className="center registration">
            <h3 className="white">Registration</h3>
            
            <div className="regform">
                <Form id="registrationForm" noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="username" label="Enter Username" className="mb-3">
                            <Form.Control controlId="username" required type="text" placeholder="Enter Username" onChange={handleUsername} />
                            <Form.Control.Feedback tooltip>Username available!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" tooltip>
                                {usernameError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="password" label="Enter Password" className="mb-3">
                            <Form.Control required type="password" placeholder="Enter Password" onChange={handlePassword} />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {passwordError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="confirmPassword" label="Confirm Password" className="mb-3">
                            <Form.Control required type="password" placeholder="Confirm Username" onChange={handleConfirmPassword} />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {confirmPasswordError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <FloatingLabel controlId="email" label="Enter Email" className="mb-3">
                            <Form.Control required type="email" placeholder="Enter Email" onChange={handleEmail} />
                            <Form.Control.Feedback type="invalid" tooltip>
                                {confirmEmailError}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <ReCAPTCHA id="re-captcha" className="register-captcha" ref={(recaptchaRef) => {captEl = recaptchaRef}} sitekey="6LdFauQdAAAAAGyoyHMjIaDeUQCOC7ewif0ENiXI" onChange={setCaptchaResponse} onExpired={()=>{setCaptchaResponse(false)}} onErrored={()=>{setCaptchaResponse(false)}}/>

                    <p id="registration-output-text" className={(outputValid) ? "green-reg-message" : "red-reg-message"}>{outputText}</p>
                    
                    <Button variant="primary" type="submit">
                        Register
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export { Register };