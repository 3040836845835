export function RefundPolicyText() {
    return(
        <div>
            <p>Money spent on the server will be non-refundable and all donations are final. Paragon Conquer does not offer refunds or exchanges on any purchases made on the server.</p>
            <p>Please inspect items carefully before purchasing them or before adding Paragon Coins to your account.</p>
            <p>Disputing any payments or attempting to “charge back” will result in a permanent HWID ban for your accounts.</p>
            <p>If there are any issues with your payments, please contact us straight away through our website, or discord, so we can quickly rectify your issue.</p>
            <p>By playing the server, you agree to the terms of service and refund policy.</p>
            <p>If you have any questions or enquiries about our refund policy, or any other information on our website, please contact us and we will reply promptly.</p>
        </div>
    );
}