import { useState } from "react";
import { Button } from "react-bootstrap";
import { LoginModal } from "./LoginModal";

export default function AuthenticationError(props) {
    const [showModal, setShowModal] = useState(true);

    return (
      <div style={{textAlign: 'center', color: '#fff'}}>
        <h1>ERROR 401</h1>
        <h3>You must be authenticated to access this content.</h3>
        <h4>Please <Button onClick={() => {setShowModal(true);}}>Login</Button></h4>
        <LoginModal closeModal={setShowModal} show={showModal} onHide={() => {setShowModal(false);}}/>
      </div>
    )
}