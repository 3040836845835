import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/EpicTrojanQuest/pic1.jpg'
import Image2 from '../../images/Guide/EpicTrojanQuest/pic2.jpg'
import Image3 from '../../images/Guide/EpicTrojanQuest/pic3.jpg'
import Image4 from '../../images/Guide/EpicTrojanQuest/pic4.jpg'
import Image5 from '../../images/Guide/EpicTrojanQuest/pic5.jpg'

export function EpicTrojanQuest() {
    return (
        <div className="guide white">
            <h3>Epic Trojan Quest</h3>
            
            <p className="head">Requirements</p>
            <ul>
                <li>Level 140</li>
                <li>2nd Reborn Character</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>TrojanStar <i>(Job Center)</i></li>
                <li>GeneralPak <i>(Trojan's Cave)</i></li>
                <li>Vessel <i>(Trojan's Cave)</i></li>
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>TrojanSeal</li>
                <li>SolarBlades</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to TrojanStar to enter the Trojan's Cave</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to GeneralPak and buy the TrojanSeal</p>
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Vessel and use the TrojanSeal to summon the EvilTrojan</p>
                    <Image src={Image3} fluid />
                    <p className="tip">*Defeat EvilTrojan to get (2) SolarBlade's for converting your weapons to the Epic CrossSabers</p>
                </li>
                <li>
                    <p className="instruction">Talk to GeneralPak to learn the Trojan Epic Skills</p>
                    <Image src={Image4} fluid />
                    <Image src={Image5} fluid />
                </li>
            </ol>
        </div>
    );
}