import Axios from 'axios';
import { getToken } from './TokenService'

export function getUserData() {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/user', {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function getPaymentHistory(userid) {
  return new Promise(resolve => {
    Axios.get('https://www.paragonconquer.com/api/getPurchaseHistory/' + userid, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data);
        } else {
            resolve(false);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
})
}