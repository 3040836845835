import { DiscordWidget } from '../components/DiscordWidget';
import { GameFeatures } from '../components/GameFeatures/GameFeatures';
import { Video } from '../components/Video';

function Home() {
  return (
      <div>
        <GameFeatures />
        <Video />
        <DiscordWidget />
      </div>
  );
}

export { Home };