import Axios from 'axios';
import { SHA3 } from 'sha3';
import { getToken } from './TokenService';

export function validateResetToken(token) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/validateResetToken', {
            jwt: token
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(response.data.error);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function sendResetRequest(username) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/sendPasswordReset', {
            username: username
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(response.data.error);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function resetPassword(token, password) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/resetPassword', {
            jwt: token,
            newpassword: new SHA3(256).update(password).digest('hex')
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(response.data.error);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function changePassword(oldPassword, newPassword) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/changePassword', {
            oldpassword: new SHA3(256).update(oldPassword).digest('hex'),
            newpassword: new SHA3(256).update(newPassword).digest('hex')
          }, {
            headers: {
                'Authorization': 'Bearer ' + getToken()
            }
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(response.data.error);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}