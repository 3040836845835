import { Col, Row } from "react-bootstrap";
import { getDateString } from "../../css/Utility";

export function TicketHeader(props) {
    return(
        <div className="meta">
            <Row>
                <Col>
                    <p><b>Ticket ID:</b> {props.data.id}</p>
                </Col>
                <Col>
                    <p><b>Character:</b> {props.data.character}</p>
                </Col>
                <Col>
                    <p><b>Date Opened:</b> {getDateString(props.data.timestamp)}</p>
                </Col>
                <Col xs={9}>
                    <p><b>Subject:</b> {props.data.subject}</p>
                </Col>
                <Col xs={3}>
                    <p><b>Status:</b> {props.data.status}</p>
                </Col>
            </Row>
        </div>
    );
}