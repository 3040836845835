import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { getAllDonationStoreItems } from "../services/DonationStoreService";
import search from '../images/search.svg';
import { LinkContainer } from "react-router-bootstrap";

export function DonationStorePreview() {
  const [donationStore, setDonationStore] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  
  const [visibleCategory, setVisibleCategory] = useState("all");

  useEffect(() => {
    let sidebar = document.getElementById('sidebarColumn');
    let main = document.getElementById('contentColumn');
    let mainContent = document.getElementById('main-content');

    sidebar.classList.add('hide');
    main.classList.remove('col-lg-8');
    main.classList.add('col-lg-10');
    mainContent.style = "width: 100%;";

    return () => {
      sidebar.classList.remove('hide');
      main.classList.add('col-lg-8');
      main.classList.remove('col-lg-10');
      mainContent.style = "";
    }
  }, []);

  if (!loaded) {
    setLoaded(true);
    (async() => {
      var donationData = await getAllDonationStoreItems();
      setDonationStore(donationData.result);
    })();
  }

  function onSearchInput(e) {
    setSearchTerm(e.currentTarget.value)
  }

  if (loaded && donationStore) {
    return (
<div>
      <Row>
        <Col xs={12} md={6}>
          <h1 className="white">Paragon Store</h1>
        </Col>
        <Col>
          <InputGroup className="mb-3">
          <InputGroup.Text><img style={{width: "16px"}} src={search}/></InputGroup.Text>
          <Form.Control placeholder="Search the Paragon Store" aria-label="Search the Paragon Store" onInput={(e) => {onSearchInput(e)}}/></InputGroup>
        </Col>
      </Row>

      <hr className="white-hr"/>
      
      <Row>
        <Col xs={12} md={2}>
          <Nav defaultActiveKey="filter-all" className="flex-column store-categories">
            <p>Item Categories</p>
            <Nav.Link eventKey="filter-all" onClick={() => {setVisibleCategory("all")}}>All Items</Nav.Link>
            {getItemCategories(donationStore, setVisibleCategory)}
          </Nav>
        </Col>
        <Col>
          <Row>
              {getStore(donationStore, visibleCategory, searchTerm)}
            </Row>
        </Col>
      </Row>
      </div>
    )
  } else {
    return (
      <div className="loading-container">
        <p>Paragon Store</p>
        <div class="loading-spinner"></div>
      </div>
    )
  }
}

function getItemCategories(data, setCategory) {
  var markup = [];

  for (var item of [...new Set(data.map((item) => item.type))]) {
    const itemKey = item;
    markup.push(
      <Nav.Link eventKey={`filter-${item}`} onClick={() => {setCategory(itemKey)}}>{item}</Nav.Link>
    )
  }

  return markup;
}

function getStore(data, category, searchTerm) {
  var markup = [];

  for (let item of data) {
    if (category !== "all" && category !== item.type) {
      continue;
    }

    let searchable = (item.name + item.description + item.type + item.price).toLowerCase();

    var cancelRender = false;

    for (let term of searchTerm.toLowerCase().split(" ")) {
      if (term.trim() == "") {
        continue;
      }

      if (!searchable.includes(term)) {
        cancelRender = true;
        break;
      }
    }

    if (cancelRender) {
      continue;
    }

    markup.push(
      <Col xl={4} md={6} data-type={item.type}>
        <div className="store-item">
          <Row>
            <Col xs={3}>
            <img loading="lazy" style={{width: "100%"}} src={require(`../images/DonationItems/${item.img_name}`)} alt={`Icon for ${item.name}`}/>  
            </Col>
            <Col xs={9}>
              <div style={{paddingLeft: "8px"}}>
                <p className="item-name" style={{margin: "0"}}>{item.name}</p>
              </div>              
            </Col>
          </Row>
          <div className="item-line2">
            <LinkContainer exact to={`/donationstorepreview/${item.id}/${item.name}`}>
              <button className="details-button">View Details</button>
            </LinkContainer>
            <span style={{color: "gold", float: "right"}}>{item.price} Coins</span>
            <div style={{clear: "both"}}></div>
          </div>
          <hr/>
          <p>{item.description}</p>
        </div>
      </Col>
    )
  }

  return markup;
}