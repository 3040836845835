import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/MiniCups/pic1.jpg'
import Image2 from '../../images/Guide/MiniCups/pic2.jpg'
import Image3 from '../../images/Guide/MiniCups/pic3.jpg'
import Image4 from '../../images/Guide/MiniCups/pic4.jpg'
import Image5 from '../../images/Guide/MiniCups/pic5.jpg'

export function MiniCups() {
    return (
        <div className="guide white">
            <h3>New bless to MiraculousGourd, MiniCups and Event Points</h3>
            <ol>
                <p className="head">MiraculousGourd:</p>
                <ol>
                    <li>MiraculousGourd can now be blessed up to -7 as part of a first tier for only 5M CPs through Arwen, Market.</li>
                    <Image src={Image1} fluid />
                    <Image src={Image2} fluid />
                </ol>
                <p className="head">MiniCups:</p>
                <ol>
                    <li>We've created 3 Free-to-get Mini versions of the Donation Cups as part of the second tier. The requirements are 7500 Online Points and 6000 Event Points to forge any of them.</li>
                    <Image src={Image3} fluid />
                    <Image src={Image4} fluid />
                    <Image src={Image5} fluid />
                </ol>
                <p className="head">Event Points:</p>
                <ol>
                    <li>Event Points are a new currency given to those who participate in the hourly events, You get 50 points for taking place in them.</li>
                </ol>        
            </ol>
        </div>
    );
}