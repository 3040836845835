import { ContentBlock } from "../ContentBlock/ContentBlock";
import './GameFeatures.css';

export function GameFeatures() {
    return(
        <ContentBlock header="Game Features">
            <ul className="game-features-list">
                <li>- US Hosted Server.</li>
                <li>- Version 6609 (Up to WindWalker)</li>
                <li>- Max level 140 with high leveling rate.</li>
                <li>- Free starter gears and a newbie quest to learn game basics.</li>
                <li>- CPs drop 150, Fairly balanced spawns to guarantee the best experience.</li>
                <li>- Subclasses, Chi system and refinery are available(No Jiang-Hu)</li>
                <li>- Unique and fully customisable cosmetics, with personal custom garments/accessories available.</li>
                <li>- Epic classes(Ninja,Trojan,Monk & Taoist)</li>
                <li>- Custom and traditional PVP/PVE events. </li>
                <li>- Ticket system to ensure the best game-to-player experience.</li>
            </ul>    
        </ContentBlock>
    );
}