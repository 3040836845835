import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/ChiSystem/pic1.jpg'
import Image2 from '../../images/Guide/ChiSystem/pic2.jpg'
import Image3 from '../../images/Guide/ChiSystem/pic3.jpg'
import Image4 from '../../images/Guide/ChiSystem/pic4.jpg'
import Image5 from '../../images/Guide/ChiSystem/pic5.jpg'
import Image6 from '../../images/Guide/ChiSystem/pic6.jpg'
import Image7 from '../../images/Guide/ChiSystem/pic7.jpg'
import Image8 from '../../images/Guide/ChiSystem/pic8.jpg'
import Image9 from '../../images/Guide/ChiSystem/pic9.jpg'
import Image10 from '../../images/Guide/ChiSystem/pic10.jpg'
import Image11 from '../../images/Guide/ChiSystem/pic11.jpg'
import Image12 from '../../images/Guide/ChiSystem/pic12.jpg'
import Image13 from '../../images/Guide/ChiSystem/pic13.jpg'
import Image14 from '../../images/Guide/ChiSystem/pic14.jpg'

export function ChiSystem() {
    return (
        <div className="guide white">
            <h3>Chi System</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers basics and our recommendations about Chi.</li>
            </ul>

            <p className="head">How to unlock Chi?</p>
            <ol>
                <li>
                    <p className="instruction">The Dragon Chi, Phoenix Chi, Tiger Chi and Turtle Chi represent four kinds of locked elemental power. When you reach Level 110 of your 1st rebirth, you are able to unlock the Chi power. Click the "Chi" button at the bottom of the character status to access to the Chi system.</p>
                    <Image src={Image1} fluid />
                    <Image src={Image2} fluid />
                    <p className="instruction">You need to unlock the Chi powers one by one from the Dragon Chi, Phoenix Chi, Tiger Chi to Turtle Chi at last. Click the "Unlock" button to study the Dragon Chi, first. When your Dragon Chi Score reaches 300, you can unlock the Phoenix Chi. It costs 300 Chi Points to unlock a Chi power, each time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>

            <p className="head">What are the Chi Attributes?</p>
            <ol>
                <li>
                    <p className="instruction">After you unlock a Chi power, 4 attributes with a random type and effect will be attached to that Chi. Different Chi powers may have the same type of attribute, while one Chi power must have four different attributes. The attributes can be replaced with new ones by studying with Chi Points.</p>
                    <Image src={Image4} fluid />

                    <p className="instruction">There are 13 types of Chi attributes. Different colors of the attributes refer to different effects (from the lowest grey, to white, blue, green, purple, golden and the highest orange). The Chi Score is calculated based on each attribute's effect. (Full score: 400)</p>
                    <Image src={Image5} fluid />
                    <p className="tip">*Note that we are only covering the ones the main ones/the ones that are of actual use.</p>
                </li>
            </ol>

            <p className="head">How to study?</p>
            <ol>
                <li>
                    <p className="instruction">You can replace the attached attributes with new ones by studying with Chi Points. If you want to keep some Chi attributes, you can lock them by checking the box next to it. The locked attributes won't be replaced while refreshing. However, the more attributes you lock, the more Chi Points it will cost to study.</p>
                    <Image src={Image6} fluid />

                    <p className="instruction">You also can preset your desired attributes in the Attribute Settings, and then when you get an attribute which has a better effect than the preset value, you will be informed.</p>
                    <Image src={Image7} fluid />
                    <Image src={Image8} fluid />
                    <p className="instruction">If you have a Level 2 or above VIP membership, you also can use the auto-study function. First, you need to set your desired attributes in the Settings, and then decide the auto study times. If you get the desired attribute or you use out all the Chi Points, the auto-study will stop.</p>
                    <Image src={Image9} fluid />
                </li>
            </ol>

            <p className="head">Chi builds and our recommendations:</p>
            <ul>
                <li>The build varies between nobility and here are the builds we recommend to run.</li>
            </ul>
            <ol>
                <li>
                    <p className="instruction">For Earls we highly recommend the Break build, this build gives you everything an earl needs.</p>
                    <Image src={Image10} fluid />

                    <p className="instruction">Fire Taoists have only one build because Nobility doesn't increase your damage.</p>
                    <Image src={Image14} fluid />

                    <p className="instruction">Dukes can be tricky, all 3 builds are viable, depends on your preferences. Option 1 is what we would think is the best for it, you would be able to break princes and kings and keep anti break agaisnt Earls. </p>
                    <Image src={Image11} fluid />

                    <p className="instruction">Princes build is easier than duke, going break with it wouldn't be the better option because you would only be able to trigger it on Kings while going critical strike would work on everyone.</p>
                    <Image src={Image12} fluid />

                    <p className="instruction">King has 2 options, also up to your preference, do you want to deal more damage and struggle with Fire Taoists or miss out on critical strike to have Immunity and not struggle agaisnt Fire Taoists?</p>
                    <Image src={Image13} fluid />
                </li>
            </ol>
        </div>
    );
}