import { useEffect, useState } from 'react';
import { getUpdateNotes } from '../services/UpdateNotesService';
import { Link } from './Link'

export function UpdateNotes() {
    const [updateNotes, setUpdateNotes] = useState([]);

    useEffect(() => {
        (async () => {
            setUpdateNotes(await getUpdateNotes());
        })();
    }, [])

    return (
        <div className="sidebar-object">
            <div className="sidebar-header">
                <span className="h-large">U</span><span className="h-small">pdate</span>&nbsp;&nbsp;<span className="h-large">N</span><span className="h-small">otes</span>
            </div>
            <div className="sidebar-body">
                <div className="update-notes">
                    {updateNoteMarkup()}
                </div>
                <div className="update-notes-more">
                    <Link local href="/updates" text="View All &raquo;"></Link>
                </div>
            </div>
        </div>
    );

    function updateNoteMarkup() {
        let markup = [];

        let max = 5;

        for (let note of updateNotes.sort((a, b) => {
            return new Date(b.Date) - new Date(a.Date)
        })) {
            if (max === 0) {
                break;
            }

            markup.push(<Link local href={`/updates/${note.ID}/${note.Title.replaceAll(' ', '-')}`} text={`${new Date(note.Date).toLocaleString('en-US', { month: 'short', day: 'numeric' })} -  ${note.Title}`}></Link>)
            max--;
        }

        return markup;
    }
}