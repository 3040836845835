import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { SectionHeader } from "../../../SectionHeader/SectionHeader";

export function ExistingPatches() {
    const [field, setField] = useState([]);

    return (
        <div>
            <SectionHeader underlined>Delete existing patches</SectionHeader>
            <form>
                <Form.Group as={Col} controlId="my_multiselect_field" className="mb-3">
                    <Form.Label>Patch list</Form.Label>
                    <Form.Control as="select" multiple value={field} onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                        <option value="field1">1001.zip | 22kb</option>
                        <option value="field2">1002.zip | 2201kb</option>
                        <option value="field3">1003.zip | 427mb</option>
                    </Form.Control>
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary">Delete Selected Patches</Button>
                </div>
            </form>
        </div>
    )
}