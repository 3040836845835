import { Carousel } from 'react-bootstrap';
import kingdomwar from '../images/webp/kingdomwar.webp';
import raidboss from '../images/webp/raidboss.webp';
import newbieguide from '../images/webp/newbie-guide.webp';
import { LinkContainer } from 'react-router-bootstrap';

function Banner() {
    return (
        <Carousel className="mobile-hidden" fade touch={false} keyboard={false} controls={false} indicators={true}>
            <Carousel.Item className="clickable">
                <LinkContainer exact to="/guide/newbies/NewbieGuide">
                    <div>
                        <img
                        className="header-carousel d-block w-100"
                        src={newbieguide}
                        alt="Newbie Guide slide"
                        />

                        <Carousel.Caption>
                        <h3>Newbie Quest</h3>
                        <p>Complete this quest to get started!</p>
                        </Carousel.Caption>
                    </div>
                </LinkContainer>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="header-carousel d-block w-100"
                src={kingdomwar}
                alt="Kingdom War slide"
                />
                <Carousel.Caption>
                <h3>Kingdom War</h3>
                <p>Fight to win glory for your Guild!</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="header-carousel d-block w-100"
                src={raidboss}
                alt="Raid boss slide"
                />

                <Carousel.Caption>
                <h2>Raid Bosses</h2>
                <p>Defeat Raid Bosses for grand rewards.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}
  
export { Banner };