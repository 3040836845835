import { Col, Row } from 'react-bootstrap';
import { Facebook, Discord } from 'react-bootstrap-icons';
import XtremeTop100 from '../images/xtremetop.png';

function Footer() {
    return (
        <footer>
            <div className="footer-main">
                <Row>
                    <Col xs={6} lg={2} className="offset-lg-1">
                        <p class="foothead">Join us on Social Media</p>
                        <h1>
                            <a aria-label="Paragon Conquer Facebook Page" className="facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Paragon-Conquer-103703422181032"><Facebook/></a>
                            &nbsp;
                            <a aria-label="Paragon Conquer Discord Server" className="discord" target="_blank" rel="noreferrer" href="https://discord.gg/F7tUnrBMDY"><Discord/></a>
                        </h1>
                    </Col>
                    <Col xs={6} lg={2} className="order-lg-3">
                        <p class="foothead">Support Paragon Conquer</p>
                        <a href="https://www.xtremetop100.com/in.php?site=1132372689" target="_blank" rel="noreferrer">
                            <img className="xtreme-img" src={XtremeTop100} alt="Voting Link to XtremeTop100"/>
                        </a>
                    </Col>
                    <Col xs={12} lg={6} className="footer-vertical-align">
                        <span>&copy; 2022-{new Date().getFullYear()} Paragon Conquer. All Rights Reserved</span>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}

export { Footer };