import Axios from 'axios';
import { SHA3 } from 'sha3';

function registerAccount(username, password, email, captcha) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/register', {
            u: username,
            p: new SHA3(256).update(password).digest('hex'),
            e: email,
            c: captcha
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

function checkUsernameTaken(username) {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/checkAccountExistence/' + username)
        .then(response => {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(null);
            }
        })
        .catch(error => {
            resolve(null);
        })
    })
}

export { checkUsernameTaken, registerAccount }