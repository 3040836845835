import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/TreasureForest/pic1.webp'
import Image2 from '../../images/Guide/TreasureForest/pic2.webp'
import Image3 from '../../images/Guide/TreasureForest/pic3.webp'
import Image4 from '../../images/Guide/TreasureForest/pic4.webp'
import Image5 from '../../images/Guide/TreasureForest/pic5.webp'
import Image6 from '../../images/Guide/TreasureForest/pic6.webp'

export function TreasureForest() {
    return (
        <div className="guide white">
            <h3>Treasure Forest</h3>

            <p className="head">About:</p>
            <ol>
                <li>
                    <p className="instruction">Treasure Forest is an event designed to help new players progress in a non-pk zone. While farming you're going to auto-loot the following items:</p>
                    <Image src={Image1} fluid />
                    <Image src={Image2} fluid />
                    <Image src={Image3} fluid />
                    <p className="tip">*Upon completing 5 SapphireGemShard's all you have to do is right click it and it'll convert into SapphireGem, then just right click to obtain 3M CPs.</p>
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">DavyJones spawns 30 minutes into the event. It spawns near the BOSS mark in the minimap.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                </li>
            </ol>

            <p className="head">DavyJones has a damage ranking for the top 5:</p>
            <ol>
                <li>Rank 1: 15M CPs.</li>
                <li>Rank 2: 12M CPs. </li>
                <li>Rank 3: 10M CPs. </li>
                <li>Rank 4: 8M CPs. </li>
                <li>Rank 5: 6M CPs. </li>
            </ol>
        </div>
    );
}