import { useState } from "react";
import { Form, FloatingLabel, Button, Col, Row } from "react-bootstrap";
import { closeTicket, replyTicket } from "../../services/TicketService";
import { TicketMessage } from "./TicketMessage";

export function TicketBody(props) {
    const [outputText, setOutputText] = useState("");
    const [render, setRender] = useState(false);
    const [status, setStatus] = useState(props.status)

    async function reply() {
        const message = document.getElementById('ticketReply');

        if (!message.value.trim()) {
            setOutputText("Please write a reply.");
            return;
        }
        
        let response = await replyTicket(props.id, message.value)

        if (response) {
            props.data.push(response)
            message.value = '';
            setRender(!render);
        }
    }

    async function close() {        
        let response = await closeTicket(props.id)

        if (response) {
            setStatus("Closed");
        }
    }

    return(
        <div className="body">
            {getMessages(props.data)}
            <Row className="reply-area">
                <Col>
                    <Form>
                        <FloatingLabel controlId="ticketReply" label="Reply">
                            <Form.Control as="textarea" placeholder="Reply" />
                        </FloatingLabel>
                        <div className="center" style={{color: 'pink', marginTop: '1em', fontWeight: 'bold'}}>{outputText}</div>
                        <Button onClick={reply}>Reply</Button>
                        <Button onClick={close} disabled={(status === 'Closed')}>Close Ticket</Button>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

function getMessages(messages) {
    let messageArray = [];

    for (const message of messages) {
        messageArray.push(<TicketMessage message={message}/>)
    }

    return messageArray;
}