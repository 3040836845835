import { Link } from './Link'

export function UsefulLinks() {
    return(
        <div className="sidebar-object">
            <div className="sidebar-header">
                <span className="h-large">U</span><span className="h-small">seful</span>&nbsp;&nbsp;<span className="h-large">L</span><span className="h-small">inks</span>
            </div>
            <div className="sidebar-body">
                <div className="useful-links">
                    <Link local href="/register" text="&nbsp;-&nbsp;&nbsp;Create Account"></Link>
                    <Link local href="/guide" text="&nbsp;-&nbsp;&nbsp;Gameplay Guide"></Link>
                    <Link local href="/rules" text="&nbsp;-&nbsp;&nbsp;Game Rules"></Link>
                    <Link local href="/createticket" text="&nbsp;-&nbsp;&nbsp;Create a Support Ticket"></Link>
                </div>
            </div>
        </div>
    );
}