import Axios from 'axios';
import { getToken } from './TokenService'

export function getUpdateNotes() {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/getUpdateNotes')
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve([]);
            }
          })
          .catch(function (error) {
            resolve([]);
          });
    })
}

export function getUpdateNote(id) {
  return new Promise(resolve => {
    Axios.get(`https://www.paragonconquer.com/api/getUpdateNote/${id}`)
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function createUpdateNote(title, content, date) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/createUpdateNote', {
        title: title,
        content: content,
        date: date
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function modifyUpdateNote(noteId, title, content, date) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/modifyUpdateNote', {
        noteId: noteId,
        title: title,
        content: content,
        date: date
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

export function deleteUpdateNote(noteId) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/deleteUpdateNote', {
        noteId: noteId
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
  })
}

