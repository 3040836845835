import { useEffect, useState } from 'react';

function Clock() {
    const [time, setTime] = useState(getTime());
    
    useEffect(() => {
        const interval = setInterval(
          () => setTime(getTime()),
          1000
        );
     
        return () => {
          clearInterval(interval);
        }
      }, []);

    return (
        <p>{time}</p>
    )
}

function getTime() {
    var d = new Date();
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    var nd = new Date(utc + (3600000*-2));
    return nd.toLocaleString();
}

export { Clock }