import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from '../Context/AccountProvider';
import { fetchTicket } from "../services/TicketService";
import { getToken } from '../services/TokenService'
import AuthenticationError from "../components/AuthenticationError";
import { Ticket } from "../components/Ticket";

export function TicketView() {
  const { id } = useParams();
  const { accountInfo } = useAccount();

  const [ticket, setTicket] = useState();
  const [ticketLoad, setTicketLoad] = useState(false)

  async function loadTicket() {
    let tckt = await fetchTicket(id)
    setTicketLoad(true)
    setTicket(tckt);
  }

  if (accountInfo.loggedIn) {
    if (!ticket) {
      if (!ticketLoad) {
        loadTicket();
        return(
          <div>
            loading ticket
          </div>
        )
      } else {
        return (
          <div>
            err
            {JSON.stringify(ticket)}
          </div>
        )
      }
    } else {
      return (
        <div>
          <Ticket data={ticket}/>
        </div>

      )
    }
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}