export function Video() {
    return(
        <div className="video-object">
            <div className="video-header">
                <span className="h-large">G</span><span className="h-small">ameplay</span>&nbsp;&nbsp;<span className="h-large">T</span><span className="h-small">railer</span>
            </div>
            <div className="video-body">
                <iframe src="https://www.youtube-nocookie.com/embed/m0JV36wJ_E8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    );
}