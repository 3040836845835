import { LinkContainer } from 'react-router-bootstrap';

export function Link(props) {
    if (props.local) {
        return(
            <div className="link-object">
                <LinkContainer exact to={props.href}>
                    <span>{props.text}</span>
                </LinkContainer>
            </div>
        );
    } else {
        return(
            <div className="link-object">
                <a href={props.href}>{props.text}</a>
            </div>
        );
    }
}