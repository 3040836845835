export function TermsOfService() {
  return (
      <div className="info-page">
        <div className="info-header">
          <span className="h-xlarge">T</span><span className="h-large">erms</span> <span className="h-xlarge">O</span><span className="h-large">f</span> <span className="h-xlarge">S</span><span className="h-large">ervice</span>
        </div>
        <div className="info-text">
          <h5>By making an account, and playing the server, you agree to the terms of service and any future amendments made to this document.</h5>
          <p>- These terms of service govern your access to the server and our services.</p>
          <p>- You may not use our server to do or share anything that violates these terms, our server rules and other terms and policies.</p>
          <p>- Unlawful and/or fraudulent behaviour is prohibited.</p>
          <p>- You agree that the staff of the server will not be held liable for content posted on the server by other users. If you see any offensive content, please report it through the correct avenues.</p>
          <p>- You agree to not expose minors using the service to any unsuitable content.</p>
          <p>- You agree that decisions made by the Paragon Conquer team on matters surrounding the server are final.</p>
          <p>- You agree to not partake in any action that could be detrimental to the server or other players.</p>
          <p>- You agree that the security of your account is in your hands and that account sharing is not supported by the server. If you are scammed or hacked with a shared account your case will be denied.</p>
          <p>- If you are found to be acting against these rules, we reserve the right to remove your access to the server without prior notice or warnings.</p>
        </div>
      </div>
  );
}