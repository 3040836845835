import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/NewbieGuide/pic1.webp'
import Image2 from '../../images/Guide/NewbieGuide/pic2.webp'
import Image3 from '../../images/Guide/NewbieGuide/pic3.webp'
import Image5 from '../../images/Guide/NewbieGuide/pic5.webp'
import Image6 from '../../images/Guide/NewbieGuide/pic6.webp'
import Image7 from '../../images/Guide/NewbieGuide/pic7.webp'
import Image11 from '../../images/Guide/NewbieGuide/pic11.webp'
import Image12 from '../../images/Guide/NewbieGuide/pic12.webp'
import Image13 from '../../images/Guide/NewbieGuide/pic13.webp'
import Image17 from '../../images/Guide/NewbieGuide/pic17.webp'
import Image18 from '../../images/Guide/NewbieGuide/pic18.webp'
import Image19 from '../../images/Guide/NewbieGuide/pic19.webp'
import Image23 from '../../images/Guide/NewbieGuide/pic23.webp'
import Image24 from '../../images/Guide/NewbieGuide/pic24.webp'
import Image25 from '../../images/Guide/NewbieGuide/pic25.webp'
import Image26 from '../../images/Guide/NewbieGuide/pic26.webp'
import Image27 from '../../images/Guide/NewbieGuide/pic27.webp'
import Image28 from '../../images/Guide/NewbieGuide/pic28.webp'
import Image29 from '../../images/Guide/NewbieGuide/pic29.webp'
import Image30 from '../../images/Guide/NewbieGuide/pic30.webp'
import Image31 from '../../images/Guide/NewbieGuide/pic31.webp'
import Image32 from '../../images/Guide/NewbieGuide/pic32.webp'
import Image33 from '../../images/Guide/NewbieGuide/pic33.webp'
import Image34 from '../../images/Guide/NewbieGuide/pic34.webp'
import Image35 from '../../images/Guide/NewbieGuide/pic35.webp'
import Image36 from '../../images/Guide/NewbieGuide/pic36.webp'
import Image37 from '../../images/Guide/NewbieGuide/pic37.webp'
import Image38 from '../../images/Guide/NewbieGuide/pic38.webp'
import Image39 from '../../images/Guide/NewbieGuide/pic39.webp'
import Image40 from '../../images/Guide/NewbieGuide/pic40.webp'
import Image41 from '../../images/Guide/NewbieGuide/pic41.webp'
import Image50 from '../../images/Guide/NewbieGuide/Arwen1.webp'
import Image51 from '../../images/Guide/NewbieGuide/Arwen2.webp'
import Image52 from '../../images/Guide/NewbieGuide/Arwen3.webp'
import Image99 from '../../images/Guide/NewbieGuide/pic99.webp'

export function NewbieGuide() {
    return (
        <div className="guide white">
            <h3>NewbieGuide</h3>
        
            <p className="head">Quest NPCs</p>
            <ol>
                <li>NewbieGuide <i>(Twin City)</i></li>
                <li>ParagonConquer <i>(Twin City)</i></li>
                <li>ExchangerBatari <i>(Twin City)</i></li>
                <li>ArenaGuard <i>(Twin City)</i></li>
                <li>BotjailGuardian <i>(Market)</i></li>
                <li>Arwen <i>(Twin City)</i></li>
                <li>Assistant <i>(Lab 1-4)</i></li>
                <li>NewbieGuide <i>(Lab 1-4)</i></li>
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>NewbieQuestStage1</li>
            </ol>

            <p className="head">Reward</p>
            <ol>
                <li>462 Attribute Points.</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to NewbieQuest (Twin City), Select a language and start Stage 1.</p>
                    <Image src={Image1} fluid />
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be given NewbieQuestStage1, A clickable item that will display the list of NPCS you have to find.</p>
                    <Image src={Image3} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to ParagonConquer and click the option "NewbieQuest" and then option "I Understand" to complete the NPC.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                    <Image src={Image7} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to ExchangerBatari and click the option "NewbieQuest" and then option "I Understand" to complete the NPC.</p>
                    <Image src={Image11} fluid />
                    <Image src={Image12} fluid />
                    <Image src={Image13} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to BotJailGuardian and click the option "NewbieQuest" and then option "I Understand" to complete the NPC.</p>
                    <Image src={Image17} fluid />
                    <Image src={Image18} fluid />
                    <Image src={Image19} fluid />                    
                </li>
                <li>
                    <p className="instruction">Talk to ArenaGuard and click the option "NewbieQuest" and then option "I Understand" to complete the NPC.</p>
                    <Image src={Image23} fluid />
                    <Image src={Image24} fluid />
                    <Image src={Image25} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Arwen and click the option "NewbieQuest" and then option "I Understand" to complete the NPC.</p>
                    <Image src={Image50} fluid />
                    <Image src={Image51} fluid />
                    <Image src={Image52} fluid />
                </li>
                <li>
                    <p className="instruction">By now, You'll be done learning the basics of the game and getting familiar with mechanics. Head back to NewbieQuest to click "Stage 2".</p>
                    <Image src={Image26} fluid />
                    <Image src={Image27} fluid />
                    <p className="tip">*In case you missed one of the NPCS, You'll be reminded of it.</p>
                    <Image src={Image99} fluid />
                </li>
                <li>
                    <p className="instruction">If you successfully completed all the NPCS, You'll be sent to Labyrinth 1, Talk to Assistant and select one of the available languages.</p>
                    <Image src={Image28} fluid />
                    <Image src={Image29} fluid />
                </li>
                <li>
                    <p className="instruction">Once you've killed 500 souls, You'll get a notication to take you to the exit.</p>
                    <Image src={Image30} fluid />
                    <Image src={Image31} fluid />
                    <Image src={Image32} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent to the Labyrinth 2, Where your target is 700 monsters.</p>
                    <Image src={Image33} fluid />
                    <Image src={Image34} fluid />
                </li>
                <li>
                    <p className="instruction">Once in Stage 3, Your new target is 900 monsters.</p>
                    <Image src={Image35} fluid />
                    <Image src={Image36} fluid />
                    <Image src={Image37} fluid />
                </li>
                <li>
                    <p className="instruction">You're almost done, You find yourself in the final Stage 4 where your last target is 1100 monsters.</p>
                    <Image src={Image38} fluid />
                    <Image src={Image39} fluid />
                </li>
                <li>
                    <p className="instruction">Click "Finish the quest" to finish it and obtain 462 free attribute points. Welcome to Paragon Conquer!</p>
                    <Image src={Image40} fluid />
                    <Image src={Image41} fluid />
                </li>
            </ol>
        </div>
    );
}