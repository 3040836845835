import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { useAccount } from "../Context/AccountProvider";
import { getToken } from "../services/TokenService";
import { deleteUpdateNote, getUpdateNotes } from "../services/UpdateNotesService";
import AuthenticationError from "../components/AuthenticationError";
import UpdateNoteForm from "../components/UpdateNotes/UpdateNoteForm";

export function StaffAnnouncementManagement() {
  const { accountInfo } = useAccount();

  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [announcementBeingModified, setAnnouncementBeingModified] = useState(null);

  useEffect(() => {
    (async() => {
      setAnnouncements(await getUpdateNotes());
    })();
  }, []);

  useEffect(() => {
    if (announcements.length === 0) {
      return;
    }

    function handleUpdateNoteModify(e) {
      var newAnnouncements = [...announcements];
      var announcementToEdit = newAnnouncements.find(x => x.ID === e.detail.id);

      if (announcementToEdit) {
        announcementToEdit.Title = e.detail.title;
        announcementToEdit.Date = e.detail.date;
        announcementToEdit.Content = e.detail.content;
      } else {
        newAnnouncements.push({
          ID: e.detail.id,
          Title: e.detail.title,
          Date: e.detail.date,
          Content: e.detail.content
        })
      }

      setAnnouncements(newAnnouncements);

      setShowEditModal(false);
    }

    document.addEventListener("pg.updateNote.modify", handleUpdateNoteModify);

    return () => {
      document.removeEventListener("pg.updateNote.modify", handleUpdateNoteModify);
    }
  }, [announcements]);

  let history = useHistory(); 
  const redirectHome = () => { history.push("/") }

  function handleClose() {
    setAnnouncementBeingModified(null);
    setShowEditModal(false);
  }

  function editAnnouncement(announcement) {
    setAnnouncementBeingModified(announcement);
    setShowEditModal(true);
  }

  async function delAnnouncement(announcement) {
    if (announcement.ID === announcementToDelete?.ID) {
      await deleteUpdateNote(announcement.ID);
      setAnnouncements(announcements.filter(x => x.ID !== announcement.ID));
    } else {
      setAnnouncementToDelete(announcement);
    }
  }

  if (accountInfo.loggedIn) {
    if (!accountInfo.user.ServerStaff) {
      redirectHome();
    }

    return (
      <div>
      <div className="staff-announcements-table">
        <div className="sat-header">
          <Row>
            <Col>Title</Col>
            <Col>Date</Col>
            <Col></Col>
          </Row>
        </div>
        <div className="sat-items">
          {parseAnnouncements()}
        </div>
        <div className="sat-footer">
          <Button variant="success" onClick={() => editAnnouncement()}>Create New Announcement</Button>
        </div>
      </div>

      <Modal className="updateNoteEditModal" show={showEditModal} onHide={handleClose} centered>
        <Modal.Body>
          <UpdateNoteForm announcement={announcementBeingModified}/>
        </Modal.Body>
      </Modal>
      </div>
    );
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }

  function parseAnnouncements() {
    const elements = [];
  
    for (let announcement of announcements.sort((a,b) => {
      return new Date(a.Date) - new Date(b.Date)
  })) {
      elements.push(
        <Row>
          <Col>{announcement.Title}</Col>
          <Col>{new Date(announcement.Date).toLocaleDateString()}</Col>
          <Col className="table-actions">
            <LinkContainer exact to={`/updates/${announcement.ID}/${announcement.Title.replaceAll(' ', '-')}`}>
              <Button variant="success">View</Button>
            </LinkContainer>
            <Button variant="warning" onClick={() => editAnnouncement(announcement)}>Edit</Button>
            <Button variant="danger" onClick={() => delAnnouncement(announcement)}>{announcementToDelete?.ID === announcement.ID ? "Confirm" : "Delete"}</Button>
          </Col>
        </Row>
      )
    }
  
    return elements
  }
}