import { useState } from 'react';
import {Form, Button, FloatingLabel} from 'react-bootstrap';
import { changePassword } from '../services/PasswordResetService';

function ChangePasswordForm(props) {
    const validationRegex = /^[a-zA-Z0-9!@#$%^&*()]{4,14}$/;
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [outputText, setOutputText] = useState("");
    const [success, setSuccess] = useState(false);

    async function attemptChange(event) {
        event.preventDefault();
        event.stopPropagation();

        const oldPasswordElement = document.getElementById("opassword");
        const newPasswordElement = document.getElementById("rpassword");
        const confirmPasswordElement = document.getElementById("crpassword");

        let valid = newPasswordElement.classList.contains("is-valid") && confirmPasswordElement.classList.contains("is-valid");

        if (valid) {
            let passwordChanged = await changePassword(oldPasswordElement.value, newPasswordElement.value);

            if (passwordChanged === 'true') {
                document.getElementById("changePasswordForm").reset();
                setOutputText("Your password has been changed successfully.");
                setSuccess(true);
            } else if ( passwordChanged === 'password') {
                setOutputText("Incorrect password.");
                setSuccess(false)
            } else {
                setOutputText("There was an error changing your password. Please try again.");
                setSuccess(false)
            }
        } else {
            setOutputText("Please enter valid information.");
        }
    }

    const handlePassword = (event) => {
        const element = event.currentTarget;

        var password = element.value;

        handleConfirmPassword();

        if (password === "") {
            element.classList.remove("is-valid");
            element.classList.remove("is-invalid");
            return;
        }

        if (password.length >= 6 && password.length <= 14) {
            if (password.match(validationRegex) != null) {
                element.classList.add("is-valid");
                element.classList.remove("is-invalid");
            } else {
                element.classList.remove("is-valid");
                element.classList.add("is-invalid");
                setPasswordError("Invalid characters in password.");
            }
        } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
            setPasswordError("Your password must be between 6 and 14 characters!");
        }
    }

    const handleConfirmPassword = (event) => {
        const confirmPasswordElement = document.getElementById("crpassword");
        const passwordElement = document.getElementById("rpassword");

        if (confirmPasswordElement.value === "") {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
            return;
        }

        if (confirmPasswordElement.value === passwordElement.value) {
            confirmPasswordElement.classList.add("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
        } else {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.add("is-invalid");
            setConfirmPasswordError("Your passwords do not match.");
        }
    }

    return (
        <div className="reset-form-page">
            <h3 className="white">Change Password</h3>
            <h5 className="white">Please enter a new password for your account.</h5>
            <Form className="reset-form" id="changePasswordForm">
                <Form.Group className="mb-3" controlId="opassword">
                    <FloatingLabel controlId="opassword" label="Old Password" className="mb-3">
                        <Form.Control type="password" placeholder="Old Password" />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" controlId="rpassword">
                    <FloatingLabel controlId="rpassword" label="New Password" className="mb-3">
                        <Form.Control type="password" placeholder="New Password" onChange={handlePassword} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {passwordError}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" controlId="crpassword">
                    <FloatingLabel controlId="crpassword" label="Confirm Password" className="mb-3">
                        <Form.Control type="password" placeholder="Confirm Password" onChange={handleConfirmPassword} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {confirmPasswordError}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <p id="registration-output-text" className={(success) ? "green-reg-message" : "red-reg-message"}>{outputText}</p>
                <Button type="submit" variant="primary" onClick={attemptChange}>
                    Change Password
                </Button>
            </Form>
        </div>
    )
}
            
export { ChangePasswordForm };