import { useEffect, useState } from 'react';

export function Countdown(props) {
    const [time, setTime] = useState("");
    
    useEffect(() => {
        const interval = setInterval(function() {

          var now = new Date().getTime();
            
          var distance = props.date - now;
            
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
          setTime(days + "d " + hours + "h "
          + minutes + "m " + seconds + "s ");
            
          // If the count down is over, write some text 
          if (distance < 0) {
            clearInterval(interval);
            setTime("Today");
          }
        }, 1000);
     
        return () => {
          clearInterval(interval);
        }
      }, []);

    return (
        <p>{time}</p>
    )
}