import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { getAllDonationStoreItems, getDonationStoreItem } from "../services/DonationStoreService";
import search from '../images/search.svg';
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

export function StoreItemDetails() {
  const [item, setItem] = useState(false);
  const [loaded, setLoaded] = useState(false);
  
  const { id } = useParams();

  useEffect(() => {
    let sidebar = document.getElementById('sidebarColumn');
    let main = document.getElementById('contentColumn');
    let mainContent = document.getElementById('main-content');

    sidebar.classList.add('hide');
    main.classList.remove('col-lg-8');
    main.classList.add('col-lg-10');
    mainContent.style = "width: 100%;";

    return () => {
      sidebar.classList.remove('hide');
      main.classList.add('col-lg-8');
      main.classList.remove('col-lg-10');
      mainContent.style = "";
    }
  }, []);

  if (!loaded) {
    setLoaded(true);
    (async() => {
      var donationData = await getDonationStoreItem(id);
      setItem(donationData.result);
    })();
  }

  if (loaded && item) {
    return (
<div>
      <Row>
        <Col>
          <h1 className="white" style={{display: 'inline-block'}}>Paragon Store</h1>
          <LinkContainer exact to='/donationstorepreview'>
            <span className="return-to-store">Return to Store</span>
          </LinkContainer>
        </Col>
      </Row>

      <hr className="white-hr"/>
      
      <Row>
        <Col>
          {getMedia(item.media)}
        </Col>
        <Col>
          <div className="item-info-display">
            <div>
              <h4>{item.name} <span style={{color: 'gold', float: 'right'}}>{item.price} Coins</span></h4>
            </div>
            
            <hr/>
            
            <div>
              <p><b>Description: </b>{item.description}</p>
              <p><b>Type: </b>{item.type}</p>
            </div>
          </div>
        </Col>
      </Row>
      </div>
    )
  } else {
    return (
      <div className="loading-container">
        <p>Paragon Store</p>
        <div class="loading-spinner"></div>
      </div>
    )
  }
}

function getMedia(media) {
  let markup = [];

  if (media.length == 0) {
    markup.push(
      <img style={{width: '100%', height: '300px'}} src={require('../images/no-preview.webp')} alt="No preview available for this item."/>
    )
  }

  for (let item of media) {
    markup.push(
        <video style={{width: "100%", height: "300px"}} controls={false} autoPlay={true} muted={true} loop={true}>
          <source src={require(`../videos/${item.location}`)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    )
  }

  return markup;
}