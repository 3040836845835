import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { getUpdateNote, getUpdateNotes } from "../services/UpdateNotesService";

export function Updates() {
  const { id } = useParams();
  const [updateNote, setUpdateNote] = useState(false);
  const [updateNoteList, setUpdateNoteList] = useState(false)

  useEffect(() => {
    if (!id) return;

    (async () => {
      setUpdateNote((await getUpdateNote(id))[0]);
    })();
  });

  useEffect(() => {
    if (id) return;

    (async () => {
      setUpdateNoteList(await getUpdateNotes());
    })();
  });

  if (id) {
    if (updateNote) {
      return (
        <div className="update-note">
          <div className="note-header">
            <h2>{updateNote.Title}</h2>
            <p className="update-note-date">{(() => {
              let date = new Date(updateNote.Date).toUTCString().split(' ');
              date.shift()
              date.pop()
              date.pop()

              date[1] += ','

              return date.join(' ');
            })()}</p>
          </div>
          <div className="note-body">
            <div className="update-note-content" dangerouslySetInnerHTML={{ __html: updateNote.Content }}>
            </div>
            <div className="update-note-footer">
              <hr/>
              <LinkContainer className="notes-return-link" exact to={`/updates`}><span>View More Updates</span></LinkContainer>
              <hr/>
            </div>
          </div>
        </div>
      )
    } else {
      return (<div></div>)
    }
  } else {
    if (!updateNoteList) {
      return (
        <div></div>
      )
    } else {
      return (
        <div>
          <div className="update-notes-header">Paragon Conquer Updates</div>
          <div className="update-note-full-list">
            {updateNoteMarkup()}
          </div>
        </div>
      );
    }
  }

  function updateNoteMarkup() {
    let markup = [];

    for (let note of updateNoteList.sort((a, b) => {
      return new Date(b.Date) - new Date(a.Date)
  })) {
      markup.push(
        <LinkContainer className="updateNoteRow" exact to={`/updates/${note.ID}/${note.Title.replaceAll(' ', '-')}`}>
          <Row>
            <Col xs={2}>
            <span>{new Date(note.Date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</span>
            </Col>
            <Col xs={10}>{note.Title}</Col>
          </Row>
        </LinkContainer>
      )
    }

    return markup;
  }
}