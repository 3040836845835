import { TicketBody } from "./Ticket/TicketBody";
import { TicketHeader } from "./Ticket/TicketHeader";

export function Ticket(props) {
    return(
        <div className="ticket">
            <TicketHeader data={props.data.header}/>
            <TicketBody data={props.data.messages} status={props.data.header.status} id={props.data.header.id}/>
        </div>
    );
}