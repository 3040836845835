import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/EpicMonkQuest/pic1.jpg'
import Image2 from '../../images/Guide/EpicMonkQuest/pic2.jpg'
import Image3 from '../../images/Guide/EpicMonkQuest/pic3.jpg'
import Image4 from '../../images/Guide/EpicMonkQuest/pic4.jpg'
import Image5 from '../../images/Guide/EpicMonkQuest/pic5.jpg'
import Image6 from '../../images/Guide/EpicMonkQuest/pic6.jpg'
import Image7 from '../../images/Guide/EpicMonkQuest/pic7.jpg'
import Image8 from '../../images/Guide/EpicMonkQuest/pic8.jpg'
import Image9 from '../../images/Guide/EpicMonkQuest/pic9.jpg'
import Image10 from '../../images/Guide/EpicMonkQuest/pic10.jpg'
import Image11 from '../../images/Guide/EpicMonkQuest/pic11.jpg'

export function EpicMonkQuest() {
    return (
        <div className="guide white">
            <h3>Epic Monk Quest</h3>
            
            <p className="head">Requirements</p>
            <ul>
                <li>Level 140</li>
                <li>2nd Reborn Character</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>HeadAbbot <i>(Job Center)</i></li>
                <li>GoldenCicada <i>(Western Paradise)</i></li>
                <li>VictoryBuddha <i>(Western Paradise)</i></li>
                <li>WhiteDragon <i>(Western Paradise)</i></li>
                <li>FortuneArhat <i>(Eastern Paradise)</i></li>
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>DragonBalls</li>
                <li>Buddha'sNecklace</li>
                <li>BoundlessHeart <i>(2)</i></li>
                <li>HolyBottle</li>
                <li>UtopiaToken <i>(40)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to HeadAbbot (Job Center) to enter the Western Paradise.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to GoldenCicada to learn about the quest and its stages.</p>
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to VictoryBuddha and attempt to remove the distractions using 1 DragonBall</p>
                    <Image src={Image3} fluid />
                    <p className="tip">*VictoryBuddha might kick you out the Western Paradise until you can succesfully remove the distractions.</p>
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">When you remove the distractions you will be awarded Buddha'sNecklace, take it to GoldenCicada to get into the Eastern Paradise.</p>
                    <Image src={Image5} fluid />
                </li>
                <li>
                    <p className="instruction">Look for FortuneArhat, and use Buddha'sNeacklace to release the CrazyDragon.</p>
                    <Image src={Image6} fluid />
                </li>
                <li>
                    <p className="instruction">Defeat CrazyDragon to get HolyBottle and (2) BoundlessHeartuse them to transform Monk's PrayerBeads into legendary EpicWeapons.</p>
                    <Image src={Image7} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to VictoryBuddha and ask about UtopiaTokens and then go to the WineMap and get 40 UtopiaTokens to trade them for the Epic Skills through WhiteDragon.</p>
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <p className="tip">*VIP 6 Players will get UtopiaTokens autolooted to inventory and NON VIP have to pick them up.</p>
                </li>
                <li>
                    <p className="instruction">Once you've collected 40 UtopiaTokens, you will be teleported back to the Western Paradise, talk to WhiteDragon to swap them for Epic Monk Skills.</p>
                    <Image src={Image10} fluid />
                    <Image src={Image11} fluid />
                </li>
            </ol>
        </div>
    );
}