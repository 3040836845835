import { RefundPolicyText } from '../components/RefundPolicyText';

export function RefundPolicy() {
  return (
    <div className="info-page">
      <div className="info-header">
        <span className="h-xlarge">R</span><span className="h-large">efund</span> <span className="h-xlarge">P</span><span className="h-large">olicy</span>
      </div>
      <div className="info-text">
        <RefundPolicyText />
      </div>
  </div>
  );
}