import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useAccount } from "../../../../Context/AccountProvider";
import { getAllSettings, updateSetting } from "../../../../services/SettingsService";

export function SiteSettings() {
    const [settings, setSettings] = useState();
    const {toast} = useAccount();

    useEffect(() => {
        (async() => {
            let data = await getAllSettings();
            setSettings(data.result);
        })();
    }, [])
  
    if (!settings) {
      return (
        <div className="white center">
            Loading settings...
        </div>
      );
    }

    return (
      <form>
          {renderSettings(settings)}
          
        <div className="d-flex justify-content-end">
            <Button variant="primary" type="button" onClick={() => {saveSettings(toast)}}>Save Website Settings</Button>
        </div>
      </form>
    );
}

async function saveSettings(toast) {
  let settingsToSave = document.getElementsByClassName("paragon-web-setting");

  let settingCount = settingsToSave.length;
  let successCount = 0;

  for (let settingElement of settingsToSave) {
    let settingSaved = await updateSetting(settingElement.name, settingElement.value);

    if (settingSaved === "ok") {
      successCount++;
    }
    
  }

  if (settingCount === successCount) {
    toast('success', 'Settings Saved', 'The website settings have been successfully saved.');
  } else {
    toast('success', 'Error Saving', 'The website settings could not be saved - there was an issue with the settings you have input.');
  }
}

function renderSettings(data) {
  let responseArray = [];
  
  for (let setting of data) {
    responseArray.push(
      <Form.Group className="mb-3">
          <Form.Label>{setting["key"]}</Form.Label>
          <Form.Control className="paragon-web-setting" type="text" name={setting["key"]} defaultValue={setting["value"]}/>
      </Form.Group>
    )
  }
  

  return responseArray;
}