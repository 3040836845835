import { useState } from 'react';
import {Form, Button, FloatingLabel} from 'react-bootstrap';
import { resetPassword } from '../services/PasswordResetService';

function PasswordResetForm(props) {
    const validationRegex = /^[a-zA-Z0-9!@#$%^&*()]{4,20}$/;
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [outputText, setOutputText] = useState("");

    async function attemptReset(event) {
        event.preventDefault();
        event.stopPropagation();

        const passwordElement = document.getElementById("rpassword");
        const confirmPasswordElement = document.getElementById("crpassword");

        let valid = passwordElement.classList.contains("is-valid") && confirmPasswordElement.classList.contains("is-valid");

        if (valid) {
            let passwordChanged = await resetPassword(props.token, passwordElement.value);

            if (passwordChanged) {
                props.callback(true);
            } else {
                setOutputText("There was an error changing your password. Please try again.");
            }
        } else {
            setOutputText("Please enter valid information.");
        }
    }

    const handlePassword = (event) => {
        const element = event.currentTarget;

        var password = element.value;

        handleConfirmPassword();

        if (password === "") {
            element.classList.remove("is-valid");
            element.classList.remove("is-invalid");
            return;
        }

        if (password.length >= 6 && password.length <= 20) {
            if (password.match(validationRegex) != null) {
                element.classList.add("is-valid");
                element.classList.remove("is-invalid");
            } else {
                element.classList.remove("is-valid");
                element.classList.add("is-invalid");
                setPasswordError("Invalid characters in password.");
            }
        } else {
            element.classList.remove("is-valid");
            element.classList.add("is-invalid");
            setPasswordError("Your password must be between 6 and 20 characters!");
        }
    }

    const handleConfirmPassword = (event) => {
        const confirmPasswordElement = document.getElementById("crpassword");
        const passwordElement = document.getElementById("rpassword");

        if (confirmPasswordElement.value === "") {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
            return;
        }

        if (confirmPasswordElement.value === passwordElement.value) {
            confirmPasswordElement.classList.add("is-valid");
            confirmPasswordElement.classList.remove("is-invalid");
        } else {
            confirmPasswordElement.classList.remove("is-valid");
            confirmPasswordElement.classList.add("is-invalid");
            setConfirmPasswordError("Your passwords do not match.");
        }
    }

    return (
        <div className="reset-form-page">
            <h3 className="white">Hey there, {props.username}!</h3>
            <h5 className="white">Please enter a new password for your account.</h5>
            <Form className="reset-form">
                <Form.Group className="mb-3" controlId="rpassword">
                    <FloatingLabel controlId="rpassword" label="New Password" className="mb-3">
                    <Form.Control type="password" placeholder="New Password" onChange={handlePassword} />
                    <Form.Control.Feedback type="invalid" tooltip>
                        {passwordError}
                    </Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3" controlId="crpassword">
                    <FloatingLabel controlId="crpassword" label="Confirm Password" className="mb-3">
                    <Form.Control type="password" placeholder="Confirm Password" onChange={handleConfirmPassword} />
                    <Form.Control.Feedback type="invalid" tooltip>
                        {confirmPasswordError}
                    </Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
                <p id="registration-output-text" className="red-reg-message">{outputText}</p>
                <Button type="submit" variant="primary" onClick={attemptReset}>
                    Change Password
                </Button>
            </Form>
        </div>
    )
}
            
export {PasswordResetForm};