import './SectionHeader.css';

export function SectionHeader({children, underlined}) {
    return (
        <div className="section-header">
            {children}
            <SectionHr underlined={underlined}/>
        </div>
    )
}

function SectionHr({underlined}) {
    if (underlined) return <hr className='section-header-hr'/>
    return <></>
}