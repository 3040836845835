import { useState } from 'react';
import {Form, Button, FloatingLabel} from 'react-bootstrap';
import { sendResetRequest } from '../services/PasswordResetService';

function ForgotPasswordForm(props) {
    var [errorMessage, setErrorMessage] = useState("");
    var [successMessage, setSuccessMessage] = useState("");
    var [loading, setLoading] = useState(false);

    const formId = Math.random().toString().substr(2, 8);

    async function recoverPassword(event) {
        setErrorMessage("");
        event.preventDefault();
        event.stopPropagation();

        let username = document.getElementById(formId+'username').value;

        setLoading(true);
        let result = await sendResetRequest(username);

        if (result === "time") {
            setLoading(false);
            setErrorMessage("Please wait at least 5 minutes between reset requests.");
        } else {
            setLoading(false);
            setSuccessMessage("Please check your email.");
        }
    }

    return (
        <div>
            <div className="form-header">
                <p>Please enter your username.</p>
            </div>
            <Form className="modal-login-form">
                <Form.Group className="mb-3" controlId={formId+"username"}>
                    <FloatingLabel controlId={formId+"username"} label="Username" className="mb-3">
                    <Form.Control type="text" placeholder="Username" />
                    </FloatingLabel>
                </Form.Group>
                {
                    (loading) ? <div className="login-form-loading"><span>Please wait...</span></div> : ""
                }
                
                <div className="login-form-error">{errorMessage}</div>
                <div className="login-form-success">{successMessage}</div>
                <Button type="submit" variant="primary" onClick={recoverPassword}>
                    Recover Password
                </Button>
            </Form>
        </div>
    )
}

export {ForgotPasswordForm};