import { Modal } from 'react-bootstrap'
import { ForgotPasswordForm } from './ForgotPasswordForm'

export function ForgotPasswordModal(props) {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
        onShow={()=>{document.getElementsByTagName("body")[0].style = "";}}
      >        
        <Modal.Header>
          <div>
            <span class="h-large">F</span><span class="h-small">orgot</span>&nbsp;&nbsp;<span class="h-large">P</span><span class="h-small">assword</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ForgotPasswordForm />
        </Modal.Body>
      </Modal>
    );
  }
  