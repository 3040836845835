import Axios from 'axios';
import { SHA3 } from 'sha3';
import { setToken, clearToken } from './TokenService'

function authenticate(username, password) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/authenticate', {
            u: username,
            p: new SHA3(256).update(password).digest('hex')
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.jwt);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

function validateToken(token) {
    return new Promise(resolve => {
        Axios.post('https://www.paragonconquer.com/api/validateToken', {
            jwt: token
          })
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data.result);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function handleLogout() {
    clearToken();
}


async function handleToken(token) {
    let tokenValid = await validateToken(token);

    if (tokenValid === true) {
        setToken(token);
        return true;
    } else {
        clearToken();
        return false;
    }
}

export { authenticate, validateToken, handleToken }