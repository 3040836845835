export function PrivacyPolicy() {  
  return (
      <div className="info-page">
        <div className="info-header">
          <span className="h-xlarge">P</span><span className="h-large">rivacy</span> <span className="h-xlarge">P</span><span className="h-large">olicy</span>
        </div>
        <div className="info-text">
          <h5>Paragon Conquer collects a variety of information that you provide directly to us.</h5>
          <h6>We process your information when necessary to provide you with the services included when playing the server.</h6>
          <p>- Account and identifiable information provided to us when creating an account are kept strictly confidential.</p>
          <p>- Personal information provided to us is only used to verify identity, assist with identification and to determine appropriate services.</p>
          <p>&nbsp;&nbsp;&gt;&nbsp;For example, we may use your email in order to assist you in retrieving your account information if it has been lost.</p>
          <p>- Our website may utilise cookies or other features in order to auto-save your information for faster login.</p>
          <p>If you have any enquiries regarding our privacy policy, please contact us through our website, or discord.</p>
        </div>
      </div>
  );
}