import './ContentBlock.css'

export function ContentBlock({className, header, children}) {
    return (
        <div className={`${className} contentblock`}>
            <ContentBlockHeader header={header}/>
            <div className="contentblock-body">
                {children}
            </div>
        </div>
    )
}

function ContentBlockHeader({header}) {
    if (!header) return;

    var headerWords = header.split(' ');
    var headerComponents = [];

    for (var word of headerWords) {
        headerComponents.push(
            <span>
                <span className="contentblockheader-large">{word[0]}</span>
                <span className="contentblockheader-small">{word.slice(1)}</span>
            </span>
        );
    }

    return (
        <div className="contentblockheader">
            {headerComponents}
        </div>
    )
}