export function AttributePoints() {
    return (
        <div className="guide white">
            <h3>Attribute Points</h3>
            <ol>
                <li>Attribute points are the points in your status menu designated as Strength, Agility, Vitality, or Spirit. Each attribute does something different for your character as explained below.
                </li>
                <p className="head">Attributes Explained:</p>
                <ol>
                    <li>Strength: Increases your attack power</li>
                    <li>Agility: 36 Agility is required for equipping any other weapon not requiring strength except for Taoist's backsword or Hossu.</li>
                    <li>Vitality: Gives characters more HP.</li>
                    <li>Spirit: Increases your magic power, and increases health.</li>
                    <p className="tip">*Note that there is a cap of 1000 attribute points on each stat, making it a total of 4000 that will NEVER be increased.</p>
                    <p className="tip">*Agility only increases your characters attack speed and NOT your skills speed, the cap of agility is 130, anything higher than that will NOT increase attack speed.</p>
                </ol>
                <p className="head">How to Obtain Attribute Points?</p>
                <ol>
                    <li>
                        <p className="instruction">Every account starts with 538 Attribute Points(Level 140, 2nd Reborn) However, after completing NewbieQuest which awards you with 462 FREE and PERMANENT attribute points
                         your real start is 1000 Attribute Points.</p>
                         <li>Treasure Forest: 1 AmethystGem = 25 Attribute Points, etc.</li>
                         <li>Treasure Forest: 5 RubyGems = 10 Attribute Points, etc.</li>
                         <li>Treasure Forest: 3 SapphireGems/ 5 SapphireGems = MysteryFruits, etc.</li>
                         <li>Treasure Forest: MysteryChests = Chances for MysteryFruits .</li>
                         <li>Raid Boss(Daily): Hard Mode = 50 Attribute Points.</li>
                         <li>Raid Boss(Daily): Easy Mode = 30 Attribute Points.</li>
                         <li>Jar Quest(Daily) = 30 Attribute Points.</li>
                         <li>Steed Race: 10 Points = 20 Attribute Points.</li>
                         <li>Spirit Bead(Daily): MysteryFruits + MysteryDew as rewards.</li>
                    </li>
                </ol>
            </ol>
        </div>
    );
}