import { useState } from 'react';
import {Form, Button, FloatingLabel} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { authenticate, handleToken } from '../services/AuthService'
import { useAccount } from '../Context/AccountProvider'
import { getUserData } from '../services/UserService'
import { ForgotPasswordModal } from './ForgotPasswordModal';

function LoginForm(props) {
    var [errorMessage, setErrorMessage] = useState("");
    var [loading, setLoading] = useState(false);
    var [showForgotPassword, setShowForgotPassword] = useState(false);

    const formId = Math.random().toString().substr(2, 8);
    var {updateAccountInfo} = useAccount();

    async function attemptLogin(event) {
        setErrorMessage("");
        event.preventDefault();
        event.stopPropagation();

        let username = document.getElementById(formId+'username').value;
        let password = document.getElementById(formId+'password').value;

        setLoading(true);
        let result = await authenticate(username, password);

        if (result === undefined) {
            setLoading(false);
            setErrorMessage("Invalid credentials!");
        } else {
            setLoading(false);
            let tokenSet = await handleToken(result);

            if (tokenSet) {
                let userData = await getUserData()
                if (!userData) {
                    updateAccountInfo({loggedIn: true, user: undefined});
                } else {
                    updateAccountInfo({loggedIn: true, user: userData});
                }
            } else {
                setErrorMessage("Invalid login session!");
            }
        }
    }

    return (
        <div>
            {
                (props.modal) 
                ? 
                    <div className="form-header">
                        <p>Please enter your credentials.</p>
                    </div>
                : <></>
            }
            <Form className={(props.modal) ? "modal-login-form" : ""}>
                <Form.Group className="mb-3" controlId={formId+"username"}>
                    <FloatingLabel controlId={formId+"username"} label="Username" className="mb-3">
                    <Form.Control type="text" placeholder="Username" />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className={(props.modal) ? "mb-3 margin-custom" : "mb-3"} controlId={formId+"password"}>
                    <FloatingLabel controlId={formId+"password"} label="Password" className="mb-3">
                    <Form.Control type="password" placeholder="Password" />
                    </FloatingLabel>
                </Form.Group>
                {
                    (props.modal)
                    ?
                        <div className="forgot">
                            <span className="forgot-password-link" onClick={() => {if (props.typeCallback) {props.typeCallback("forgotPassword")}}}>Forgot Password</span>
                        </div>
                    : <></>
                }

                {
                    (loading) ? <div className="login-form-loading"><span>Attempting login</span></div> : ""
                }
                
                <div className="login-form-error">{errorMessage}</div>
                <Button type="submit" variant="primary" onClick={attemptLogin}>
                    Login
                </Button>&nbsp;
                {
                    (props.register) ? <LinkContainer activeClassName="" to="/register" onClick={()=>{document.getElementById('main-content').scrollIntoView(true);}}><Button variant="primary">Register</Button></LinkContainer> : ""
                }

                {
                    (!props.modal) 
                    ?
                        <span className="forgot-password-link" onClick={()=>{setShowForgotPassword(true)}}>Forgot Password</span>
                    : <></>
                }
            </Form>
            <ForgotPasswordModal closeModal={setShowForgotPassword} show={showForgotPassword} onHide={() => {setShowForgotPassword(false);}}/>
        </div>
    )
}
            
export {LoginForm};