import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass7/pic1.webp'
import Image2 from '../../images/Guide/BattlePass7/pic2.webp'
import Image3 from '../../images/Guide/BattlePass7/pic3.webp'
import Image4 from '../../images/Guide/BattlePass7/pic4.webp'
import Image5 from '../../images/Guide/BattlePass7/pic5.webp'
import Image6 from '../../images/Guide/BattlePass7/pic6.webp'
import Image7 from '../../images/Guide/BattlePass7/pic7.webp'
import Image8 from '../../images/Guide/BattlePass7/pic8.webp'
import Image9 from '../../images/Guide/BattlePass7/pic9.webp'
import Image10 from '../../images/Guide/BattlePass7/pic10.webp'
import Image11 from '../../images/Guide/BattlePass7/pic11.webp'
import Image12 from '../../images/Guide/BattlePass7/pic12.webp'
import Image13 from '../../images/Guide/BattlePass7/pic13.webp'
import Image14 from '../../images/Guide/BattlePass7/pic14.webp'
import Image15 from '../../images/Guide/BattlePass7/pic15.webp'
import Image16 from '../../images/Guide/BattlePass7/pic16.webp'
import Image17 from '../../images/Guide/BattlePass7/pic17.webp'
import Image18 from '../../images/Guide/BattlePass7/pic18.webp'
import Image19 from '../../images/Guide/BattlePass7/pic19.webp'
import Image20 from '../../images/Guide/BattlePass7/pic20.webp'
import Image21 from '../../images/Guide/BattlePass7/pic21.webp'
import { LinkContainer } from 'react-router-bootstrap';

export function BattlePass7() {
    return (
        <div className="guide white">
            <h3>Battle Pass</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #7.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>EssenceChest <i>(Twin City)</i></li>
                <li>Hula <i>(Twin City)</i></li>
                <li>MadaraV2<i>(Garment)</i></li>
                <li>Patrolling Shadow<i>(Garment)</i></li>
                <li>Blazing Sun<i>(Garment)</i></li>
                <li>PassPack1 <i>(Item)</i></li>
                <li>PassPack2 <i>(Item)</i></li>
                <li>ForgeOrb <i>(Item)</i></li>
                <li>RuneStone +5 <i>(Item)</i></li>
                <li>PetEgg <i>(Item)</i></li>
                <li>CustomLogoGem <i>(Item)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 7 through the Coin Store for 10 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 7 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There are 10 different quests, each one gives different amounts 10 Battle Pass Points and 2M CPs.
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to Shuang.</li>
                    <Image src={Image4} fluid />
                    <li>City Tokens: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect all the 5 City Tokens from each city to swap them for 40 Battle Pass Points.</li>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                    <Image src={Image7} fluid />
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <li>SpecialSpook: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image10} fluid />
                    <li>SpecialTerato: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image11} fluid />
                    <li>SpecialHelhest: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image12} fluid />
                    <li>SpecialApe: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image13} fluid />
                    <li>SpecialOctopus: Your damage will be 1, this boss has 200 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image14} fluid />

                    <li>Complete Tower of Mystery to obtain 20 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Infernal GW(Sign up through Balrog) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <li>Attend the Elite GW(Sign up through GeneralRoo) to obtain 10 Battle Pass Points + 2M CPs.</li>
                </ol>
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>
            <ol>
                <ol>
                    <li>Level 1: 50M CPs, 5,000 Champion Points and (1) NameToken(Tradable)</li>
                    <li>Level 2: 50M CPs and 6,500 Champion Points.</li>
                    <li>Level 3: 50M CPs and 8,000 Champion Points.</li>
                    <li>Level 4: 50M CPs, 10,000 Champion Points and (1) MadaraV2(255 HP Bound Garment)</li>
                    <Image src={Image15} fluid />

                    <li>Level 5: 50M CPs, 12,000 Champion Points and (1) Patrolling Shadow(255 HP Bound Garment)</li>
                    <Image src={Image16} fluid />
                    <Image src={Image17} fluid />

                    <li>Level 6: 50M CPs, 15,000 Champion Points and (1) PassPack1(Untradable)</li>
                    <p className="tip">*PassPack1 is a clickable item where you get to choose whether you want RuneStone +5(Tradable) or ForgeOrb(Bound)</p>
                    <Image src={Image18} fluid />
                    
                    <li>Level 7: 50M CPs, (1) PassPack2(Untradable) and Blazing Sun(255 HP Bound Garment)</li>
                    <p className="tip">*PassPack2 is a clickable item where you get to choose whether you want PetEgg(Bound) or CustomLogoGem(Tradable)</p>
                    <Image src={Image19} fluid />
                    <Image src={Image20} fluid />
                    <Image src={Image21} fluid />
                </ol>
            </ol>
        </div>
    );
}