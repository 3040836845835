import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass1/pic1.webp'
import Image2 from '../../images/Guide/BattlePass1/pic2.webp'
import Image3 from '../../images/Guide/BattlePass1/pic3.webp'
import Image4 from '../../images/Guide/BattlePass1/pic4.webp'
import Image5 from '../../images/Guide/BattlePass1/pic5.webp'
import Image6 from '../../images/Guide/BattlePass1/pic6.webp'
import Image7 from '../../images/Guide/BattlePass1/pic7.webp'
import Image8 from '../../images/Guide/BattlePass1/pic8.webp'
import Image9 from '../../images/Guide/BattlePass1/pic9.webp'
import Image10 from '../../images/Guide/BattlePass1/pic10.webp'
import Image11 from '../../images/Guide/BattlePass1/pic11.webp'

export function BattlePass1() {
    return (
        <div className="guide white">
            <h3>Battle Pass</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #1.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>DivineJade <i>(Twin City)</i></li>
                <li>PrayerTree <i>(Twin City)</i></li>
                <li>GarmentEnchanter <i>(Item)</i></li>
                <li>SteedArmorEnchanter <i>(Item)</i></li>
                <li>Spiderworld <i>(Item)</i></li>
                <li>AmethystStone <i>(Item)</i></li>
                <li>RubyStone <i>(Item)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass through the Coin Store for 10 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image2} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There currently are 5 different quests, each one gives 10 Battle Pass Points and 2M CPs:
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to PrayerTree.</li>
                    <Image src={Image3} fluid />
                    <li>SpecialBanshee: Your damage will be 1, this boss has 300 HP, once defeated you will obtain the 10 Battle Pass Points.</li>
                    <Image src={Image4} fluid />
                    <li>SpecialSpook: Your damage will be 1, this boss has 300 HP, once defeated you will obtain the 10 Battle Pass Points.</li>
                    <Image src={Image5} fluid />
                    <li>SpecialTerato: Your damage will be 1, this boss has 300 HP, once defeated you will obtain the 10 Battle Pass Points</li>
                    <Image src={Image6} fluid />
                    <li>Beast of Legends: You're asked to clean up this Castle, you will find 4 different type of legendary beasts, exterminate them until you are kicked out. When kicked out, go to PrayerTree to deliver the quest.</li>
                    <p className="tip">*Note that if you leave the Castle, your progress on this quest will be reset.</p>
                    <Image src={Image7} fluid />
                </ol>  
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>              
            <ol>          
                <ol>
                    <li>Level 1: 100M CPs, 1,500 Inner Power Points and (1) GarmentEnchanter(Bound)</li>
                    <li>Level 2: 100M CPs, 2,500 Inner Power Points and (1)SteedArmorEnchanter(Bound)</li>
                    <li>Level 3: 100M CPs, 3,500 Inner Power Points and 5,000 Champion Points.</li>
                    <li>Level 4: 100M CPs, 4,500 Inner Power Points and 7,500 Champion Points.</li>
                    <li>Level 5: 100M CPs, (1) Spiderworld -1 255 HP(Bound) Garment.</li>
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <li>Level 6: 100M CPs, 5,000 Inner Power Points and (1) AmethystStone(Untradable)</li>
                    <p className="tip">*AmethystStone is a clickable item where you get to choose whether you want 1000 Attribute Points or 3 BookFragment(Tradable)</p>
                    <Image src={Image10} fluid />
                    <li>Level 7: 100M CPs and (1) RubyStone(Untradable)</li>
                    <p className="tip">*RubyStone is a clickable item where you get to choose whether you want ForgeOrb(Bound) or 2 SuperBook(Bound)</p>
                    <p className="tip">What is ForgeOrb? It's a clickable item to forge one of the donation cups(ShifuCup, ImperialCup or GobletofFire)</p>
                    <Image src={Image11} fluid />
                </ol>  
            </ol>
        </div>
    );
}