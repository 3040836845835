import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';

import { validateResetToken } from '../services/PasswordResetService';
import { PasswordResetForm } from '../components/PasswordResetForm';

function ResetPassword(props) {
    const resetToken = queryString.parse(props.location.search).token;
    const [loadTriggered, setLoadTriggered] = useState(false);
    const [tokenState, setTokenState] = useState("loading");
    const [showChanged, setShowChanged] = useState(false);
    let history = useHistory(); 
    const handleRedirect = () =>{ history.push("/") }

    useEffect(() => {
            document.getElementById('main-content').scrollIntoView(true);
    }, [tokenState])

    async function checkToken() {
        let validToken = await validateResetToken(resetToken);
        
        setTokenState(validToken);
    }

    if (!loadTriggered) {
        setLoadTriggered(true);

        if (resetToken) {
            checkToken();
        } else {
            setTokenState("none");
        }
    }

    if (showChanged) {
        return (
            <div>
                <h3 className="white">Your password has been successfully changed, {tokenState}!</h3>
                <h5 className="white">Please write your password down so you do not lose it in the future.</h5>
            </div>
        )
    }

    if (tokenState === "loading") {
        return (
            <div>
                <h1 className="white">Loading...</h1>
            </div>
        )
    } else if (tokenState === "invalid") {
        return (
            <div className="center page-center">
                <h3 className="white">This password reset link is no longer valid.</h3>
            </div>
        )
    } else if (tokenState === "none") {
        handleRedirect();
        return (
          <></>
        )
    } else {
        return (
            <div>
                <PasswordResetForm username={tokenState} token={resetToken} callback={setShowChanged}/>
            </div>
        )
        
    }
  }
  
export { ResetPassword };