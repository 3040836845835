import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { getLauncherVersion, uploadLauncherFile } from "../../../../services/LauncherSettingsService";
import { requireInteger } from "../../../../utilities/FormUtility";
import { SectionHeader } from "../../../SectionHeader/SectionHeader";

export function LauncherSettings() {
    const [launcherVersion, setLauncherVersion] = useState(null);
    const [launcherFile, setLauncherFile] = useState();

    useEffect(() => {
        (async () => {
            const launcherVersion = await getLauncherVersion();
            setLauncherVersion(launcherVersion);
        })();
    }, []);

    function handleFileChange (event) {
        if (event.target.files) {
            setLauncherFile(event.target.files[0]);
        }
      };

    function saveLauncherSettings(event) {
        event.preventDefault();

        if (!launcherFile) {
            return;
        }

        uploadLauncherFile(launcherFile);
        setLauncherVersion(launcherVersion);
    }

    if (launcherVersion == null) {
        return (
            <div className="white center">
                Loading launcher version...
            </div>
        );
    }

    return (
        <div>
            <SectionHeader underlined>Upload New Launcher {launcherVersion}</SectionHeader>
            <form onSubmit={saveLauncherSettings}>
                <Form.Group controlId="file" className="mb-3">
                    <Form.Label>Change Launcher File</Form.Label>
                    <Form.Control type="file" accept=".exe" onChange={handleFileChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Launcher Version</Form.Label>
                    <Form.Control type="number" min={0} defaultValue={launcherVersion} onChange={(event) => setLauncherVersion(event.target.value)} onKeyPress={requireInteger}/>
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button type="submit" variant="primary">Save Launcher Settings</Button>
                </div>
            </form>
        </div>
    )
}