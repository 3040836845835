let Items = {
    723953: {
        Name: 'DonationPoint(1)',
        DPSCost: 1
    },
    723954: {
        Name: 'DonationPoints(10)',
        DPSCost: 10
    },
    723955: {
        Name: 'DonationPoints(50)',
        DPSCost: 50
    },
    723956: {
        Name: 'DonationPoints(100)',
        DPSCost: 100
    },
    3008448: {
        Name: 'VIP-7',
        DPSCost: 100
    },
    728143: {
        Name: 'ForgeOrb',
        DPSCost: 25
    },
    2100175: {
        Name: 'ImperialCup',
        DPSCost: 25
    },
    2100205: {
        Name: 'ShifuCup',
        DPSCost: 25
    },
    2100165: {
        Name: 'GobletOfFire',
        DPSCost: 25
    },
    3007376: {
        Name: '1000AttPtsPack',
        DPSCost: 10
    },
    3008256: {
        Name: 'CustomGarmentGem',
        DPSCost: 60
    },
    3008257: {
        Name: 'CustomAccessoryGem',
        DPSCost: 40
    },
    3005889: {
        Name: 'Nametoken',
        DPSCost: 1
    },
    3300309: {
        Name: 'GarmentEnchanter',
        DPSCost: 2
    },
    3004249: {
        Name: 'SacredRefineryPack',
        DPSCost: 3
    },
    3008986: {
        Name: 'CosmeticPass',
        DPSCost: 10
    },
    188915: {
        Name: 'GoldClothSaint',
        DPSCost: 7
    },
    193445: {
        Name: 'FrozenFantasyGlaze',
        DPSCost: 7
    },
    193525: {
        Name: 'FrozenFantasyGlory',
        DPSCost: 7
    },
    189065: {
        Name: 'RobeofDarkness(Helmet)',
        DPSCost: 7
    },
    193565: {
        Name: 'LavaCatStar',
        DPSCost: 7
    },
    193555: {
        Name: 'LavaCatCharm',
        DPSCost: 7
    },
    194385: {
        Name: 'StarryAngelicRobe',
        DPSCost: 7
    },
    194405: {
        Name: 'FoxSpirit(Charm)',
        DPSCost: 7
    },
    193325: {
        Name: 'AspirationJacket',
        DPSCost: 7
    },
    199480: {
        Name: 'DreamRobe',
        DPSCost: 7
    },
    189675: {
        Name: 'BubbleSwimsuit',
        DPSCost: 10
    },
    188965: {
        Name: 'HarbingerSuit',
        DPSCost: 10
    },
    195745: {
        Name: 'FrozenFantasy(Blood)',
        DPSCost: 10
    },
    195835: {
        Name: 'TaleofSwordman',
        DPSCost: 10
    },
    195435: {
        Name: 'AkatsukiRobe',
        DPSCost: 10
    },
    194395: {
        Name: 'LunarAngelicalRobe',
        DPSCost: 10
    },
    195785: {
        Name: 'HellSuitV3',
        DPSCost: 10
    },
    189645: {
        Name: 'ArabicPride',
        DPSCost: 10
    },
    194275: {
        Name: 'SwordArt',
        DPSCost: 10
    },
    193975: {
        Name: 'CelticWarrior',
        DPSCost: 10
    },
    193985: {
        Name: 'BlackWingChun',
        DPSCost: 10
    },
    193275: {
        Name: 'RobeOfDarkness(Hades)',
        DPSCost: 10
    },
    193705: {
        Name: 'RomanticNight',
        DPSCost: 10
    },
    199565: {
        Name: 'SuitPurple',
        DPSCost: 10
    },
    194755: {
        Name: 'Hokage',
        DPSCost: 10
    },
    193725: {
        Name: 'PinkFantasy',
        DPSCost: 10
    },
    194235: {
        Name: 'SempersLegacy',
        DPSCost: 10
    },
    194445: {
        Name: 'CaptainRed',
        DPSCost: 10
    },
    194675: {
        Name: 'WarriorSuitV2',
        DPSCost: 10
    },
    194575: {
        Name: 'ChineseVest',
        DPSCost: 10
    },
    196155: {
        Name: 'Anbu',
        DPSCost: 10
    },
    193965: {
        Name: 'SquidGame',
        DPSCost: 10
    },
    195655: {
        Name: 'DarthVader',
        DPSCost: 13
    },
    194875: {
        Name: 'BrightPurpleRomance',
        DPSCost: 13
    },
    199665: {
        Name: 'TurkishPolice',
        DPSCost: 13
    },
    194175: {
        Name: 'SubZero',
        DPSCost: 13
    },
    194645: {
        Name: 'AssassinCreed',
        DPSCost: 13
    },
    194125: {
        Name: 'Saitaman',
        DPSCost: 13
    },
    183855: {
        Name: 'ArabDiamond',
        DPSCost: 13
    },
    193655: {
        Name: 'FoxyGoblin',
        DPSCost: 13
    },
    193855: {
        Name: 'Army',
        DPSCost: 13
    },
    194155: {
        Name: 'Pirate',
        DPSCost: 13
    },
    194185: {
        Name: 'BlackPanther',
        DPSCost: 13
    },
    194545: {
        Name: 'WinterSoldier',
        DPSCost: 13
    },
    194815: {
        Name: 'AcidSkull',
        DPSCost: 13
    },
    194105: {
        Name: 'Kakegurui',
        DPSCost: 13
    },
    199450: {
        Name: 'AuroraRobe',
        DPSCost: 13
    },
    194315: {
        Name: 'BlueOrchid',
        DPSCost: 13
    },
    193995: {
        Name: 'Sasuke',
        DPSCost: 13
    },
    194085: {
        Name: 'BlackTaiChi',
        DPSCost: 13
    },
    194845: {
        Name: 'FBI',
        DPSCost: 13
    },
    195975: {
        Name: 'PurpleOrchid',
        DPSCost: 13
    },
    194785: {
        Name: 'FemaleZen',
        DPSCost: 13
    },
    194065: {
        Name: 'GetsugaTenshou',
        DPSCost: 13
    },
    183765: {
        Name: 'BobMarley',
        DPSCost: 13
    },
    199895: {
        Name: 'RedOrchid',
        DPSCost: 13
    },
    194055: {
        Name: 'Ichigo',
        DPSCost: 13
    },
    194095: {
        Name: 'OnePiece',
        DPSCost: 13
    },
    193885: {
        Name: 'GreyTaiChi',
        DPSCost: 13
    },
    183835: {
        Name: 'Raiden',
        DPSCost: 13
    },
    183875: {
        Name: 'Death',
        DPSCost: 13
    },
    193695: {
        Name: 'PennyWise',
        DPSCost: 13
    },
    195885: {
        Name: 'AngelVsDemon',
        DPSCost: 13
    },
    199975: {
        Name: 'GambitSuit',
        DPSCost: 13
    },
    195645: {
        Name: 'BlackGoku',
        DPSCost: 13
    },
    183995: {
        Name: 'Mario&Peach',
        DPSCost: 13
    },
    192995: {
        Name: 'OnePalm',
        DPSCost: 13
    },
    195045: {
        Name: 'DarkFairy',
        DPSCost: 13
    },
    194165: {
        Name: 'Vash',
        DPSCost: 15
    },
    183755: {
        Name: 'ScaryMovie',
        DPSCost: 15
    },
    194805: {
        Name: 'Vegetta',
        DPSCost: 15
    },
    194245: {
        Name: 'Cruel',
        DPSCost: 15
    },
    199685: {
        Name: 'HeavenlyWarrior',
        DPSCost: 15
    },
    194555: {
        Name: 'SixPathRobeV1',
        DPSCost: 15
    },
    194835: {
        Name: 'SailorMoon',
        DPSCost: 15
    },
    193895: {
        Name: 'Hermes',
        DPSCost: 15
    },
    194145: {
        Name: 'Goku',
        DPSCost: 15
    },
    193645: {
        Name: 'FinalFantasy',
        DPSCost: 15
    },
    194605: {
        Name: 'IchigoKurosaki',
        DPSCost: 15
    },
    194255: {
        Name: 'YellowFlash',
        DPSCost: 15
    },
    183845: {
        Name: 'MoneyHeist',
        DPSCost: 15
    },
    195995: {
        Name: 'Flowery',
        DPSCost: 15
    },
    195915: {
        Name: 'Batman',
        DPSCost: 15
    },
    195385: {
        Name: 'BlackJingwu',
        DPSCost: 15
    },
    194655: {
        Name: 'MoneyGarment',
        DPSCost: 17
    },
    195725: {
        Name: 'Obito',
        DPSCost: 17
    },
    194015: {
        Name: 'Kakashi',
        DPSCost: 17
    },
    199945: {
        Name: 'AkatsukiV2',
        DPSCost: 17
    },
    199925: {
        Name: 'SixPathRobeV2',
        DPSCost: 17
    },
    195375: {
        Name: 'UchihaMadara',
        DPSCost: 17
    },
    195795: {
        Name: 'PokerLegend',
        DPSCost: 23
    },
    195355: {
        Name: 'GrimReaper',
        DPSCost: 30
    },
    191455: {
        Name: 'Panda',
        DPSCost: 30
    },
    190125: {
        Name: 'PinkyRobe',
        DPSCost: 30
    },
    191485: {
        Name: 'AwakenedWarrior',
        DPSCost: 30
    },
    190145: {
        Name: 'LunarRobe',
        DPSCost: 30
    },
    191465: {
        Name: 'Gladiator',
        DPSCost: 30
    },
    190115: {
        Name: 'EliteRobe',
        DPSCost: 30
    },
    191445: {
        Name: 'Aide',
        DPSCost: 30
    },
    191545: {
        Name: 'Emperor',
        DPSCost: 30
    },
    191495: {
        Name: 'Xmas',
        DPSCost: 30
    },
    191475: {
        Name: 'SantasGirl',
        DPSCost: 30
    },
    191515: {
        Name: 'HalloweenDress',
        DPSCost: 30
    },
    200517: {
        Name: 'KingOfScorpionSaint',
        DPSCost: 7
    },
    200531: {
        Name: 'FieryDragonHades',
        DPSCost: 7
    },
    200474: {
        Name: 'FlameFox',
        DPSCost: 7
    },
    200475: {
        Name: 'SnowFox',
        DPSCost: 7
    },
    200564: {
        Name: 'SuperVictorAlpaca',
        DPSCost: 7
    },
    200525: {
        Name: 'BrightAntelope',
        DPSCost: 7
    },
    200596: {
        Name: 'CelestialFox(Fantasy)',
        DPSCost: 7
    },
    200543: {
        Name: 'GeneralCat(Charm)',
        DPSCost: 7
    },
    200544: {
        Name: 'GeneralCatStar',
        DPSCost: 7
    },
    200641: {
        Name: 'CharlotOfTime',
        DPSCost: 7
    },
    200575: {
        Name: 'AstralPhoenix',
        DPSCost: 12
    },
    200560: {
        Name: 'LunarMonkey',
        DPSCost: 12
    },
    200559: {
        Name: 'SolarMonkey',
        DPSCost: 12
    },
    200617: {
        Name: 'ShinningLion(Splendor)',
        DPSCost: 12
    },
    200616: {
        Name: 'AstralDeer',
        DPSCost: 12
    },
    200627: {
        Name: 'PurpleDragon(Unique)',
        DPSCost: 12
    },
    200626: {
        Name: 'HeavenDragon(Unique)',
        DPSCost: 12
    },
    200643: {
        Name: 'RadiantHusky',
        DPSCost: 12
    },
    200642: {
        Name: 'RadiantSamoyed',
        DPSCost: 12
    },
    200524: {
        Name: 'GentleAntelope',
        DPSCost: 12
    },
    200631: {
        Name: 'SuperDog',
        DPSCost: 12
    },
    200640: {
        Name: 'SolarKylin',
        DPSCost: 12
    },
    200637: {
        Name: 'LunarFox(Illusion)',
        DPSCost: 12
    },
    200635: {
        Name: 'ShadowFox(Shura)',
        DPSCost: 12
    },
    200634: {
        Name: 'ImperialFox(Supreme)',
        DPSCost: 12
    },
    200632: {
        Name: 'GiantFish(Ripple)',
        DPSCost: 12
    },
    200633: {
        Name: 'GiantFish(Gold)',
        DPSCost: 12
    },
    200624: {
        Name: 'MoonKylin',
        DPSCost: 12
    },
    200623: {
        Name: 'CelestialDragon(Freedom)',
        DPSCost: 12
    },
    200622: {
        Name: 'BigToad',
        DPSCost: 12
    },
    200579: {
        Name: 'RipperBeast',
        DPSCost: 12
    },
    200644: {
        Name: 'DarkBeast',
        DPSCost: 12
    },
    200580: {
        Name: 'MythicBeast',
        DPSCost: 12
    },
    200500: {
        Name: 'Fuleco',
        DPSCost: 12
    },
    200553: {
        Name: 'CuteMonkey',
        DPSCost: 12
    },
    200554: {
        Name: 'YokMonkey',
        DPSCost: 12
    },
    200555: {
        Name: 'SmartMonkey',
        DPSCost: 12
    },
    200556: {
        Name: 'JoyfulMonkey',
        DPSCost: 12
    },
    200557: {
        Name: 'FunnyMonkey',
        DPSCost: 12
    },
    200558: {
        Name: 'CharmingMonkey',
        DPSCost: 12
    },
    200494: {
        Name: 'MoneyHorse',
        DPSCost: 13
    },
    200691: {
        Name: 'Ox',
        DPSCost: 17
    },
    200700: {
        Name: 'LightningLeopard',
        DPSCost: 20
    },
    200701: {
        Name: 'SnowLeopard',
        DPSCost: 20
    },
    200703: {
        Name: 'ShadowLeopard',
        DPSCost: 20
    },
    200704: {
        Name: 'ScarletLeopard',
        DPSCost: 20
    },
    200768: {
        Name: 'BabyDragon(Cyan)',
        DPSCost: 22
    },
    200769: {
        Name: 'BabyDragon(Blue)',
        DPSCost: 22
    },
    200770: {
        Name: 'BabyDragon(Red)',
        DPSCost: 22
    },
    200771: {
        Name: 'BabyDragon(Purple)',
        DPSCost: 22
    },
    200628: {
        Name: 'ThunderMotorcycle',
        DPSCost: 22
    },
    200629: {
        Name: 'SoulCharlot',
        DPSCost: 22
    },
    200790: {
        Name: 'GhostRider',
        DPSCost: 22
    },
    200707: {
        Name: 'HalloweenBroom',
        DPSCost: 22
    },
    350090: {
        Name: 'DarkCatStaff(Charm)',
        DPSCost: 8
    },
    350108: {
        Name: 'LunarAffection(Sparkle)',
        DPSCost: 8
    },
    350083: {
        Name: 'PinkySpear',
        DPSCost: 4
    },
    370040: {
        Name: 'MewMewBow(Charm)',
        DPSCost: 4
    },
    370048: {
        Name: 'LovesickBow(Sparkle)',
        DPSCost: 4
    },
    370050: {
        Name: 'BubbleofSeahorse',
        DPSCost: 5
    },
    380056: {
        Name: 'BubbleWaves',
        DPSCost: 5
    },
    380054: {
        Name: 'IdealShield(Sparkle)',
        DPSCost: 5
    },
    380046: {
        Name: 'MewMewShield(Charm)',
        DPSCost: 5
    },
    360468: {
        Name: 'Knife',
        DPSCost: 5
    },
    360202: {
        Name: 'GoldenDragonslayer(1-handed)',
        DPSCost: 5
    },
    360201: {
        Name: 'SilverDragonslayer(1-handed)',
        DPSCost: 5
    },
    360203: {
        Name: 'BronzeDragonslayer(1-handed)',
        DPSCost: 5
    },
    360208: {
        Name: 'PhoenixDream(Sparkle)',
        DPSCost: 5
    },
    360192: {
        Name: 'GraspofLove',
        DPSCost: 5
    },
    360439: {
        Name: 'CakePop(1-Handed)',
        DPSCost: 5
    },
    360186: {
        Name: 'KingCatStaff(Charm)',
        DPSCost: 5
    },
    360217: {
        Name: 'BubbleUmbrella',
        DPSCost: 5
    },
    360303: {
        Name: 'LavaBlade',
        DPSCost: 5
    },
    360412: {
        Name: 'BodhiSword',
        DPSCost: 5
    },
    360449: {
        Name: 'LightSword',
        DPSCost: 5
    },
    360325: {
        Name: 'BlackRose',
        DPSCost: 5
    },
    360403: {
        Name: 'EmeraldScythe',
        DPSCost: 5
    },
    360462: {
        Name: 'RedBuriedBlade',
        DPSCost: 5
    },
    360434: {
        Name: 'Acid130Blade(OG)',
        DPSCost: 6
    },
    360437: {
        Name: 'Acid130Sword(OG)',
        DPSCost: 6
    },
    360445: {
        Name: 'Acid130Club(OG)',
        DPSCost: 6
    },
    360447: {
        Name: 'Acid130Scepter(OG)',
        DPSCost: 6
    },
    360451: {
        Name: 'GoldenDagger',
        DPSCost: 7
    },
    360405: {
        Name: 'SwordOfElements',
        DPSCost: 7
    },
    360222: {
        Name: 'ThorFireHammer',
        DPSCost: 7
    },
    360221: {
        Name: 'ThorIceHammer',
        DPSCost: 7
    },
    360220: {
        Name: 'DemonicBlade',
        DPSCost: 7
    },
    360428: {
        Name: 'RedCurse',
        DPSCost: 7
    },
    360218: {
        Name: 'ElementsBlade',
        DPSCost: 7
    },
    360429: {
        Name: 'DragonHowl',
        DPSCost: 7
    },
    360304: {
        Name: 'PinkyBlade',
        DPSCost: 7
    },
    360377: {
        Name: 'PinkySword',
        DPSCost: 7
    },
    360455: {
        Name: 'PinkyClub',
        DPSCost: 7
    },
    360414: {
        Name: 'DragonBlade',
        DPSCost: 7
    },
    360442: {
        Name: 'StargazerSword',
        DPSCost: 7
    },
    360431: {
        Name: 'BloodlustSword',
        DPSCost: 7
    },
    360511: {
        Name: 'JadeBlade',
        DPSCost: 9
    },
    360349: {
        Name: 'JadeSword',
        DPSCost: 9
    },
    360470: {
        Name: 'FloralWeapon',
        DPSCost: 9
    },
    360435: {
        Name: 'DeadlyThorn',
        DPSCost: 10
    }
};

export default Items;