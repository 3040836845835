import { useState } from 'react';
import {Modal} from 'react-bootstrap'
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { LoginForm } from './LoginForm'

export function LoginModal(props) {
    const [form, setForm] = useState("login");

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
        onShow={()=>{document.getElementsByTagName("body")[0].style = "";}}
        onExited={()=>{setForm("login")}}
      >
        <Modal.Header>
          <div>
            <span class="h-large">L</span><span class="h-small">ogin</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {
            (form === "login") 
            ?
              <LoginForm modal typeCallback={setForm} />
            :
              <ForgotPasswordForm typeCallback={setForm}/>
          }
        </Modal.Body>
      </Modal>
    );
  }
  