import Axios from 'axios';
import { getToken } from './TokenService'

export function checkout(coins) {
  return new Promise(resolve => {
    Axios.get(`https://www.paragonconquer.com/api/checkout/${coins}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data);
        } else {
            resolve(false);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
})
}

export function getAllDonationStoreItems() {
    return new Promise(resolve => {
        Axios.get('https://www.paragonconquer.com/api/getDonationItems')
          .then(function (response) {
            if (response.data["error"] === undefined) {
                resolve(response.data);
            } else {
                resolve(false);
            }
          })
          .catch(function (error) {
            resolve(false);
          });
    })
}

export function getDonationStoreItem(id) {
  return new Promise(resolve => {
      Axios.get('https://www.paragonconquer.com/api/getDonationItem/' + id)
        .then(function (response) {
          if (response.data["error"] === undefined) {
              resolve(response.data);
          } else {
              resolve(false);
          }
        })
        .catch(function (error) {
          resolve(false);
        });
  })
}

export function updateSetting(key, value) {
  return new Promise(resolve => {
    Axios.post('https://www.paragonconquer.com/api/updateSetting', {
        key: key,
        value: value
      }, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
      })
      .then(function (response) {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(response.data.error);
        }
      })
      .catch(function (error) {
        resolve(false);
      });
})
}

export function getDownloadLinks() {
  return new Promise(resolve => {
      Axios.get('https://www.paragonconquer.com/api/getDownloadLinks')
      .then(response => {
          if (response.data["error"] === undefined) {
              resolve(response.data.result);
          } else {
              resolve(null);
          }
      })
      .catch(error => {
          resolve(null);
      })
  })
}

export function getNobilityReset() {
  return new Promise(resolve => {
    Axios.get('https://www.paragonconquer.com/api/getNobilityReset')
    .then(response => {
        if (response.data["error"] === undefined) {
            resolve(response.data.result);
        } else {
            resolve(null);
        }
    })
    .catch(error => {
        resolve(null);
    })
  })
}