import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/BattlePass2/pic1.webp'
import Image2 from '../../images/Guide/BattlePass2/pic2.webp'
import Image3 from '../../images/Guide/BattlePass2/pic3.webp'
import Image4 from '../../images/Guide/BattlePass2/pic4.webp'
import Image5 from '../../images/Guide/BattlePass2/pic5.webp'
import Image6 from '../../images/Guide/BattlePass2/pic6.webp'
import Image7 from '../../images/Guide/BattlePass2/pic7.webp'
import Image8 from '../../images/Guide/BattlePass2/pic8.webp'
import Image9 from '../../images/Guide/BattlePass2/pic9.webp'
import Image10 from '../../images/Guide/BattlePass2/pic10.webp'
import Image11 from '../../images/Guide/BattlePass2/pic11.webp'
import Image12 from '../../images/Guide/BattlePass2/pic12.webp'
import Image13 from '../../images/Guide/BattlePass2/pic13.webp'
import Image14 from '../../images/Guide/BattlePass2/pic14.webp'
import Image15 from '../../images/Guide/BattlePass2/pic15.webp'
import Image16 from '../../images/Guide/BattlePass2/pic16.webp'

export function BattlePass2() {
    return (
        <div className="guide white">
            <h3>Battle Pass 2</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers the Battle Pass #2.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>BattlePass <i>(Item)</i></li>
                <li>FlowerStage <i>(Twin City)</i></li>
                <li>BlissBoy <i>(Twin City)</i></li>
                <li>NameToken <i>(Item)</i></li>
                <li>TalismanEnchanter <i>(Item)</i></li>
                <li>Battle Bunny Riven<i>(Garment)</i></li>
                <li>Battle Bunny Sword<i>(Accessory)</i></li>
                <li>SomersaultCloud <i>(Steed Armor)</i></li>
                <li>AmethystStone <i>(Item)</i></li>
                <li>RubyStone <i>(Item)</i></li>
                <li>Killua(Suit)<i>(Garment)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Purchase the Battle Pass 2 through the Coin Store for 10 Coins.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">The Battle Pass 2 has 7 levels, you start from 0 and have to level up all the way up to 7. It takes 100 Battle Pass Points to level up.</p>
                    <Image src={Image2} fluid />
                    <p className="tip">*Note that the daily quests restart at 00:00 server time.</p>
                    <Image src={Image3} fluid />
                </li>
            </ol>
            <p className="head">About Daily Quests:</p>
            <li>There currently are 5 different quests, each one gives 10 Battle Pass Points and 2M CPs:
            </li>              
            <ol>          
                <ol>
                    <li>SacrificeToken: Highly recommend to use bow to farm them, it drops on the ground and you get a message box for it. Collect 10 and bring them to BlissBoy.</li>
                    <Image src={Image4} fluid />
                    <li>SpecialOctopus: Your damage will be 1, this boss has 300 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image5} fluid />
                    <li>SungJingwoo: Your damage will be 1, this boss has 100 HP, once defeated you will obtain the 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image6} fluid />
                    <li>Win 10 Arena Qualifier Matches: You must win 10 Arena Qualifier matches to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image7} fluid />
                    <li>Defeat Hourly Bosses/Boss Invasion: Kill any of the Hourly Bosses or Boss Invasion to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image8} fluid />
                    <li>Complete Tower of Mystery: Defeat the last Boss of Tower of Mystery(Ruthless Aura) to obtain 10 Battle Pass Points + 2M CPs.</li>
                    <Image src={Image9} fluid />
                    <Image src={Image10} fluid />
                    <li>Win Hourly Events: Upon winning any of the HOURLY events, you have a chance to get BattlePassPoints(10) item + 2M CPs.</li>
                    <Image src={Image11} fluid />
                </ol>  
            </ol>
            <p className="head">About Levels:</p>
            <li>There are 7 levels, the reward are the following:
            </li>              
            <ol>          
                <ol>
                    <li>Level 1: 100M CPs, 2,000 Inner Power Points and (1) NameToken(Tradable)</li>
                    <li>Level 2: 100M CPs, 2,500 Inner Power Points and 3,000 Champion Points.</li>
                    <li>Level 3: 100M CPs, 4,000 Inner Power Points and (1) SomersaultCloud(255 HP Bound Steed Armor)</li>
                    <li>Level 4: 100M CPs, 10,000 Champion Points and (1) BattleBunnyRiven(255 HP Bound Garment)</li>
                    <Image src={Image12} fluid />
                    <li>Level 5: 100M CPs, 6,000 Inner Power Points and (2) BattleBunnySword(255 HP Bound Accessories)</li>
                    <Image src={Image13} fluid />
                    <li>Level 6: 100M CPs, 5,000 Inner Power Points and (1) AmethystStone(Untradable)</li>
                    <p className="tip">*AmethystStone is a clickable item where you get to choose whether you want 2000 Attribute Points or 3 SuperBook(Tradable)</p>
                    <Image src={Image14} fluid />
                    <li>Level 7: 100M CPs, (1) RubyStone(Untradable) + Killua(Suit) 255 HP Bound Garment.</li>
                    <Image src={Image16} fluid />
                    <p className="tip">*RubyStone is a clickable item where you get to choose whether you want ForgeOrb(Bound) or 3 TalismanEnchanter(Tradable)</p>
                    <p className="tip">What is ForgeOrb? It's a clickable item to forge one of the donation cups(ShifuCup, ImperialCup or GobletofFire)</p>
                    <Image src={Image15} fluid />
                </ol>  
            </ol>
        </div>
    );
}