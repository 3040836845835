import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/GettingStarted/pic1.jpg'
import Image2 from '../../images/Guide/GettingStarted/pic2.jpg'
import Image3 from '../../images/Guide/GettingStarted/pic3.jpg'
import Image4 from '../../images/Guide/GettingStarted/pic4.jpg'
import Image5 from '../../images/Guide/GettingStarted/pic5.jpg'
import Image6 from '../../images/Guide/GettingStarted/pic6.jpg'
import Image7 from '../../images/Guide/GettingStarted/pic7.jpg'
import Image8 from '../../images/Guide/GettingStarted/pic8.jpg'
import Image9 from '../../images/Guide/GettingStarted/pic9.jpg'
import Image10 from '../../images/Guide/GettingStarted/pic10.jpg'
import Image11 from '../../images/Guide/GettingStarted/pic11.jpg'
import Image12 from '../../images/Guide/GettingStarted/pic12.jpg'
import Image13 from '../../images/Guide/GettingStarted/pic13.jpg'
import Image14 from '../../images/Guide/GettingStarted/pic14.jpg'
import { LinkContainer } from 'react-router-bootstrap';

export function GettingStarted() {
    return (
        <div className="guide white">
            <h3>Getting Started</h3>
            
            <p className="head">About:</p>
            <ul>
                <li>This guide covers basics and a general recommendations to get started.</li>
            </ul>

            <p className="head">NPCs, Items, Maps:</p>
            <ol>
                <li>GuideBook <i>(Item)</i></li>
                <li>Mariah <i>(Twin City)</i></li>
                <li>Conductress <i>(LevelingZone)</i></li>
                <li>PromotionCenter <i>(Twin City)</i></li>
                <li>NewbieQuest <i>(Twin City)</i></li>
                <li>MerchantPakku <i>(TwinCity)</i></li>
                <li>Cerberus <i>(TwinCity)</i></li>
                <li>CloudSaint <i>(Market)</i></li>
                <li>ExchangerBatari <i>(TwinCity)</i></li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Start of the journey; You spawn in TwinCity, You're going to find "GuideBook" Item in your inventory, Right click to display an in-game guide.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Mariah to claim free P6 bound gears based on your class.</p>
                    <p className="tip">*Note that if you're a taoist, You have choose a class first; Water/Fire.</p>
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">Go to Conductress and click "LevelingZone" option to go to the leveling zone to get the highest level(140).</p>
                    <p className="tip">*Note that you're going to get a message box in the screen with instructions to equip the talismans that you have in your inventory to level up even faster.</p>
                    <Image src={Image3} fluid />
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">Once you're level 140, Go to the PromotionCenter to promote to the highest title for your class.</p>
                    <p className="tip">*Note that you can learn all the available skills for your class within one click.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                </li>
                <li>
                    <p className="instruction">Newbie Quest is a must as you get 462 Permanent Attribute Points completely FREE.</p>
                    <LinkContainer to="/guide/newbies/NewbieGuide">
                        <p className="tip">*Here is the complete walthrough for the NewbieQuest.</p>
                    </LinkContainer>
                    <Image src={Image7} fluid />
                </li>
                <li>
                    <p className="instruction">P7 souls are sold by MerchantPakku; Gears have a price of 5M CPs and weapons go for 7M CPs, 2 handed weapons cost are halved.</p>
                    <Image src={Image8} fluid />
                </li>
                <li>
                    <p className="instruction">One random boss is generated at every x:15 and x:45: ThrillingSpook, SnowBanshee, SeaSpider, ChaosBeast, HeavenRoc, AllurinWitch, FlameGiant and NemesisTyrant. All 4 of them have the same drops.
                     Killer gets 1 BossesTreasure to its inventory and 50 Champion Points, You may also get 1 BossKill Point(Daily) that can be exchanged
                     for rewards. Bosses also have on the ground drops: 3 SplendidStarStone, 3 MoonCPsPack(500k CPs), 1 LootKey, 1 LootBox, 2 CosmeticTokens and 5 Essence.</p>
                     <p className="tip">When one of the bosses is alive, the option will be displayed as a clickable option to have a quick teleport to where the boss is at.</p>
                     <p className="tip">Note that the on the ground drops will be available for anyone to pick them up after 5 seconds, All the hourly bosses deal 8.5k true damage to players
                     around it. SoulShackle is blocked in all the bosses' maps.</p>
                     <p className="tip">Essence? They're a right-clickable and untradable item that is converted to a currency which can be swapped for rewards at Cerberus.</p>
                    <Image src={Image9} fluid />
                </li>
                <li>
                    <p className="instruction">Jar Quest is a daily must-do to farm Attribute Points, Talk to CloudSaint to start the daily extermination. There are 4 stages: 500,800,1100 and 1500.
                    Complete all 4 stages to get 30 Attribute Points.</p>
                    <p className="tip">*Note that the amount of kills that you need to do per stage is multiplied by 3 if you're an Archer.</p>
                    <Image src={Image10} fluid />
                    <Image src={Image11} fluid />
                </li>
                <li>
                    <p className="instruction">Another way to earn attribute points and make CPs is doing the Forest Treasure event.</p>
                    <LinkContainer to="/guide/gameplay/TreasureForest">
                        <p className="tip">*Here is the full Forest Treasure guide.</p>
                    </LinkContainer>
                    <Image src={Image13} fluid />
                </li>
                <li>
                    <p className="instruction">For many more options to learn about Attribute Points and where to obtain them we recommend to read the guide.</p>
                    <LinkContainer to="/guide/gameplay/AttributePoints">
                        <p className="tip">*Here is the full Attribute Points guide.</p>
                    </LinkContainer>
                </li>
                <li>
                    <p className="instruction">Stay online to farm Online Points that can be used to increase your VIP level to unlock more of its features or exchange to Champion Points that can be used
                    to buy items from its store: PerfectionStones, Unique steed armors and house permits.</p>
                    <p className="tip">*Note that items bought from the Champion Points store are all BOUND, Perfection Stones included.</p>
                    <LinkContainer to="/guide/gameplay/VipSystem">
                        <p className="tip">*Here is the full VIP system guide.</p>
                    </LinkContainer>
                    <Image src={Image12} fluid />
                </li>
                <li>
                    <p className="instruction">Participating in the hourly events such as LMS, Daily PK, Betting Contest, FFA events, etc. Will now give you 50 Event Points. Collect 6000 and 7500 online Points 
                    to forge the Mini Cups: Free-to-get Cups of the Donation Cups.</p>
                    <p className="tip">*Note that these new cups can also be swapped between each other for a 15M CPs fee.</p>
                    
                    <LinkContainer to="/guide/gameplay/MiniCups">
                        <p className="tip">*Here is the full Mini Cups guide.</p>
                    </LinkContainer>
                    <Image src={Image14} fluid />
                </li>
            </ol>
        </div>
    );
}