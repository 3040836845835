import { useState } from "react";
import { getNobilityReset } from "../services/SettingsService";
import { Countdown } from "./Countdown";

export function NobilityReset() {
    const [settings, setSettings] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    async function loadSettings() {
        let settingsResult = await getNobilityReset();
        setSettingsLoaded(true);
        
        setSettings(settingsResult);
    }

    if (!settingsLoaded) {
        loadSettings();
    }

    let resetYear = (!settings) ? 0 : parseInt(settings.find(x => x.key === "nobilityResetDate").value.split('-')[0]);
    let resetMonth = (!settings) ? 0 : parseInt(settings.find(x => x.key === "nobilityResetDate").value.split('-')[1]) - 1

    return(
        <div className="sidebar-object">
            <div className="sidebar-header">
                <span className="h-large">N</span><span className="h-small">obility</span>&nbsp;&nbsp;<span className="h-large">R</span><span className="h-small">eset</span>
            </div>
            <div className="sidebar-body">
                <div>
                    <p style={{fontSize:13}}>{(!settings) ? "" : settings.find(x => x.key === "nobilityResetMessage").value}</p>
                </div>
                <div>
                    <h5>Next Reset:</h5>
                    {
                        (() => {
                            if (settings) {
                                return (<Countdown date={new Date(resetYear, resetMonth, 1, 0, 0, 0, 0)} />)
                            } else {
                                return <></>
                            }
                        })()
                    }
                    
                </div>
            </div>
        </div>
    );
}