import { useAccount } from '../Context/AccountProvider';
import { getToken } from '../services/TokenService';
import AuthenticationError from "../components/AuthenticationError";
import { CreateTicketForm } from '../components/CreateTicketForm';

export function CreateTicket() {
  const { accountInfo } = useAccount();

  if (accountInfo.loggedIn) {
    return (
      <CreateTicketForm />
    )
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}