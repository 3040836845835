import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { createUpdateNote, modifyUpdateNote } from "../../services/UpdateNotesService";

export default function UpdateNoteForm(props) {

    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [content, setContent] = useState('');
    
    useEffect(() => {
        if (props.announcement) {
            console.log(props.announcement);
            setTitle(props.announcement.Title);
            setDate(new Date(props.announcement.Date).toISOString().split('T')[0]);
            setContent(props.announcement.Content);
        } else {
            setTitle('');
            setDate(new Date().toISOString().split('T')[0]);
            setContent('');
        }
    }, [props.announcement]);

    async function handleForm(event) {
        event.preventDefault();        

        var noteId;

        if (props.announcement) {
            await modifyUpdateNote(props.announcement.ID, title, content, date);
            noteId = props.announcement.ID;
        } else {
            noteId = await createUpdateNote(title, content, date);
        }

        document.dispatchEvent(
            new CustomEvent("pg.updateNote.modify", {
                detail: {
                    id: noteId,
                    title: title,
                    content: content,
                    date: date
                }
            })
        );
    }

    return (
        <Form onSubmit={handleForm}>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" placeholder="Title" required defaultValue={title} onChange={(e) => setTitle(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="date">
                <Form.Label>Date</Form.Label>
                <input type="date" id="start" className="form-control" name="date" required
                defaultValue={date}  onChange={(e) => setDate(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="content">
                <Form.Label>Content</Form.Label>
                <Form.Control as="textarea" rows={5} required defaultValue={content}  onChange={(e) => setContent(e.target.value)} />
            </Form.Group>

            <div className="form-button-right">
                <Button variant="success" type="submit">
                    {(props.announcement) ? "Save" : "Create"}
                </Button>
            </div>
        </Form>
    )
}