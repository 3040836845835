import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/VipSystem/pic1.webp'
import Image2 from '../../images/Guide/VipSystem/pic2.webp'
import Image3 from '../../images/Guide/VipSystem/pic3.webp'
import Image4 from '../../images/Guide/VipSystem/pic4.webp'
import Image5 from '../../images/Guide/VipSystem/pic5.webp'
import Image6 from '../../images/Guide/VipSystem/pic6.webp'
import Image7 from '../../images/Guide/VipSystem/pic7.webp'
import Image8 from '../../images/Guide/VipSystem/pic8.webp'
import Image9 from '../../images/Guide/VipSystem/pic9.webp'

export function VipSystem() {
    return (
        <div className="guide white">
            <h3>Vip System and Online Points</h3>

            <p className="head">About VIP levels:</p>
            <ol>
                <li>There are 7 levels for the VIP memberships, Level 1 to 6 are upgradable ONE BY ONE using Online Points(Default is 1 per minute). This system rewards players for their activity. Talk to ExchangerBatari
                    to upgrade your VIP levels, You'll also be told how many Online Points you need for each level.
                </li>
                <p className="instruction">Here are how many Online Points you need to upgrade each VIP level.</p>
                <ol>
                    <li>Level 1: 8000 Online Points.</li>
                    <li>Level 2: 10000 Online Points.</li>
                    <li>Level 3: 15000 Online Points.</li>
                    <li>Level 4: 20000 Online Points.</li>
                    <li>Level 5: 26000 Online Points.</li>
                    <li>Level 6: 32000 Online Points.</li>
                </ol>
                <li>
                    <p className="instruction">These are the features for VIP level 1 to 6.</p>
                    <Image src={Image1} fluid />
                </li>                 
            </ol>
            <p className="head">About VIP level 7:</p>
            <ol>
                <li>VIP level 7 is a one-time subscription that can be bought in the Donation Shop. It includes all of the already listed VIP 6 features and the following in addition:              
                </li>
                <ol>
                    <li>3 Online Points per minute.</li>
                    <li>Permanent Refinery Stabilization.</li>
                    <li>50 Flowers daily.</li>
                    <li>120 Friends(Total).</li>
                    <li>3 Exclusive Titles: SupremeGold, SupremePlatinum and SupremeDiamond.</li>
                    <li>1 Exclusive Garment: Paragon, with both male and female versions.</li>
                    <li>2 Exclusive Wings: SolarDragon and SirenSong.</li>
                    <li>2 Exclusive Steed Armors: CelestialDragon and SeaDragon.</li>
                    <li>VIP 7 Pass.</li>
                    <li>@dnd / @donotdisturb In-Game command to block trades, system messages and team invites.</li>
                    <li>17 New hair styles.</li>
                    <Image src={Image3} fluid />
                    <Image src={Image4} fluid />
                    <Image src={Image5} fluid />
                </ol>
            </ol>
            <p className="head">About VIP 7 Pass:</p>
            <ol>          
                <ol>
                    <li>Ability to directly complete all of the Epic Quests.</li>
                    <li>Change your Guilds name for free.</li>
                    <li>Ability to swap your donation cups between each other for free.</li>
                    <li>Ability to get Skill Souls for free.</li>
                    <li>Gain 2 daily Boss Hunter points.</li>
                    <li>Gain Stigma when starting Tower of Mystery daily quest, can only be used in Capture pk mode.</li>
                    <li>Quick Unlock ability to instantly unlock gears, must contact a GM to obtain.</li>
                    <Image src={Image8} fluid />
                    <li>Ability to change Skill Soul names and reproduce the text when killing a target.</li>
                    <Image src={Image9} fluid />
                    <li>VIP tag title before your characters name.</li>
                    <Image src={Image6} fluid />
                    <li>Have a customized tag title that only you can use.</li>
                    <Image src={Image7} fluid />
                </ol>  
            </ol>
            <p className="head">About Champion Points:</p>
            <ol>          
                <p className="instruction">You can exchange your Online Points for Champion Points:</p>
                <ol>
                    <li>100 Online Points for 30 Champion Points.</li>
                    <li>1100 Online Points for 350 Champion Points.</li>
                    <li>12000 Online Points for 4000 Champion Points.</li>
                    <p className="tip">*Items bought from the Champion Points Store will ALL be bound, Perfection Stones included.</p>
                    <Image src={Image2} fluid />
                </ol>  
            </ol>
        </div>
    );
}