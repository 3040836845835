import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/EpicWarriorQuest/pic1.jpg'
import Image2 from '../../images/Guide/EpicWarriorQuest/pic2.jpg'
import Image3 from '../../images/Guide/EpicWarriorQuest/pic3.jpg'
import Image4 from '../../images/Guide/EpicWarriorQuest/pic4.jpg'
import Image5 from '../../images/Guide/EpicWarriorQuest/pic5.jpg'
import Image6 from '../../images/Guide/EpicWarriorQuest/pic6.jpg'
import Image7 from '../../images/Guide/EpicWarriorQuest/pic7.jpg'
import Image8 from '../../images/Guide/EpicWarriorQuest/pic8.jpg'
import Image9 from '../../images/Guide/EpicWarriorQuest/pic9.jpg'
import Image10 from '../../images/Guide/EpicWarriorQuest/pic10.jpg'
import Image11 from '../../images/Guide/EpicWarriorQuest/pic11.jpg' 
import Image12 from '../../images/Guide/EpicWarriorQuest/pic12.jpg'
import Image13 from '../../images/Guide/EpicWarriorQuest/pic13.jpg'
import Image14 from '../../images/Guide/EpicWarriorQuest/pic14.jpg'
import Image15 from '../../images/Guide/EpicWarriorQuest/pic15.jpg'
import Image16 from '../../images/Guide/EpicWarriorQuest/pic16.jpg'
import Image17 from '../../images/Guide/EpicWarriorQuest/pic17.jpg'
import Image18 from '../../images/Guide/EpicWarriorQuest/pic18.jpg'
import Image19 from '../../images/Guide/EpicWarriorQuest/pic19.jpg'
import Image20 from '../../images/Guide/EpicWarriorQuest/pic20.jpg'
import Image21 from '../../images/Guide/EpicWarriorQuest/pic21.jpg'
import Image22 from '../../images/Guide/EpicWarriorQuest/pic22.jpg'
import Image23 from '../../images/Guide/EpicWarriorQuest/pic23.jpg'
import Image24 from '../../images/Guide/EpicWarriorQuest/pic24.jpg' 
import Image25 from '../../images/Guide/EpicWarriorQuest/pic25.jpg'
import Image26 from '../../images/Guide/EpicWarriorQuest/pic26.jpg'
import Image27 from '../../images/Guide/EpicWarriorQuest/pic27.jpg'
import Image28 from '../../images/Guide/EpicWarriorQuest/pic28.jpg'
import Image29 from '../../images/Guide/EpicWarriorQuest/pic29.jpg'
import Image30 from '../../images/Guide/EpicWarriorQuest/pic30.jpg'
import Image31 from '../../images/Guide/EpicWarriorQuest/pic31.jpg'
import Image32 from '../../images/Guide/EpicWarriorQuest/pic32.jpg'
import Image33 from '../../images/Guide/EpicWarriorQuest/pic33.jpg'
import Image34 from '../../images/Guide/EpicWarriorQuest/pic34.jpg'
import Image35 from '../../images/Guide/EpicWarriorQuest/pic35.jpg'
import Image36 from '../../images/Guide/EpicWarriorQuest/pic36.jpg'
import Image37 from '../../images/Guide/EpicWarriorQuest/pic37.jpg'
import Image38 from '../../images/Guide/EpicWarriorQuest/pic38.jpg'
import Image39 from '../../images/Guide/EpicWarriorQuest/pic39.jpg'
import Image40 from '../../images/Guide/EpicWarriorQuest/pic40.jpg'
import Image41 from '../../images/Guide/EpicWarriorQuest/pic41.jpg'
import Image42 from '../../images/Guide/EpicWarriorQuest/pic42.jpg'
import Image43 from '../../images/Guide/EpicWarriorQuest/pic43.jpg'
import Image44 from '../../images/Guide/EpicWarriorQuest/pic44.jpg'
import Image45 from '../../images/Guide/EpicWarriorQuest/pic45.jpg'
import Image46 from '../../images/Guide/EpicWarriorQuest/pic46.jpg'


export function EpicWarriorQuest() {
    return (
        <div className="guide white">
            <h3>Epic Warrior Quest</h3>
            
            <p className="head">Requirements</p>
            <ul>
                <li>Level 140</li>
                <li>2nd Reborn Character</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>WarriorGod <i>(Job Center)</i></li>
                <li>XuFan <i>(Bird Island)</i></li>
                <li>SugarTang <i>(Bird Island)</i></li>
                <li>YangYun <i>(Bird Island)</i></li>
                <li>YangYun2 <i>(Yang's Treasure House)</i></li>
                <li>YeSheng <i>(Bird Island)</i></li>
                <li>TimeDoor <i>(Bird Island))</i></li>
                <li>SeasonDoor <i>(Bird Island)</i></li>
                <li>DreamDoor <i>(Bird Island)</i></li>
                <li>RuanBrother <i>(Hut)</i></li>
                <li>Plum <i>(Desert City)</i></li>
                <li>GhoulKong <i>(Desert City)</i></li>
                <li>MonkMisery <i>(GhoulKong's Village)</i></li>
                <li>Cauldron <i>(Dungeon, 3rd Floor)</i></li>
                
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>FrozenArmor</li>
                <li>Letter</li>
                <li>TowerSplinter<i>(3)</i></li>
                <li>FishingNet</li>
                <li>FreshFish<i>(3)</i></li>
                <li>IronPoise</li>
                <li>HonorScroll</li>
                <li>Shawn'sSpear</li>
                <li>AnnatoBlade</li>
                <li>GlitterSword</li>
                <li>InfernalAxe</li>
                <li>WaspSpear</li>
                <li>Clinkers<i>(4)</i></li>
                <li>StarTower</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to WarriorGod (Job Center) to start.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to XuFan to get FrozenArmor and take it to SugarTang.</p>
                    <Image src={Image2} fluid />
                    <Image src={Image3} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to SugarTang to learn about the quest's stages.</p>
                    <p className="tip">*If you're familar with the Tower Quest this will be very familiar.</p>
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">Take Letter(Item) to YangYun and ask about the first piece.</p>
                    <Image src={Image5} fluid />
                    <Image src={Image6} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent to the Yang's Treasure House, to notice that it has been invaded by Bandits. Defeat them all and then talk to YangYun to get the first TowerSplinter.</p>
                    <Image src={Image7} fluid />
                    <Image src={Image8} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent back to YangYun and ask about the second piece.</p>
                    <Image src={Image9} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to YeSheng and learn about fishing, you'll be sent to NetPeddler to get (1) FishingNet
and then sent to one fishing island, right click the FishingNet to summon FishElf, defeat it
to get (3) FreshFish.</p>
                    <Image src={Image10} fluid />
                    <Image src={Image11} fluid />
                    <Image src={Image12} fluid />
                    <Image src={Image13} fluid />
                    <Image src={Image14} fluid />
                </li>
                <li>
                    <p className="instruction">Take the (3) FreshFish back to YeSheng to trade them for (1) IronPoise.</p>
                    <Image src={Image15} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to HutsGuardian to learn about the Hut.</p>
                    <Image src={Image16} fluid />
                    <p className="tip">*You have to activate the Doors in the following order.</p>
                    <Image src={Image17} fluid />
                </li>
                <li>
                    <p className="instruction">You'll get inside the Hut, find RuanBrother to be sent on the missiong of getting Shawn'sSpear.</p>
                    <Image src={Image18} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent to the GuardingStone, ask for information about Shawn'sSpear.</p>
                    <Image src={Image19} fluid />
                </li>
                <li>
                    <p className="instruction">Take Shawn'sSpear back to RuanBrother to trade it for the second TowerSplinter.</p>
                    <Image src={Image20} fluid />
                    <Image src={Image21} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent back to YengYun, ask about the third piece.</p>
                    <Image src={Image22} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Plum to learn about the thirds piece location.</p>
                    <Image src={Image23} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to GhoulKong to negotiate the third piece.</p>
                    <Image src={Image24} fluid />
                </li>
                <li>
                    <p className="instruction">Find EvilMan inside GhoulKong's Village, talk to him to save the Village.</p>
                    <Image src={Image25} fluid />
                    <Image src={Image26} fluid />
                </li>
                <li>
                    <p className="instruction">Defeat Mercenary to get DungeonMap(Item) , take it back to GhoulKong to explore the dungeons.</p>
                    <Image src={Image27} fluid />
                    <Image src={Image28} fluid />
                </li>
                <li>
                    <p className="instruction">You'll be sent to the 1st Floor. Find MausoleumGeneral3, defeat it to get AnnatoBlade(Item)</p>
                    <Image src={Image29} fluid />
                    <Image src={Image30} fluid />
                </li>
                <li>
                    <p className="instruction">Go downstairs and find MausoleumGeneral2, defeat it to get GlitterSword(Item)</p>
                    <Image src={Image31} fluid />
                    <Image src={Image32} fluid />
                </li>
                <li>
                    <p className="instruction">Having AnnatoBlade and GlitterSword, jump into the Portal to be sent to the 2nd Floor.</p>
                    <Image src={Image33} fluid />
                    <Image src={Image34} fluid />
                </li>
                <li>
                    <p className="instruction">Find MausoleumGeneral1, defeat it to get InfernalAxe(Item)</p>
                    <Image src={Image35} fluid />
                    <Image src={Image36} fluid />
                </li>
                <li>
                    <p className="instruction">Go upstairs and find MausoleumGeneral4, defeat it to get WaspSpear(Item)</p>
                    <Image src={Image37} fluid />
                    <Image src={Image38} fluid />
                </li>
                <li>
                    <p className="instruction">Having InfernalAxe and WaspSpear, jump into the Portal to be sent to the 3rd Floor.</p>
                    <Image src={Image39} fluid />
                </li>
                <li>
                    <p className="instruction">Find CapriceLeader, defeat it to trigger the 3rd Floor's boss: YangFeng. Defeat it to get the third
TowerSplinter.</p>
                    <Image src={Image40} fluid />
                    <Image src={Image41} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to Cauldron, smelt the weapons into clinkers and then having all 4 clinkers and 3 TowerSplinters,proceed to
restore the StarTower.</p>
                    <Image src={Image42} fluid />
                    <Image src={Image43} fluid />
                </li>
                <li>
                    <p className="instruction">Go back to BirdIsland and talk to SugarTang to trade the StarTower to complete the quest.</p>
                    <Image src={Image44} fluid />
                    <Image src={Image45} fluid />
                    <Image src={Image46} fluid />
                </li>
            </ol>
        </div>
    );
}