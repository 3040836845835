import { Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { useAccount } from '../Context/AccountProvider';
import { LoginForm } from './LoginForm';
import { handleLogout } from '../services/AuthService'
import { useHistory } from 'react-router-dom';

function SidebarAccount() {
    var { accountInfo, setAccInfo } = useAccount();
    var history = useHistory();

    const redirectHome = () => {
        history.push('/')
    }

    if (accountInfo.loggedIn && accountInfo.user) {
        return (
            <div className="sidebar-object">
                <div className="sidebar-header">
                    <span className="h-large">M</span><span className="h-small">y</span>&nbsp;&nbsp;<span className="h-large">A</span><span className="h-small">ccount</span>
                </div>
                <div className="sidebar-body">
                    <Row>
                        <Col lg="auto" className="left-col">
                            <img src={`/images/avatar/${accountInfo.user.Avatar}.webp`} alt="User Avatar" />
                        </Col>
                        <Col>
                            <p className="logged-welcome">
                                Welcome, {accountInfo.user.Name}
                            </p>
                            <LinkContainer activeClassName="" to="/myaccount" onClick={() => { document.getElementById('main-content').scrollIntoView(true); }}><p className="logged">Dashboard</p></LinkContainer>
                            <p className="logged" href="#" onClick={() => { setAccInfo({ loggedIn: false }); handleLogout(); redirectHome() }}>Logout</p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    } else if (accountInfo.loggedIn && accountInfo.user === undefined) {
        return (
            <div className="sidebar-object">
                <div className="sidebar-header">
                    <span className="h-large">M</span><span className="h-small">y</span>&nbsp;&nbsp;<span className="h-large">A</span><span className="h-small">ccount</span>
                </div>
                <div className="sidebar-body">
                    <p className="logged-welcome">Welcome to Paragon Conquer!</p>
                    <p className="white">Please create an in-game character to view your account data.</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="sidebar-object">
                <div className="sidebar-header">
                    <span className="h-large">L</span><span className="h-small">ogin</span>
                </div>
                <div className="sidebar-body">
                    <LoginForm register />
                </div>
            </div>
        );
    }
}

export default SidebarAccount;