import { Image } from 'react-bootstrap';
import Image1 from '../../images/Guide/EpicNinjaQuest/pic1.jpg'
import Image2 from '../../images/Guide/EpicNinjaQuest/pic2.jpg'
import Image3 from '../../images/Guide/EpicNinjaQuest/pic3.jpg'
import Image4 from '../../images/Guide/EpicNinjaQuest/pic4.jpg'
import Image5 from '../../images/Guide/EpicNinjaQuest/pic5.jpg'
import Image6 from '../../images/Guide/EpicNinjaQuest/pic6.jpg'
import Image7 from '../../images/Guide/EpicNinjaQuest/pic7.jpg'
import Image8 from '../../images/Guide/EpicNinjaQuest/pic8.jpg'
import Image9 from '../../images/Guide/EpicNinjaQuest/pic9.jpg'

export function EpicNinjaQuest() {
    return (
        <div className="guide white">
            <h3>Epic Ninja Quest</h3>
            
            <p className="head">Requirements</p>
            <ul>
                <li>Level 140</li>
                <li>2nd Reborn Character</li>
            </ul>

            <p className="head">Quest NPCs</p>
            <ol>
                <li>WindSage <i>(Job Center)</i></li>
                <li>SwapperStarry <i>(Infinity Abyss)</i></li>
                <li>ElementalGuard <i>(Infinity Abyss)</i></li>
            </ol>

            <p className="head">Quest Items</p>
            <ol>
                <li>YinYangSeal</li>
                <li>ShadowScroll</li>
                <li>TwilightScroll</li>
                <li>FatalScroll</li>
                <li>Life'sEye</li>
            </ol>

            <p className="head">Walkthrough</p>
            <ol>
                <li>
                    <p className="instruction">Talk to WindSage (Job Center) to enter the Infinity Abyss.</p>
                    <Image src={Image1} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to SwapperStarry (Infinity Abyss) and buy YinYangSeal.</p>
                    <Image src={Image2} fluid />
                </li>
                <li>
                    <p className="instruction">Talk to ElementalGuard and use YinYangSeal to summon NightmareCaptain.</p>
                    <Image src={Image3} fluid />
                    <p className="tip">*You're going to get ShadowScroll, give it to ElementalGuard to learn ShadowClones skill to help you complete the quest quicker.</p>
                    <Image src={Image4} fluid />
                </li>
                <li>
                    <p className="instruction">Defeat PurpleBanshee to get TwilightScroll.</p>
                    <Image src={Image5} fluid />

                </li>
                <li>
                    <p className="instruction">Defeat AzureDragon to get FatalScroll.</p>
                    <Image src={Image6} fluid />
                </li>
                <li>
                    <p className="instruction">Defeat WhiteTiger to spawn Ninja.</p>
                    <Image src={Image7} fluid />

                </li>
                <li>
                    <p className="instruction">Defeat Ninja to get (2) Life's Eye.</p>
                    <Image src={Image8} fluid />
                    <Image src={Image9} fluid />
                    <p className="tip">*Make sure you trade the scrolls for their respective skills before leaving the Infinity Abyss.</p>
                </li>
            </ol>
        </div>
    );
}