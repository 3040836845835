import { Row, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { useAccount } from '../Context/AccountProvider';
import { getToken } from '../services/TokenService'

import ParagonCoinImage from '../images/pcoin.webp';
import PaymentLogsIcon from '../images/payment_logs_icon.png';
import ChangePasswordIcon from '../images/changepassword_icon.png';
import ChangeEmailIcon from '../images/email_icon.png';
import AuthenticationError from '../components/AuthenticationError';

function MyAccount() {
  const { accountInfo } = useAccount();

  if (accountInfo.loggedIn) {
    return (
      <div className="my-account">
        <div className="character-info">
          <Row>
            <Col xs={12} lg={3} className="avatar">
              <img src={`/images/avatar/${accountInfo.user.Avatar}.webp`} alt="User Avatar"/>
            </Col>
            <Col xs={7} lg={4} className="tags">
              <span>Character Name:</span>
              <span>Conquer Points:</span>
              <span>Paragon Coins:</span>
              <span>Vip Level:</span>
            </Col>
            <Col xs={5} className="info">
              <span>{accountInfo.user.Name}</span>
              <span>{accountInfo.user.ConquerPoints}</span>
              <span>{accountInfo.user.DonationPoints}</span>
              <span>{accountInfo.user.Vip}</span>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xs={12} lg={6}>
            <LinkContainer exact to="/buycoins">
              <div className="account-option">
                <Row>
                  <Col xs={12} lg={4} className="center">
                    <Image src={ParagonCoinImage} width={64}/>
                  </Col>
                  <Col xs={12} lg={8} className="center">
                    <span className="white">Paragon Coins</span>
                    <p className="white">Purchase Paragon Coins to buy premium game items.</p>
                  </Col>
                </Row>
              </div>
            </LinkContainer>
          </Col>
          <Col xs={12} lg={6}>
            <LinkContainer exact to="/changepassword">
              <div className="account-option">
                <Row>
                  <Col xs={12} lg={4} className="center">
                    <Image src={ChangePasswordIcon} width={64}/>
                  </Col>
                  <Col xs={12} lg={8} className="center">
                    <span className="white">Change Password</span>
                    <p className="white">Secure your account by changing your password often.</p>
                  </Col>
                </Row>
              </div>
            </LinkContainer>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={6}>
            <LinkContainer exact to="/purchasehistory">
              <div className="account-option">
                <Row>
                  <Col xs={12} lg={4} className="center">
                    <Image src={PaymentLogsIcon} width={64}/>
                  </Col>
                  <Col xs={12} lg={8} className="center">
                    <span className="white">Purchase History</span>
                    <p className="white">View your Donation Points purchase history.</p>
                  </Col>
                </Row>
              </div>
              </LinkContainer>
          </Col>
          <Col xs={12} lg={6}>
              <div className="account-option disabled">
                <Row>
                  <Col xs={12} lg={4} className="center">
                    <Image src={ChangeEmailIcon} width={64}/>
                  </Col>
                  <Col xs={12} lg={8} className="center">
                    <span className="white">Coming Soon</span>
                    <p className="white">Change your accounts recovery email.</p>
                  </Col>
                </Row>
              </div>
          </Col>
        </Row>
      </div>
    );
  } else if (getToken() != null) {
    return (
      <></>
    )
  } else {
    return (
      <AuthenticationError />
    )
  }
}

export { MyAccount };