import { useState } from 'react';
import {Form, Button, FloatingLabel, Col, Row} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';
import { submitTicket } from '../services/TicketService';

export function CreateTicketForm(props) {
    const [outputText, setOutputText] = useState("");
    const [captchaResponse, setCaptchaResponse] = useState(false);
    const history = useHistory();

    var recaptcha;
    async function createTicket(event) {
        event.preventDefault();
        event.stopPropagation();

        if (captchaResponse === false) {
            setOutputText("Please complete the captcha.");
            return;
        }

        const subject = document.getElementById("support-subject").value;
        const message = document.getElementById("support-request").value;

        if (!subject || /^\s*$/.test(subject)) {
            setOutputText("Please write a subject for your ticket.");
            return;
        }

        if (!message || /^\s*$/.test(message)) {
            setOutputText("Please describe your issue in detail.");
            return;
        }

        let resp = await submitTicket(subject, message, captchaResponse);
        
        if (!isNaN(resp)) {
            history.push('/ticket/'+resp);
        }
    }

    return (
        <div className="new-ticket-form">
            <h3 className="center white">Create a Support&nbsp;Ticket</h3>
            <Form>
                <Row>
                    <Col xs={12}>
                        <FloatingLabel controlId="support-subject" label="Subject" className="mb-3">
                            <Form.Control type="text" placeholder="Subject" maxLength={70} aria-describedby="subjectHelp" />
                            <Form.Text id="supportRequestHelp" className="white">
                                Enter a brief description of your support request.
                            </Form.Text>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12}>
                        <FloatingLabel controlId="support-request" label="Support Request" className="mb-3">
                            <Form.Control as="textarea" placeholder="Leave a comment here" maxLength={15000} aria-describedby="supportRequestHelp" />
                            <Form.Text id="supportRequestHelp" className="white">
                                Please type a detailed explanation of your support request so that we may best assist you.
                            </Form.Text>
                        </FloatingLabel> 
                    </Col>
                </Row>
                <Row>
                    <Col className="center" xs={12}>
                        <p style={{color: 'pink'}}>{outputText}</p>
                    </Col>
                    <Col className="center" xs={12}>
                        <ReCAPTCHA className="register-captcha" ref={(el) => { recaptcha = el; }} sitekey="6LdFauQdAAAAAGyoyHMjIaDeUQCOC7ewif0ENiXI" onChange={setCaptchaResponse} onExpired={()=>{setCaptchaResponse(false)}} onErrored={()=>{setCaptchaResponse(false)}}/>
                    </Col>
                    <Col className="center" xs={12}>
                        <Button onClick={createTicket}>Submit Ticket</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}