function FacebookWidget() {
    return(
        <div className="sidebar-object">
            <div className="sidebar-header">
                <span className="h-large">F</span><span className="h-small">acebook</span>
            </div>
            <div className="facebook-widget">
                <div className="fb-page" data-href="https://www.facebook.com/Paragon-Conquer-103703422181032" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Paragon-Conquer-103703422181032" className="fb-xfbml-parse-ignore"><a target="_top" href="https://www.facebook.com/Paragon-Conquer-103703422181032">Paragon Conquer</a></blockquote></div>
            </div>
        </div>
    );
}

export { FacebookWidget };